<template>

    <div id="createFormElement">
        <li :id="'answer_'+question.id" :key="question.id" class="hide" v-for="(question, i) in questions" :ref="'qref_' + i">
            <div class="item-content item-input">
                <div class="item-inner">
                    <div class="item-title item-label">{{question.name}}</div>
                    <div class="item-input-wrap">
                        <template v-if="question.type == 'select'">
                            <select :id="'answer_id_'+question.id" @focusin="() => changeColorActive('qref_' + i)" @focusout="() => changeColorInactive('qref_' + i)" :name="'answer_'+question.id" @change="answerFunction($event)">
                                <option selected value="0">-</option>
                                <option :value="answer.value" v-for="(answer, j) in question.answer" :key="j">{{answer.name}}</option>
                            </select>
                        </template>
                        <template v-else-if="question.type == 'multicheckbox'" >

<!--                            <label class="item-checkbox item-content" v-for="(answer, j) in question.answer" :key="j">-->
<!--                                <input :name="'answer_'+question.id" :value="answer.value" @click="answerFunction($event)" ref="multichecbox" type="checkbox"/>-->
<!--                                <i class="icon icon-checkbox"></i>-->
<!--                                <div class="item-inner">-->
<!--                                    <div class="item-title">{{answer.name}}</div>-->
<!--                                </div>-->
<!--                            </label>-->

                          <f7-list>
                            <template v-for="(answer, j) in question.answer">
                            <f7-list-item checkbox radio-icon :name="'q_'+question.id" @change="changeHandler($event); answers[i] = $event.target.value" :ref="`multicheckbox_${i}_${j}`" v-for="(answer, j) in question.answer" v-bind:key="j" :value="answer.value" :title="answer.name" />
                            </template>
                          </f7-list>

                        </template>

                        <div id="question-error" v-if="errors['answer_'+question.id]">{{errors['answer_'+question.id]}}</div>

                    </div>
                </div>



            </div>
        </li>
    </div>

</template>

<script>
    import $ from 'dom7';
    import questions from "../assets/diagnosis/questions";

    export default {
        name: "createFormElement",
        props:{
            questions:{
                type: Array,
                default: [],
            },
            errors:{
                type: Object,
                default: [],
            }
        },
        data(){
          return{
                currentID: 0,
                allID: [0],
                focused: '',
          }
        },
        methods:{
            changeColorActive(ref){
                if (this.$refs[ref] && this.$refs[ref].length > 0) {
                    const el = this.$refs[ref][0];
                    el.classList.add('focusIn');
                    this.focused = ref;
                }
            },
            changeColorInactive(ref){
                if (this.$refs[ref] && this.$refs[ref].length > 0) {
                    const el = this.$refs[ref][0];
                    el.classList.remove('focusIn');
                    this.focused = '';
                }
            },
            answerFunction(event){
              let allQuestions = this.$t('questions').questions;
              let liTarget = '';
              let target = '';
              target = event.target;
              liTarget = $(target).parents('li')[0];

                this.currentID = parseInt(liTarget.id.split('_')[1]);
                let targetValue = target.value;
                let answer = allQuestions[this.currentID].answer[--targetValue];


                if(answer != undefined) {

                    if (answer.link) {
                        this.hideOtherElements(allQuestions, ++this.currentID);
                        document.querySelector(`li#answer_${answer.link}`).classList.remove('hide');
                    } else {
                        if (allQuestions.length - 1 > this.currentID) {
                            this.currentID += 1;
                            this.allID.includes(this.currentID) ? '' : this.allID.push(this.currentID);
                        }

                        document.querySelector(`li#answer_${this.currentID}`).classList.remove('hide');
                    }
                }

                event.target.blur();
          },

            hideOtherElements(questions,actualyID){
                const length = questions.length;

                for(let i = actualyID; i<length; i++){
                    document.querySelector(`li#answer_${i}`).classList.add('hide');
                    let question = document.querySelector(`li#answer_${i}`);
                    $(question).find('select').val(0);
                    let input = $(question).find('input');
                    if(input.length > 0){
                        for(let j = 0; j<input.length; j++){
                            $(input[j]).prop('checked',false)

                        }
                    }
                }
            }
        },
        mounted() {

        }

    }
</script>

<style scoped>

    .multichecbox{
        display: block;
    }

    :root{
        --f7-list-item-title-white-space: normal;
    }

    .hide{
        display: none;
    }

</style>
