<template>

    <f7-navbar data-name="navbar" :no-shadow="true" :no-hairline="hairLines" :innerClass="'navbarText'" :mdCenterTitle="'true'">
        <f7-nav-left>
            <slot></slot>
        </f7-nav-left>

        <f7-nav-title sliding><slot name="title"></slot></f7-nav-title>

        <f7-nav-right></f7-nav-right>
    </f7-navbar>

</template>

<style lang="scss">
@media only screen and (max-width: 600px) {
    .navbar .title{
        font-size: 5vw;
    }
    .navbar i.icon{
        font-size: 7vw;
    }
}
@media only screen and (min-width: 601px) {
    .navbar{
        height: 80px;
    }
    .navbar .title{
        font-size: 3.7vw;
    }
    .navbar i.icon{
        font-size: 7vw;
    }
}

.navbar .left{
    flex: 0 1 20%;
}

.navbar .title{
    justify-self: center;
    text-align: center;
    flex: 0 1 60%;
    color: #fff;
    font-family: TitilliuBold;
}

.navbar .right{
    flex: 0 1 20%;
}

.navbar .navbar-bg{
    background-color: #d53e06 !important;
    backdrop-filter: unset !important;
}

.navbar i.icon{
    color: white !important;
}
</style>

<script>

    export default{

        data(){
            return{
            }
        },
        props: {
            showNavbar: {
                type: Boolean,
                default: false
            },
            center: Boolean,
            share: Boolean,
            hairLines: {
                type: Boolean,
                default: true
            }
        },
        watch:{

        },
        methods: {


        },
        mounted() {

            this.$f7ready((f7) => {

            });
        }
    }

</script>