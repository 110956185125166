export default {
    abstinenceTimerTips: {
        '0': 'Pierwsze efekty odstawienia papiersów poczujesz już po 20 minutach! Śledź informację w tym module, aby dowiedzieć się jakie korzyści z rzucenia palenia osiągasz.',
        '1200': 'Puls zaczyna wracać do normy.',
        '7200': 'Poziom nikotyny zaczyna spadać i mogą pojawić się pierwsze objawy odstawienia papierosów. Jeśli są bardzo silne zadzwoń: 801 108 108.',
        '28800': 'Spada poziom tlenku węgla i poziom tlenu w Twojej krwi wraca do normy',
        '43200': 'W twoim organizmie nie ma już tlenku węgla i  poziom tlenu w Twojej krwi wrócił do normy',
        '57600': 'Poprawia się krążenie krwi.',
        '86400': 'Spada ryzyko zawału serca.',
        '108000': 'Papierosowy „posmak” w ustach staje się słabszy.',
        '129600': 'Zwiększa się poziom energii.',
        '151200': 'Znika uczucie pieczenia w ustach.',
        '172800': 'Poprawia się odczuwanie smaków i zapachów.',
        '194400': 'Ręce nie śmierdzą już papierosami.',
        '216000': 'Znika nieprzyjemny „papierosowy” zapach z ust.',
        '259200': 'Oskrzela się rozluźniają i rozpoczyna się proces oczyszczania dróg oddechowych. Uwaga! Możesz odczuwać wzmożony kaszel.',
        '280800': 'Poprawia się wydolność układu oddechowego.',
        '288000': 'Poprawia się jakość snu.',
        '309600': 'Coraz wyraźniej czujesz smaki i zapachy.',
        '345600': 'Większość objawów odstawienia powinna już mijać.',
        '432000': 'Mija ból/nieprzyjemne uczucie w gardle.',
        '518400': 'Twoja cera staje się jaśniejsza i powoli znikają cienie pod oczami.',
        '604800': 'Najgorsze masz już za sobą! Minął pełny tydzień od odstawienia papierosów!',
        '691200': 'Od tygodnia nie palisz! Gratulujemy! Nie czuć już od Ciebie tytoniu, a objawy odstawienia stają się coraz słabsze.',
        '777600': 'Twój układ oddechowy funkcjonuje coraz lepiej.',
        '864000': 'Twoje serce i układ krążenia funkcjonują coraz sprawniej.',
        '950400': 'Możesz jeszcze mieć kaszel, ale to świadczy o oczyszczaniu się dróg oddechowych.',
        '1036800': 'Mijają problemy z koncentracją.',
        '1123200': 'Powraca energia i mija uczucie osłabienia.',
        '1209600': 'Zachodzą kolejne korzystne zmiany regeneracyjne w układzie krążenia i oddechowym, co pozwala na łatwiejsze podjęcie np. aktywności fizycznej (np. marszu).',
        '1296000': 'Nabłonek wyścielający drogi oddechowe stopniowo odnawia się, co ułatwia oddychanie.',
        '1382400': 'Zmniejsza się ryzyko rozwoju infekcji dróg oddechowych.',
        '1468800': 'Mija uczucie duszności i "kaszel palacza".',
        '1555200': 'Poprawia się stan i wygląd Twojej skóry.',
        '1641600': 'Odbudowuje się Twoja  błona śluzowa żołądka.',
        '1728000': 'Mija drażliwość i uczucie napięcia.',
        '1814400': 'Nie palisz już 3 tygodnie! Większość objawów odstawienia minęła.',
        '1900800': 'Skóra ponownie jest ukrwiona i właściwie nawilżona.',
        '1987200': 'Poprawiła się Twoja kondycja fizyczna.',
        '2073600': 'Lepiej śpisz.',
        '2160000': 'Lepiej radzisz sobie z uczuciem stresu i nawykami.',
        '2246400': 'Nadal znacząco poprawia się praca układu krążenia.',
        '2332800': 'Wiele substancji toksycznych zostało już usuniętych z Twojego organizmu.',
        '2419200': 'To już 4 tygodnie bez papierosa! Stopniowo spada ryzyko zachorowania na choroby odtytoniowe!',
        '2505600': 'Zmniejsza się ryzyko próchnicy, zęby stają się bielsze.',
        '2592000': 'Praca płuc normalizuje się, wraca właściwa cyrkulacja powietrza.',
        '2678400': 'Czujesz się spokojniejszy.'
    }
}