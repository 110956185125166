export default {
    ratingQuestions:[
        {
            id: 0,
            name: 'Na ile w Twojej ocenie aplikacja była pomocna w rzuceniu palenia?',
            type: 'select',
            required: true,
            dependsOn: null,
            answer: [
                {
                    name: 'Bardzo przydatna.',
                    value: '1',
                },
                {
                    name: 'Przydatna.',
                    value: '2',
                },
                {
                    name: 'Ani tak, ani nie.',
                    value: '3',
                },
                {
                    name: 'Mało przydatna.',
                    value: '4',
                },
                {
                    name: 'Nie przydatna.',
                    value: '5',
                },
            ]
        },
        {
            id: 1,
            name: 'Czy poleciłbyś/poleciłabyś aplikację innym osobom, które chcą rzucić palenie?',
            type: 'select',
            required: true,
            dependsOn: null,
            answer: [
                {
                    name: 'Zdecydowanie tak.',
                    value: '1',
                },
                {
                    name: 'Tak.',
                    value: '2',
                },
                {
                    name: 'Ani tak, ani nie.',
                    value: '3',
                },
                {
                    name: 'Nie.',
                    value: '4',
                },
                {
                    name: 'Zdecydowanie nie.',
                    value: '5',
                },
            ]
        },
    ],
}