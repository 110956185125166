<template>
    <f7-page name="quitting">
        <navbar-component hair-lines>
            <f7-link icon-f7="bars" panel-open="left"></f7-link>
            <template v-slot:title>{{$t('quitting.title')}}</template>
        </navbar-component>

        <f7-block id="step-block">
            <div id="step-list">
                <div v-bind:class="{ active: (currentStep == 1) }" @click="changeStep(1)">
                    <f7-icon material="timer"></f7-icon>
                </div>
                <div v-bind:class="{ active: (currentStep == 2) }" @click="changeStep(2)">
                    <f7-icon material="attach_money"></f7-icon>
                </div>
                <div v-if="!after12Weeks" v-bind:class="{ active: (currentStep == 3) }" @click="changeStep(3)">
                    <f7-icon material="question_answer"></f7-icon>
                </div>
                <div v-if="pharmacotherapyAccess && !after12Weeks" v-bind:class="{ active: (currentStep == 4) }" @click="changeStep(4)">
                    <f7-icon material="local_hospital"></f7-icon>
                </div>
            </div>
        </f7-block>

        <!-- Czasomierz -->
        <template v-if="currentStep == 1">
            <quitting-12WeekQuestion-component 
                v-if="after12Weeks"
                :questionsAnswered="answeredQuestions['q_quitting_after12weeks']" 
                :setQuestionsAnswered="set12WeeksQuestionAnswered"
            >
            </quitting-12WeekQuestion-component>

            <template v-else>
                <quitting-timer-component v-if="cigaretteZero">
                </quitting-timer-component>
                <zero-cigarette-component 
                    v-if="!cigaretteZero"
                    :cb="addZeroCigarette"
                >
                </zero-cigarette-component>
            </template>
        </template>
        
        <!-- Kalkulator -->
        <quitting-calculator-component v-if="currentStep == 2">
        </quitting-calculator-component>

        <!-- Pytania -->
        <quitting-questions-component 
            v-if="currentStep == 3"
            :questionsAnswered="answeredQuestions['q_s3_quitting']" 
            :setQuestionsAnswered="setQuestionsAnswered"
        >
        </quitting-questions-component>

        <!-- Farmakoterapia -->
        <quitting-pharmacotherapy-component v-if="currentStep == 4">
        </quitting-pharmacotherapy-component>

    </f7-page>
</template>

<script>
    import * as moment from 'moment';
    import StoreCache from '../../utils/storeCache.js';
    import NavbarComponent from '../../components/navbar.vue';
    import ZeroCigaretteComponent from '../../components/zero-cigarette';
    import QuittingTimerComponent from './quitting-timer';
    import QuittingCalculatorComponent from './quitting-calculator';
    import QuittingQuestionsComponent from './quitting-questions';
    import QuittingPharmacotherapyComponent from './quitting-pharmacotherapy';
    import Quitting12WeekQuestionComponent from './quitting-12weekquestion';

    export default {
        name: "quitting",
        data(){
            return {
                after12Weeks: false,
                currentStep: 1,
                answeredQuestions: {
                    "q_s3_quitting": false,
                    "q_quitting_after12weeks": false,
                },
                pharmacotherapyAccess: false,
                cigaretteZero: null,
            }
        },
        components: {
            NavbarComponent,
            ZeroCigaretteComponent,
            QuittingTimerComponent,
            QuittingCalculatorComponent,
            QuittingQuestionsComponent,
            QuittingPharmacotherapyComponent,
            Quitting12WeekQuestionComponent,
        },
        computed: {
        },
        methods:{
            addZeroCigarette() {
                this.cigaretteZero = true;
            },

            /**
             * Ładowanie odpowiedzi na pytania.
             * W przypadku części z powiadomieniami porównuje
             * czas odpowiedzi na pytania z czasem ostatniego
             * powiadomienia.
             *
             * @returns {boolean}
             * @component quitting
             */
            areAnswersSaved() {
                const cache = new StoreCache();

                for (const questions in this.answeredQuestions) {
                    const stepAnswers = cache.get("_" + questions);
                    
                    if (!stepAnswers) this.answeredQuestions[questions] = false;
                    else {
                        if (questions != 'q_s3_quitting') {
                            this.answeredQuestions[questions] = true;
                            continue; 
                        }

                        const questionFreq = cache.get("_app_settings").notif_freq['NOTIF_FREQ_RZUCANIE_PALENIA_QUESTIONS'] || 0;
                        let answeredAt;

                        try {
                            answeredAt = cache.get("_settings").questionsAnsweredAt[questions];
                        }
                        catch(e) {
                            answeredAt = null;
                        }

                        const answeredAtDate = moment(answeredAt);

                        let prevNotif;
                        try {
                            prevNotif = cache.get("_s3_quitting_notifications")[0];
                            prevNotif = moment(prevNotif.time).subtract(questionFreq, 'h');
                        }
                        catch(e) {
                            prevNotif = null;
                        }

                        // console.log(questionFreq)
                        // console.log(answeredAtDate.toDate())
                        // console.log(prevNotif.toDate())

                        if (!answeredAt || !prevNotif || answeredAtDate < prevNotif) {
                            this.answeredQuestions[questions] = false;
                        }
                        else {
                            this.answeredQuestions[questions] = true;
                        }
                    }
                }
            },
            changeStep(step) {
                this.currentStep = step;
            },

            /**
             * Sprawdza, czy minęło 12 tygodni
             * od ostatniego papierosa.
             *
             * @returns {boolean}
             * @component quitting
             */
            have12WeeksPassed() {
                const cache = new StoreCache();
                const settings = cache.get("_settings");

                if (settings && settings.cigaretteZero) {
                    const cigZero = moment(Number(settings.cigaretteZero));
                    const cigZeroDiff = moment().diff(cigZero, 'hours');
                    
                    if (cigZeroDiff > 2016)
                        return true;
                }
                
                return false;
            },

            nextStep() {
                this.currentStep < 4 && this.currentStep++;
            },
            setQuestionsAnswered(questionsName) {
                this.answeredQuestions[questionsName] = true;
            },
            set12WeeksQuestionAnswered() {
                this.answeredQuestions['q_quitting_after12weeks'] = true;
            },
        },
        mounted() {
            this.$f7ready(f7=>{
                document.querySelector('#view-quitting').addEventListener('tab:show', () => {
                    // Weryfikacja, czy uzyt. ma farmakoterapie.
                    const cache = new StoreCache();
                    const pharmacotherapyAnswers = cache.get("_q_s4_beforeQuitting");

                    if (pharmacotherapyAnswers && pharmacotherapyAnswers.score > 0) {
                        this.pharmacotherapyAccess = true;
                    }

                    this.areAnswersSaved();

                    this.cigaretteZero = f7.data.stats.getTimerZeroCigarette();
                    this.after12Weeks = this.have12WeeksPassed();
                });
            })
        }

    }
</script>

<style scoped>

    @media only screen and (max-width: 600px) {
        #step-list {
            font-size: 4vw;
            margin: 0 10px;
        }
    }
    @media only screen and (min-width: 601px) {
        #step-list i {
            font-size: 5vw;
            margin: 0 5vw;
        }
    }

    .page[data-name="quitting"]{
        background-image: url("../../assets/img/bg.png");
        background-position: center;
        background-size: cover;
    }

    #step-block {
        margin: 3vh 0 !important;
    }
    #step-list {
        display: flex;
        font-weight: bold;
        justify-content: space-between;
        font-family: 'TitilliuBold' !important;
    }
    #step-list div {
        display: flex;
        justify-content: center;
        flex-grow: 1;
    }
    #step-list div.active {
        color: #fff;
    }
</style>

<style lang="scss">

    .page[data-name="quitting"] .page-content{
        font-family: 'TitilliuRegular';
    }

</style>