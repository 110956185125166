export default {
    beforeQuittingStep2Tips: {
        block1: {
            title: "",
            content: [
                "Jednym z objawów uzależnienia od tytoniu jest tendencja do tłumaczenia sobie dlaczego „można lub nawet trzeba” nadal palić. Zwykle dzieje się to w sposób zupełnie nieświadomy. Niemniej różne przekonania dotyczące papierosów i palenia usprawiedliwiają dalsze palenie.",

                "Skonfrontuj się z nimi!",
            ]
        },

        block2: {
            content: [
                "Zastanów się, czy faktycznie w każdej chwili możesz odstawić papierosy. W dymie tytoniowym znajduje się 7 tys substancji chemicznych, a jedną z nich jest nikotyna, która ma silne właściwości uzależniające. Uzależnienie to nie tylko przymus regularnego palenia powodowany fizycznym uzależnieniem. Często objawia się tym, że w pewnych określonych sytuacjach (np. towarzyskich) lub stanach emocjonalnych (np. stresu) nie potrafimy radzić sobie bez papierosa. Pomyśl więc, czy faktycznie w każdej chwili możesz rzucić? Czy nie sięgniesz po papierosa kiedy będziesz zdenerwowany lub smutny? Zastanów się też, czy kiedy długo nie palisz nie zaczynasz czuć się bardziej podenerwowany i drażliwy, a w głowie coraz częściej nie pojawia się myśl o zapaleniu. To mogą być pierwsze objawy postępującego uzależnienia...",
            ]
        },

        block3: {
            content: [
                "Zapalenie papierosa powoduje już po ok 7-10 sekundach wyrzut dopaminy w mózgu i odczuwanie przyjemności, rozładowanie napięcia lub uczucie ulgi.",

                "Przez ok. dwie godziny poziom nikotyny utrzymuje się i osoba uzależniona reaguje na wszystkie sytuacje życiowe, tak jak inni. Po ok. dwóch godzinach poziom nikotyny spada i  dopamina nie jest wydzielana w wystarczających ilościach. Zmniejsza się odporność na stres, pojawia się uczucie rozdrażnienia i przekonanie, że tylko zapalenie papierosa może pomóc rozładować wewnętrzne napięcie.",
                
                "To palenie papierosów powoduje, że codzienne sytuacje wydają się bardziej stresujące!",
                
                "Palenie napędza tzw.: spiralę stresu!",
            ]
        },

        block4: {
            content: [
                "Szkody zdrowotne palenia papierosów kumulują się w czasie! To znaczy, że najczęściej odczuwane są dopiero po wielu latach palenia. Prawdą jest również to, że nie każdy, kto pali doświadczy wyraźnych konsekwencji zdrowotnych. Jednak jest to trochę jak gra w „rosyjską ruletkę”. W przypadku papierosów zagrożeniem są choroby nowotworowe, układu krążenia, oddechowego i wiele innych. Ryzyko, że to jednak na Ciebie wypadnie jest realne! Średnio osoby palące żyją ok. 10 lat krócej, a wśród 100 osób chorych na raka płuca aż 95 osób pali lub paliło w przeszłości! To są fakty!",
            ]
        },

    }
}