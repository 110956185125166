export default {
    beforeQuittingStep4Questions:[
        {
            id: 0,
            name: 'Czy chcesz skorzystać ze wsparcia środkami farmakologicznymi, które ułatwiają rzucenie palenia?',
            type: 'select',
            required: true,
            dependsOn: null,
            answer: [
                {
                    name: 'Tak.',
                    value: '1',
                },
                {
                    name: 'Nie.',
                    value: '2',
                },
            ]
        },
        {
            id: 1,
            name: 'Czy leczysz się lub wcześniej leczyłeś z powodu chorób przewlekłych?',
            type: 'select',
            required: true,
            dependsOn: [
                {
                    questionId: 0,
                    value: '1',
                }
            ],
            answer: [
                {
                    name: 'Układu pokarmowego.',
                    value: '1',
                },
                {
                    name: 'Neurologicznych.',
                    value: '2',
                },
                {
                    name: 'Układu krążenia.',
                    value: '3',
                },
                {
                    name: 'Układu oddechowego.',
                    value: '4',
                },
                {
                    name: 'Onkologicznych.',
                    value: '5',
                },
                {
                    name: 'Psychiatrycznych.',
                    value: '6',
                },
                {
                    name: 'Nie.',
                    value: '7',
                },
            ]
        },
        {
            id: 2,
            name: 'Jaką terapię wybierasz?',
            type: 'select',
            required: true,
            dependsOn: [
                {
                    questionId: 0,
                    value: '1',
                },
                {
                    questionId: 1,
                    value: '7',
                }
            ],
            answer: [
                {
                    name: 'Desmoxan, Tabex lub Recigar.',
                    score: 10,
                    value: '1',
                },
                {
                    name: 'Środki z nikotyną (plastry, gumy, tabletki lub spray).',
                    value: '2',
                },
                {
                    name: 'Champix.',
                    score: 20,
                    value: '3',
                },
                {
                    name: 'Zyban.',
                    score: 30,
                    value: '4',
                },
                {
                    name: 'Inną.',
                    value: '5',
                },
                {
                    name: 'Jeszcze nie chcę decydować.',
                    value: '6',
                },
            ]
        },
    ],
}