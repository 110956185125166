<template>
    <f7-page no-toolbar no-navbar name="resetPassword">
        <div id="logo">
            <img src="../assets/img/logo.png" id="logo-img" />
        </div>

        <div id="slogan">
            <span v-html="templateHowTo"></span>
        </div>

        <div id="form">
            <f7-block>
                <label>
                    {{$t("resetPassword.email")}}
                </label>
                <input id="email" type="text" :placeholder="$t('resetPassword.your_email')" :value="email" @input="email = $event.target.value" />
            </f7-block>
        </div>

        <div id="login-btn">
            <f7-list class="buttons">
                <button class="button login-button" @click="resetPwd">{{$t("resetPassword.resetPassword")}}</button>
            </f7-list>
        </div>

        <div id="cancel-btn">
            <f7-list class="buttons">
                <button class="button cancel-button" @click="$f7router.back()">{{$t('registery.cancel')}}</button>
            </f7-list>
        </div>

        <div id="footer">
            <img src="../assets/img/stopka.png" id="footer-img" />
        </div>
    </f7-page>
</template>

<style>
.page[data-name="resetPassword"] .page-content {
    padding-bottom: 0px !important;
    background-image: url("../assets/img/bg.png");
    width: 100vw;
    height: 100vh;
    background-position: center;
    background-size: cover;
    font-family: TitilliuRegular;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 4fr 50px 2fr 1fr 3fr 2fr;
    grid-template-areas:
        "logo"
        "slogan"
        "form"
        "login-btn"
        "cancel-btn"
        "footer";
}
</style>

<style lang="scss" scoped>
@media only screen and (max-width: 600px) {
    #form-forgot-passwd {
        font-size: 3.5vw;
    }
    button.button {
        font-size: 5vw;
    }
    div#slogan /deep/ span {
        font-size: 7vw;
    }
    #form label {
        font-size: 3.5vw;
    }
    #form input {
        font-size: 5vw;
    }
    #form input::placeholder {
        font-size: 5vw;
    }
    #logo-img {
        margin-bottom: 1vh;
    }
}
@media only screen and (min-width: 601px) {
    #form-forgot-passwd {
        font-size: 2.5vw;
    }
    button.button {
        font-size: 4vw;
        height: 5vh !important;
    }
    div#slogan /deep/ span {
        font-size: 7vw;
    }
    #form label {
        font-size: 2.5vw;
    }
    #form input {
        font-size: 3.5vw;
    }
    #form input::placeholder {
        font-size: 3.5vw;
    }
    #form input {
        height: 5vh;
    }
    #logo-img {
        margin-bottom: 3vh;
    }
}

a {
    color: rgb(0, 33, 38);
    text-decoration: underline dotted;
    font-style: italic;
}

#logo {
    grid-area: logo;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
#logo-img {
    width: 23vw;
    height: auto;
    background-image: url("../assets/img/logo.png");
    background-size: contain;
    background-repeat: no-repeat;
}

div#slogan /deep/ span:nth-child(2n) {
    color: #fff;
}

div#slogan /deep/ span:nth-child(2n - 1) {
    color: rgb(0, 33, 38);
}

div#slogan /deep/ span {
    text-transform: uppercase;
}

#slogan {
    grid-area: slogan;
    display: flex;
    justify-content: center;
    align-items: center;
}
#form {
    grid-area: form;
}
#form label {
    display: block;
    font-style: italic;
    padding: 7px 0px;
}
#form input {
    box-sizing: border-box !important;
    border-bottom: 1px solid #fff !important;
    color: #fff !important;
    width: 100%;
    padding: 5px;
}
#form input::placeholder {
    font-style: italic;
    color: #fff !important;
}
#form input:focus {
    border-bottom: 2px solid #fff !important;
}

#login-btn {
    grid-area: login-btn;
    display: flex;
    justify-content: center;
}
#cancel-btn {
    grid-area: cancel-btn;
    display: flex;
    justify-content: center;
}
#footer {
    grid-area: footer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
}

div.buttons {
    padding: 0;
    margin: 5px;
}
.buttons.list {
    width: 90vw;
}
button.button {
    border: 2px solid #fff;
    border-radius: 25px;
    height: 45px;
    width: 100%;
    text-transform: none !important;
    font-weight: 100 !important;
}
button.login-button {
    border: 2px solid #fff;
    background: #fff;
    color: rgb(216, 39, 0);
}
button.cancel-button {
    color: #fff;
}

#footer-img {
    width: 90vw;
    height: auto;
}
</style>

<script>
    import StoreCache from '../utils/storeCache.js';
    import Application from '../utils/Application.js';

    export default {
        name: "resetPassword",
        data() {
            return {
                email: '',
                templateHowTo: this.$t("login.how_to"),
            };
        },
        mounted: function() {
            // console.log(this.$f7.data)
        },
        methods: {

            displayErrorDialog(msg, title) {
                const app = this.$f7;
                app.dialog.alert(msg, title);
                setTimeout(function () {
                    app.dialog.close();
                }, 2000);
            },

            resetPwd() {
                const self          = this;
                const app           = self.$f7;
                const router        = self.$f7router;
                const storeCache    = new StoreCache();

                if (storeCache.get("_loginData")) {
                    storeCache.remove("_loginData");
                    this.$f7.data.isLoggedIn = false;
                    this.$f7.data.user = null;
                }

                if (!Application.checkConnection()) {
                    this.displayErrorDialog(window.vue.$t("resetPassword.failure_nointernet_msg"), window.vue.$t("resetPassword.failure_nointernet_title"));
                    return;
                }

                app.request({
                    method: "POST",
                    url: `${this.$f7.data.api.url}/resetPassword/${self.email}`,
                    data: {
                    },
                    success: function (response) {
                        let parsedResponse = null;
                        try {
                            parsedResponse = JSON.parse(response);
                        }
                        catch(e) {
                            self.displayErrorDialog(self.$t("resetPassword.failure_unknown"), self.$t("resetPassword.failure"));
                            console.log(e);
                            return;
                        }
                        // console.log(parsedResponse);

                        if (parsedResponse.success) {
                            self.displayErrorDialog(self.$t("resetPassword.success_text"), self.$t("resetPassword.success"));
                            self.$f7router.navigate("/login/");
                            // console.log(parsedResponse);
                        }
                        else {
                            self.displayErrorDialog(parsedResponse.message, self.$t("resetPassword.failure"));
                            self.$f7.data.isLoggedIn = false;
                        }
                    },
                    error: function (error) {
                        self.displayErrorDialog(self.$t("resetPassword.failure_unknown"), self.$t("resetPassword.failure"));
                        console.log(error);
                    }
                });

            },
        },
        
    };
</script>