<template>
    <f7-page name="beforeQuitting">
        <navbar-component hair-lines>
            <f7-link icon-f7="bars" panel-open="left"></f7-link>
            <template v-slot:title>{{$t('beforeQuitting.title')}}</template>
        </navbar-component>

        <f7-block id="step-block">
            <div id="step-list">
                <div v-bind:class="{ active: (currentStep == 1) }" @click="changeStep(1)">
                    {{$t('beforeQuitting.step_1')}}
                </div>
                <div v-bind:class="{ active: (currentStep == 2) }" @click="changeStep(2)">
                    {{$t('beforeQuitting.step_2')}}
                </div>
                <div v-bind:class="{ active: (currentStep == 3) }" @click="changeStep(3)">
                    {{$t('beforeQuitting.step_3')}}
                </div>
                <div v-bind:class="{ active: (currentStep == 4) }" @click="changeStep(4)">
                    {{$t('beforeQuitting.step_4')}}
                </div>
            </div>
        </f7-block>

        <div class="content-container">

            <!-- Krok I -->
            <beforeQuitting-krok1-component 
                :next-step="nextStep" 
                :isModuleComplete="isModuleComplete"
                :user-group="userGroup" 
                :show-journal-results="showJournalResults" 
                v-if="currentStep == 1">
            </beforeQuitting-krok1-component>

            <!-- Krok II -->
            <beforeQuitting-krok2-component 
                :questionsAnswered="answeredQuestions['q_s2_beforeQuitting']" 
                :setQuestionsAnswered="setQuestionsAnswered"
                :next-step="nextStep" 
                :isModuleComplete="isModuleComplete"
                v-if="currentStep == 2">
            </beforeQuitting-krok2-component>

            <!-- Krok III -->
            <beforeQuitting-krok3-component 
                :questionsAnswered="answeredQuestions['q_s3_beforeQuitting']" 
                :setQuestionsAnswered="setQuestionsAnswered"
                :next-step="nextStep" 
                :isModuleComplete="isModuleComplete"
                v-if="currentStep == 3">
            </beforeQuitting-krok3-component>

            <!-- Krok IV -->
            <beforeQuitting-krok4-component 
                :questionsAnswered="answeredQuestions['q_s4_beforeQuitting']" 
                :user-group="userGroup" 
                :setQuestionsAnswered="setQuestionsAnswered"
                :isModuleComplete="isModuleComplete"
                v-if="currentStep == 4">
            </beforeQuitting-krok4-component>

        </div>


    </f7-page>
</template>

<script>
    import NavbarComponent from '../../components/navbar.vue';
    import StoreCache from '../../utils/storeCache.js';
    import BeforeQuittingKrok1Component from './beforeQuitting-krok1';
    import BeforeQuittingKrok2Component from './beforeQuitting-krok2';
    import BeforeQuittingKrok3Component from './beforeQuitting-krok3';
    import BeforeQuittingKrok4Component from './beforeQuitting-krok4';
    import User from '../../utils/User';

    export default {
        name: "beforeQuitting",
        data() {
            return {
                currentStep: 1,
                userGroup: User.getGroup(),
                showJournalResults: false,
                answeredQuestions: {
                    "q_s2_beforeQuitting": false,
                    "q_s3_beforeQuitting": false,
                    "q_s4_beforeQuitting": false,
                },
            }
        },
        components: {
            NavbarComponent,
            BeforeQuittingKrok1Component,
            BeforeQuittingKrok2Component,
            BeforeQuittingKrok3Component,
            BeforeQuittingKrok4Component,
        },
        methods: {
            // Ładowanie odpowiedzi na pytania.
            areAnswersSaved() {
                const cache = new StoreCache();

                for (const questions in this.answeredQuestions) {
                    const stepAnswers = cache.get("_" + questions);
                    if (!stepAnswers) this.answeredQuestions[questions] = false;
                    else {
                        this.answeredQuestions[questions] = true;
                    }
                }
            },
            changeStep(step) {
                this.currentStep = step;
            },
            isModuleComplete() {
                for (let [key, val] of Object.entries(this.answeredQuestions)) {
                    if (!val && !(key === 'q_s4_beforeQuitting' && this.userGroup == 1)) 
                        return false;
                }

                const hasPermissions = User.checkPermission('quitting') && User.checkPermission('abstinence') && User.checkPermission('success');
                if (hasPermissions)
                    return true;

                const cache = new StoreCache();
                const userSettings = cache.get('_settings');
                if(!userSettings || !userSettings.cigaretteJournal || !userSettings.cigaretteJournal.ended)
                    return false;

                User.addPermissions(['quitting', 'abstinence', 'success']);
                return true;
            },
            nextStep() {
                this.currentStep < 4 && this.currentStep++;
            },
            setQuestionsAnswered(questionsName) {
                this.answeredQuestions[questionsName] = true;
            },
        },
        mounted() {
            this.$f7ready(f7 => {
                document.querySelector('#view-beforeQuitting').addEventListener('tab:show', (e) => {
                    this.userGroup = User.getGroup();
                    const journal = this.$f7.data.stats.getCigaretteJournal();
                    this.showJournalResults = journal && journal.ended;
                    this.areAnswersSaved();
                    this.isModuleComplete();
                });
            });
        },
    }
</script>

<style scoped>
    @media only screen and (max-width: 600px) {
        #step-list {
            font-size: 4vw;
        }
        div#tips {
            font-size: 3.5vw;
        }
        div#tips i {
            font-size: 5vw;
        }
    }
    @media only screen and (min-width: 601px) {
        #step-list {
            font-size: 3vw;
        }
        div#tips {
            font-size: 3vw;
        }
        div#tips i {
            font-size: 4vw;
        }
        div#prep-paragraphs {
            font-size: 3vw;
        }
    }

    .page[data-name="beforeQuitting"]{
        background-image: url("../../assets/img/bg.png");
        background-position: center;
        background-size: cover;
    }

    .content-container {
        background-color: #efeff4;
        min-height: calc(100% - 70px);
        padding: 3vw;
    }

    #step-block {
        margin: 3vh 0 !important;
    }
    #step-list {
        display: flex;
        font-weight: bold;
        justify-content: space-between;
        margin: 0 10px;
        font-family: 'TitilliuBold' !important;
    }
    #step-list div.active {
        color: #fff;
    }

    #prep-content-block {
        display: flex;
        margin: 0 !important;
        flex-direction: column;
        min-height: 70vh;
    }

    /* Krok II */
    div#tips {
        color: #d53e06;
        font-weight: bold;
    }
    div#prep-paragraphs {
        flex-grow: 1;
    }
</style>

<style lang="scss">
    .page[data-name="beforeQuitting"] .page-content{
        font-family: 'TitilliuRegular';
    }
</style>