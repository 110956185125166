<template>
  <f7-page name="diagnosis">
    <navbar-component hair-lines>
      <f7-link icon-f7="bars" panel-open="left"></f7-link>
      <template v-slot:title>{{$t('diagnosis.title')}}</template>
    </navbar-component>

    <!-- Jeżeli pytania nie zostały zapisane -->
    <template v-if="!answersSaved">

      <template v-if="!loading && !loadingModule">
        <f7-block class="block-color">
          <div class="login">{{user}},</div>
          <div class="text-diagnostic">
            <p v-if="!answersSaved">{{$t('diagnosis.textHeader')}}</p>
            <p v-else>{{$t('diagnosis.readTips')}}</p>
          </div>
        </f7-block>

        <div class="form-container">
          <f7-block>
            <form id="diagnostic-form" class="form list">
              <ul class="border-radious" ref="gender">

                <li id="doyousmoke" ref="qref_11">
                  <div class="item-content item-input">
                    <div class="item-inner">


                      <f7-block>
                        <f7-block-title>{{$t('diagnosis.youSmoke')}}</f7-block-title>
                        <f7-list  >
                          <f7-list-item @change="youSmokeFunctions($event); doYouSmoke= $event.target.value"  name="doyousmoke" value="tak" radio radio-icon :title="$t('app.tak')" />
                          <f7-list-item  @change="youSmokeFunctions($event); doYouSmoke= $event.target.value"  name="doyousmoke" value="nie" radio radio-icon :title="$t('app.nie')" />
                        </f7-list>
                      </f7-block>


                      <div id="question-error" v-if="errors['doyousmoke']">{{errors['doyousmoke']}}</div>

                    </div>

                  </div>
                </li>

                <template v-if="doYouSmoke == 'tak'">
                  <li id="youWantStopSmoke" ref="qref_22">
                    <div class="item-content item-input">
                      <div class="item-inner">

                        <div class="item-input-wrap">

                          <f7-block>
                            <f7-block-title>{{$t('diagnosis.youWantStopSmoking')}}</f7-block-title>
                            <f7-list  >
                              <f7-list-item @change="doYouSmokeFunctions($event); youWantStopSmoke= $event.target.value"  name="youWantStopSmoke" value="tak" radio radio-icon :title="$t('app.tak')" />
                              <f7-list-item  @change="doYouSmokeFunctions($event); youWantStopSmoke= $event.target.value"  name="youWantStopSmoke" value="nie" radio radio-icon :title="$t('app.nie')" />
                            </f7-list>
                          </f7-block>


                          <div id="question-error" v-if="errors['youWantStopSmoke']">{{errors['youWantStopSmoke']}}</div>

                        </div>
                      </div>

                    </div>
                  </li>
                </template>

                <template v-if="doYouSmoke == 'nie'">
                  <li id="didyousmoke" ref="qref_33">
                    <div class="item-content item-input">
                      <div class="item-inner">

                        <div class="item-input-wrap">

                          <f7-block>
                            <f7-block-title>{{$t('diagnosis.didYouSmoke')}}</f7-block-title>
                            <f7-list  >
                              <f7-list-item @change="didYouSmokeFunctions($event); didYouSmoke= $event.target.value"  name="didyousmoke" value="tak" radio radio-icon :title="$t('app.tak')" />
                              <f7-list-item  @change="didYouSmokeFunctions($event); didYouSmoke= $event.target.value"  name="didyousmoke" value="nie" radio radio-icon :title="$t('app.nie')" />
                            </f7-list>
                          </f7-block>

                          <div id="question-error" v-if="errors['didyousmoke']">{{errors['didyousmoke']}}</div>

                        </div>
                      </div>

                    </div>
                  </li>
                </template>

                <template v-if="youWantStopSmoke == 'tak'">
                  <li id="didyousmoke" ref="qref_44">
                    <div class="item-content item-input">
                      <div class="item-inner">

                        <div class="item-input-wrap">


                          <f7-block>
                            <f7-block-title>{{$t('diagnosis.whenStopSmoke')}}</f7-block-title>
                            <f7-list  >
                              <f7-list-item @change="didYouSmokeFunctions($event); didYouSmoke= $event.target.value"  name="didyousmoke" value="tak" radio radio-icon :title="$t('app.tak')" />
                              <f7-list-item  @change="didYouSmokeFunctions($event); didYouSmoke= $event.target.value"  name="didyousmoke" value="nie" radio radio-icon :title="$t('app.nie')" />
                            </f7-list>
                          </f7-block>

                          <div id="question-error" v-if="errors['didyousmoke']">{{errors['didyousmoke']}}</div>

                        </div>
                      </div>

                    </div>
                  </li>
                </template>

                <template v-if="doYouSmoke == 'tak' && youWantStopSmoke == 'tak'">
                  <element-li :questions="questions" :errors="errors"></element-li>
                </template>

              </ul>

              <send @click.native="saveForm"></send>
            </form>
          </f7-block>
        </div>
      </template>

      <template v-else>
        <f7-block class="answer-loader">
          <div class="answer-loader-inside">
            <f7-preloader color="orange"></f7-preloader>
            <div class="answer-loader-txt">
              {{$t(loadingModule ? 'app.loading' : 'app.answer_analysis')}}
            </div>
          </div>
        </f7-block>
      </template>

    </template>

    <!-- Wskazówki - jeżeli pytania zostały zapisane -->
    <template v-else>
      <!-- <div class="score-bg">
          <div class="score-container">
              <div class="score-board">
                  <div class="score-board-inside">
                      <span class="score-board-icon">
                          <f7-icon f7="chart_bar_alt_fill"></f7-icon>
                      </span>
                      <span class="score-board-txt">
                          {{$t('diagnosis.your_score')}}:
                      </span>
                      <div class="score-board-counter">
                          {{score}} {{$t('diagnosis.pkt')}}
                      </div>
                  </div>
              </div>
          </div>
      </div> -->

      <f7-block class="block-color">
        <div class="header-block">
          <div>
            <f7-icon f7="graph_circle" class="header-icon"></f7-icon>
          </div>
          <div>
            <h3>
              {{$t('diagnosis.score_meaning')}}
            </h3>
          </div>
        </div>
      </f7-block>

      <f7-block class="score-tips-container">

        <tips-component :tips="tips"></tips-component>
      </f7-block>
    </template>

  </f7-page>
</template>

<script>
import NavbarComponent from '../components/navbar.vue';
import TipsComponent from '../components/tips';
import QuestionsComponent from '../components/questions';

import StoreCache from '../utils/storeCache';
import Auth from '../utils/Auth';
import User from '../utils/User';
import elementLi from '../components/createFormElement';
import send from '../components/component-button'
import _ from 'lodash';
import questions from "../assets/diagnosis/questions";
import diagnosisTips from "../assets/diagnosis/diagnosisTips";

export default {
  name: "diagnosis",
  data(){
    return{
      awaitCounter: 0,
      loading: false,
      loadingModule: true,
      user: '',
      doYouSmoke: 0,
      didYouSmoke: 0,
      youWantStopSmoke: 0,
      questions: [],
      diagnosisTips: null,
      answersSaved: false,
      answers: null,
      focused: '',
      errors: {},
    }
  },
  components: {
    QuestionsComponent,
    NavbarComponent,
    elementLi,
    send,
    TipsComponent
  },
  computed: {
    score: function() {
      const answers = (new StoreCache()).get("_q_diagnostyka");
      let score = 0;

      if (answers) {
        score = answers.score;
      }
      return `${score}/12`;
    },
    tips: function() {
      let tips = [];
      const inRange = (x, min, max) => {
        return Number(x) >= min && x <= max;
      }

      // Wskazówka 1.
      let tip1 = { title: this.diagnosisTips.block1.title };
      let userGroup;
      if (this.answers.score == -1) {
        tip1.title = this.diagnosisTips.block5.title;
        tip1.content = [this.diagnosisTips.block5.content[0]];
        userGroup = 1;
      }
      else if (this.answers.score == -2) {
        tip1.title = this.diagnosisTips.block6.title;
        tip1.content = [this.diagnosisTips.block6.content[0]];
        userGroup = 1;
      }
      else {
        switch (true) {
          case (inRange(this.answers.score, 0, 2)):
            tip1.content = [this.diagnosisTips.block1.content[0]];
            userGroup = 1;
            break;
          case (inRange(this.answers.score, 3, 5)):
            tip1.content = [this.diagnosisTips.block1.content[1]];
            userGroup = 2;
            break;
          case (inRange(this.answers.score, 6, 8)):
            tip1.content = [this.diagnosisTips.block1.content[2]];
            userGroup = 3;
            break;
          case (inRange(this.answers.score, 9, 12)):
            tip1.content = [this.diagnosisTips.block1.content[3]];
            userGroup = 4;
            break;
        }
      }
      tips.push(tip1);
      User.setGroup(userGroup);

      // Wskazówka 2.
      if (this.answers.answer_4) {
        let tip2 = { title: this.diagnosisTips.block2.title };
        if (this.answers.answer_4.length > 1) {
          tip2.content = [this.diagnosisTips.block2.content[1]];
        } else {
          tip2.content = [this.diagnosisTips.block2.content[0]];
        }
        tips.push(tip2);
      }

      // Wskazówka 3.
      if (this.answers.answer_5) {
        let tip3 = { title: this.diagnosisTips.block3.title };
        if (this.answers.answer_5.length > 1) {
          tip3.content = [this.diagnosisTips.block3.content[1]];
        } else {
          tip3.content = [this.diagnosisTips.block3.content[0]];
        }
        tips.push(tip3);
      }

      // Wskazówka 4.
      if (this.answers.answer_6) {
        let tip3 = { title: this.diagnosisTips.block4.title };
        switch (true) {
          case (inRange(this.answers.answer_6, 1, 1)):
            tip3.content = [this.diagnosisTips.block4.content[0]];
            break;
          case (inRange(this.answers.answer_6, 2, 3)):
            tip3.content = [this.diagnosisTips.block4.content[1]];
            break;
          case (inRange(this.answers.answer_6, 4, 5)):
            tip3.content = [this.diagnosisTips.block4.content[2]];
            break;
        }
        tips.push(tip3);
      }

      return tips;
    }
  },
  methods:{
    answerIsFilled(answer) {
      return answer && (
          (typeof answer === 'string' && answer != '0') ||
          (typeof answer === 'number' && answer != 0) ||
          (typeof answer === 'object' && answer.length > 0));
    },
    areAnswersSaved() {
      if (!Auth.uid)
        return false;

      this.user = User.username;
      const cache = new StoreCache();
      const answers = cache.get("_q_diagnostyka");
      const permissions = cache.get("_permissions");

      if (permissions && !permissions.includes('beforeQuitting'))
        return true;

      if (!answers || this.user == '' || !this.user){
        this.answersSaved = false;
        return false;
      }
      else if (answers && this.user != '') {
        this.answers = answers;
        this.answersSaved = true;
        return true;
      }
    },
    awaitAnswers(init = false) {
      if (init && this.areAnswersSaved()) {
        this.loadingModule = false;
        return;
      }

      setTimeout(() => {
        if (!this.areAnswersSaved() && this.awaitCounter < 10) {
          this.awaitAnswers();
        }
        else {
          this.loadingModule = false;
        }

      }, 1000);
      this.awaitCounter++;
    },
    changeColorActive(ref){
      if (this.$refs[ref]) {
        const el = this.$refs[ref];
        el.classList.add('focusIn');
        this.focused = ref;
      }
    },
    changeColorInactive(ref){
      if (this.$refs[ref]) {
        const el = this.$refs[ref];
        el.classList.remove('focusIn');
        this.focused = '';
      }
    },
    youSmokeFunctions(evt){
      console.log(evt, this.doYouSmoke)
      this.youWantStopSmoke = 0;
      evt.target.blur();
    },

    doYouSmokeFunctions(evt){
      evt.target.blur();
    },

    didYouSmokeFunctions(evt){

      if(this.didYouSmoke == 'tak' || this.didYouSmoke == 'nie'){

      }
      else{
        let li = document.querySelectorAll('#createFormElement li');
        let firstLi = document.querySelectorAll('#createFormElement li:first-child');
        firstLi[0].classList.remove('hide');
      }

      evt.target.blur();
    },
    saveForm(){
      let formData = this.$f7.form.convertToData('#diagnostic-form');

      const isValid = this.validate(formData);
      if (!isValid) return;

      let cache = new StoreCache();
      let score = 0;

      if (formData['doyousmoke'] == 'nie')
        score = -1;

      if (formData['doyousmoke'] == 'tak' && formData['youWantStopSmoke'] == 'nie')
        score = -2;

      //Zliczanie punktow od 1 do 4 pytania
      for(let i = 0; i<4; i++){
        if(formData[`answer_${i}`] != 0) {
          const answer = _.find(this.questions[i].answer, {'value': formData[`answer_${i}`]});
          console.log({answer: answer})
          if (answer) {
            score += parseInt(answer.score);
          }
          else {
            break;
          }
        }
      }
      formData.score = score;

      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.$f7.data.stats.saveAnswers("q_diagnostyka", formData);

        if (formData.score < 0) {
          User.addPermissions(["abstinence"]);
        } else {
          User.addPermissions(["beforeQuitting"]);
        }


        this.answers = formData;
        this.answersSaved = true;
      }, 2000);
    },
    validate(data) {
      this.errors = {};
      let valid = true;

      let qSelect = document.querySelectorAll('li select');
      let selectArr = [...qSelect];
      selectArr.forEach(el => {
        const li = el.closest('li');
        const liClassArr = [...li.classList];

        if (!liClassArr.includes('hide')) {
          const isRequired = true;

          if (li.id != '' && isRequired && (!el.value || !this.answerIsFilled(el.value))) {
            this.errors[li.id] = this.$t('questionsComponent.error_not_all_answered');
            valid = false;
          }
        }

      });

      let qInpt = document.querySelectorAll('li input');
      let inptArr = [...qInpt];
      let inptChecked = {};
      inptArr.forEach(el => {
        const li = el.closest('li');
        const liClassArr = [...li.classList];

        if (!liClassArr.includes('hide')) {
          const isRequired = true;

          if (!inptChecked[li.id])
            inptChecked[li.id] = false;

          if (el.checked)
            inptChecked[li.id] = true;
        }
      });

      Object.keys(inptChecked).forEach(inpt => {
        if (inpt && inpt != '' && !inptChecked[inpt]) {
          this.errors[inpt] = this.$t('questionsComponent.error_not_all_answered');
          valid = false;
        }
      });

      return valid;
    },
  },
  mounted() {
    let self = this;
    let cache = new StoreCache();

    this.$f7ready((f7)=>{

      User.verifySession();

      self.awaitAnswers(true);

      document.querySelector('#view-diagnosis').addEventListener('tab:show',()=>{
        // Wyłączenie panelu użytkownika jeżeli był włączony.
        self.$f7.views.main.router.navigate('/diagnosis/');
        self.areAnswersSaved();
      });
    })
  },
  created() {
    this.questions = this.$t('questions').questions
    this.diagnosisTips = this.$t('diagnosisTips').diagnosisTips
  },
}
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 600px) {
  .text-score-header {
    font-size: 5.5vw;
    margin-left: 2vw;
  }
  .score-tips-container h4 {
    font-size: 4vw;
  }
  .score-bg {
    height: 200px;
    margin-bottom: 60px !important;
  }
  .score-board {
    padding: 35px;
  }
  .score-board-counter {
    font-size: 16vw;
  }
}
@media only screen and (min-width: 601px) {
  .score-bg {
    height: 350px;
    margin-bottom: 8vh !important;
  }
  .score-board {
    margin-top: 10px;
    padding: 50px;
  }
  .score-tips-container h4 {
    font-size: 4vw;
  }
  .score-board-counter {
    font-size: 18vw;
  }
  .score-board-icon i {
    font-size: 7vw;
  }
}
@media only screen and (min-width: 780px) {
  .score-bg {
    height: 450px;
    margin-bottom: 8vh !important;
  }
  .score-board {
    margin-top: 20px;
    padding: 50px;
  }
}

.focusIn{
  background-color: transparent !important;
  border: 1px solid #da521f;
}

.form-list{
  display: flex;
  justify-content: space-between;
}

.block-color{
  background-color: #d53e06;
  margin: 0;
  padding: 25px 15px;
}

.login{
  color: white;
  font-size: 5vw;
}
.text-diagnostic{
  color: white;
  font-size: 4vw;
}

.form-container{
  background-color: #efeff4;
  height: auto;
}

.score-container {
  margin-top: 40px;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
}
.score-bg {
  background-color: #d53e06;
}
.score-board {
  border-radius: 15px;
  background-color: rgb(34, 35, 36);
  box-shadow: 0 0 7px 0px #000;
}
.score-board-icon {
  margin-right: 1vw;
}
.score-board-inside > span {
  font-family: 'TitilliuRegular';
  font-size: 6.5vw;
  color: #fff;
  font-weight: bold;
}
.score-board-inside {
  white-space: nowrap;
}
.score-board-counter {
  font-weight: bold;
  color: #ee562b;
}
.score-tips-container h4 {
  margin: 2vh 7vw 2vh 7vw;
}
</style>

<style lang="scss">
@media only screen and (max-width: 600px) {
  .page[data-name="diagnosis"] .intro {
    font-size: 3.8vw;
  }
  .page[data-name="diagnosis"] .item-title {
    font-size: 3.8vw;
  }
  .page[data-name="diagnosis"] select {
    font-size: 3.5vw;
  }
  .page[data-name="diagnosis"] .item-choice {
    font-size: 3.5vw;
  }
  .page[data-name="diagnosis"] #question-error {
    font-size: 3vw;
  }
}
@media only screen and (min-width: 601px) {
  .page[data-name="diagnosis"] .intro {
    font-size: 3.2vw;
  }
  .page[data-name="diagnosis"] .item-title {
    font-size: 3vw;
  }
  .page[data-name="diagnosis"] select {
    font-size: 2.5vw;
  }
  .page[data-name="diagnosis"] .item-choice {
    font-size: 2.5vw;
  }
  .page[data-name="diagnosis"] #question-error {
    font-size: 2.5vw;
  }
}

.page[data-name="diagnosis"] .page-content{
  background-color: #efeff4;
}

.page[data-name="diagnosis"] .focusIn{
  border: 1px solid #da521f !important;
  background-color: transparent !important;
}

.page[data-name="diagnosis"] .border-radious{
  border-radius: 25px;
}

.page[data-name="diagnosis"] .list ul:before{
  width: 0 !important;
}

.page[data-name="diagnosis"] .list ul{
  background: transparent !important;
}

.page[data-name="diagnosis"] .list li{
  background-color: white;
  margin-top: 10px;
  border-radius: 25px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #fff;
}

.page[data-name="diagnosis"] .item-outer {
  display: flex;

  > div {
    display: flex;
    justify-items: center;
    align-items: center;
  }

  .item-inner-left {
    padding: 0 1vw;
  }
  .item-inner-right {
    flex: 1;
  }
}
.page[data-name="diagnosis"] .item-title {
  padding: 2vw;
  color: #000;
}
.page[data-name="diagnosis"] .item-arrow-container {
  color: #da521f;
  margin-right: 3vw;

  i {
    font-size: 5vw;
  }
}
.page[data-name="diagnosis"] .input-with-value {
  color: #da521f;
}
.page[data-name="diagnosis"] select {
  // border-bottom: 1px solid grey;
  margin-bottom: 1vh;
}

.page[data-name="diagnosis"] #question-error {
  color: red;
}

.block-title {
  overflow:visible;
  font-size: 3.8vw;
}

.list ul ul {
  padding:0;
}
.md label.item-radio:not(.item-radio-icon-end) > .icon-radio {
  margin-right: 0;
}
.block {margin-bottom:0}

.page[data-name="diagnosis"] .list .list li {
  margin-top:0
}
</style>
