export default {
    quitting12WeekTips: {
        block1: {
            title: 'Zakończenie etapu rzucania palenia',
            content: [
                'Gratulujemy! Nie palisz już 12 tygodni. Twój organizm przywrócił już prawidłowe funkcjonowanie, a Ty umiesz już jak radzić sobie z potrzebą palenia. Ciesz się każdą chwilą bez papierosa i doceniaj siebie!',

                'Wróciłeś/łaś do palenia, co oznacza, że przed Tobą są kolejne próby rzucenia palenia. Dużo już wiesz o swoich nawykach i reakcjach organizmu. Lepiej też umiesz kontrolować potrzebę palenia. Wykorzystaj to przy następnej próbie. Nie zwlekaj i podejmij kolejną próbę rzucenia palenia.',
            ]
        },
    }
}