export default {
    abstinenceQuestionsTips: {
        block1: {
            title: 'Twoje postępy w utrzymaniu abstynencji',
            content: [
                'Pomyśl jakie korzyści z rzucenia palenia dzisiaj zaobserwowałeś/łaś?',
                'Zrób sobie prezent z okazji kolejnego dnia bez papierosa!',
                'Rzucałeś/łaś palenie z ważnych dla siebie powodów! Osiągnąłeś/łaś to!. ',
                'Kolejny raz dzisiaj poradziłeś/łaś sobie z chęcią palenia.',
                'Pamiętaj, żeby docenić swój sukces w rzuceniu palenia.',
                'Dzisiaj jest kolejny dzień, w którym dzięki swoim wysiłkom żyjesz zdrowiej.',
                'Pamiętaj o małej nagrodzie, za rzucenie palenia!',
                'Nie palisz? Super! Przekaż informację o aplikacji osobom, które chcą rzucić.',
                'Jesteś silny/silna psychicznie skoro dałeś/łaś radę rzucić palenie!',
                'Świetnie pokonujesz trudności!',
                'Juz nie palisz? Znakomicie! Odzyskujesz kontrolę nad swoim zdrowiem!',
                'Co zrobiłeś/łaś dzisiaj czego byś nie zrobił/ła, kiedy paliłeś/łaś papierosy?',
                'Czego nie musisz już robić odkąd nie palisz?',
                'Możesz być z siebie dumny/na!',
                'Zwróciłeś/łaś uwagę, który to już dzień bez papierosa!',
                'Inni mogą brać z Ciebie przykład!',
                'Rzuciłeś? Gratulacje! Nie każdemu się to udaje.',
                'Dostrzegaj wszystkie pozytywne zmiany.',
                'Rzucenie palenia to ogromny sukces, który świadczy o tym, że naprawdę jesteś niezłomny/na!',
                'Jak będziesz chciał/ła to wszystko Ci się uda.',
                'To, że utrzymujesz abstynencję jest dowodem, że jesteś naprawdę twardy/da.',
                'Nie palisz? Możesz sobie pogratulować. To jedna z trudniejszych rzeczy do zrobienia.',
                'Twoja skóra wygląda coraz lepiej!',
                'Przestając palić zredukowałełś/łaś ryzyko zachorowania na wiele poważnych chorób!',
                'Rzucając palenie zadbałeś/łaś o siebie i swoich bliskich!',
                'Gratulujemy! Nie czuć już od Ciebie dymu tytoniowego!',
                'Wielu innym nie udało się rzucić. Jesteś świetny/na!',
            ]
        },
    }
}