export default {
    diagnosisTips: {
        block1: {
            title: "Głębokość uzależnienia",
            content: [
                "Nie jesteś jeszcze silnie uzależniona/y od nikotyny, a palenie  tytoniu wynika głównie z potrzeb psychologicznych. Jeśli czujesz przymus palenia i trudno Ci wytrwać w abstynencji to głównym powodem są nawyki związane z używaniem papierosów i brak samej czynności palenia. Twoje uzależnienie fizyczne od nikotyny jeszcze nie występuje. Jednak każdy kolejny wypalany przez Ciebie papieros zbliża Cię do fizycznego uzależnienia i w konsekwencji możliwe jest, ze będziesz palić nałogowo, a rzucając palenie będziesz odczuwał dyskomfort fizyczny spowodowany brakiem nikotyny. Przygotowując się do rzucenia palenia nacisk powinien być położony na przygotowanie się psychiczne.",

                "Jesteś uzależniona/y od tytoniu w stopniu umiarkowanym. Twój organizm przyzwyczaił się do regularnych dawek nikotyny, a ty przyzwyczaiłeś/łaś się do sięgania po papierosa w pewnych określonych sytuacjach lub stanach emocjonalnych. Odstawiając papierosy możesz odczuwać różne dolegliwości spowodowane tym, że Twój organizm musi przyzwyczaić się do funkcjonowania bez narkotyku jakim jest nikotyna. Fizyczne objawy odstawienia nie powinny być silne i możesz łagodzić je stosując odpowiednie środki farmakologiczne. Odstawienie papierosów może też być dla Ciebie trudne ze względu na „wypracowane” przez lata palenia nawyki sięgania po papierosa. Wtedy kiedy zwykle paliłeś/łaś możesz odczuwać chęć palenia. Przygotowując się do rzucenia palenia warto rozważyć zastosowanie środków farmakologicznych, jednak nacisk powinien być położony na przygotowanie się psychiczne.",

                "Jesteś osobą silnie uzależnioną od palenia. Twoje uzależnienie wynika z fizycznego zapotrzebowania na nikotynę, która jest silnym narkotykiem i potrzeb psychologicznych (przyzwyczaiłeś/łaś się do sięgania po papierosa w pewnych określonych sytuacjach lub stanach emocjonalnych). Odczuwasz silny przymus palenia a brak papierosów odczuwasz jako dyskomfort fizyczny i psychiczny. Objawy odstawienia mogą być silne, ale możesz łagodzić je stosując odpowiednie środki farmakologiczne. Odstawienie papierosów może też być dla Ciebie trudne ze względu na „wypracowane” przez lata palenia nawyki sięgania po papierosa. Wtedy kiedy zwykle paliłeś/łaś możesz odczuwać silną chęć palenia. Przygotowując się do rzucenia palenia trzeba być przygotowanym na łagodzenie tych stanów stosując odpowiednie środki farmakologiczne przy równoczesnym odpowiednim przygotowaniu psychologicznym.",

                "Jesteś osobą bardzo silnie uzależnioną od palenia. Twoje uzależnienie wynika z fizycznego zapotrzebowania na nikotynę, która jest silnym narkotykiem i potrzeb psychologicznych (przyzwyczaiłeś/łaś się do sięgania po papierosa w pewnych określonych sytuacjach lub stanach emocjonalnych). Odczuwasz silny przymus palenia a brak papierosów odczuwasz jako poważny dyskomfort fizyczny i psychiczny. Fizyczne objawy odstawienia mogą być silne, ale możesz łagodzić je stosując odpowiednie środki farmakologiczne. Odstawienie papierosów może też być dla Ciebie trudne ze względu na „wypracowane” przez lata palenia nawyki sięgania po papierosa. Wtedy kiedy zwykle paliłeś/łaś możesz odczuwać silną chęć palenia. Rzucając palenie trzeba pamiętać o wcześniejszym przygotowaniu psychicznym i wsparciu farmakologicznym."
            ]
        },

        block2: {
            title: "Gotowość do rzucenia palenia",
            content: [
                "Nie jesteś przekonany/a, że rzucenie palenia jest dla Ciebie ważne. Zastanów się jakie korzyści przyniesie Ci odstawienie papierosów.",

                "Masz powody, dla których rzucenie palenia jest dla Ciebie ważne, ale warto zastanowić się jakie jeszcze pozytywne zmiany nastąpią w twoim codziennym życiu po odstawieniu papierosów.",
            ]
        },

        block3: {
            title: "Faza gotowości do rzucenia palenia",
            content: [
                "Dopiero zaczynasz proces rzucania palenia i nie wprowadzałeś/łaś jeszcze żadnych zmian. W kolejnym kroku postaraj się dokładnie przeanalizować kiedy palisz nawykowo i zastanów się, w jaki sposób możesz te nawyki zastąpić.",

                "Świetnie, że wprowadziłeś/łaś już zmiany w swoim paleniu. To zwiększa Twoje szanse na rzucenie palenia, bo świadczy to o tym, że potrafisz w jakimś stopniu kontrolować swoje palenie.",
            ]
        },

        block4: {
            title: "Wcześniejsze doświadczenia",
            content: [
                "To będzie Twoja pierwsza próba i nie wiesz jak reaguje Twój organizm na brak nikotyny. Przypomnij sobie jak się czujesz, kiedy nie palisz przez kilka godzin. Przeczytaj o objawach odstawienia i sposobach ich łagodzenia. Pamiętaj też o zidentyfikowaniu sytuacji, w których palisz nawykowo i zaplanowaniu sposobów na zastąpienie papierosa.",

                "Masz już pewne doświadczenie w rzucaniu palenia i udało ci się zmniejszyć liczbę wypalanych papierosów na jakiś czas. To oznacza, że w jakiś sposób potrafiłeś/łaś kontrolować swoją potrzebę palenia. Zastanów się jak to zrobiłeś/łaś? Co pomagało w powstrzymaniu chęci palenia, a co przeszkadzało?",

                "Masz już doświadczenie w rzucaniu palenia i udało ci się przez jakiś czas nie palić. To duży sukces, który oznacza, że w jakiś sposób potrafiłeś/łaś kontrolować swoją potrzebę palenia. Zastanów się jak to zrobiłeś/łaś? Co pomagało w powstrzymaniu chęci palenia, a co przeszkadzało? W jaki sposób radziłeś/łaś sobie w sytuacjach, w których zwykle palisz? Te wszystkie informacje pomogą Ci teraz skutecznie rzucić palenie."
            ]
        },

        block5: {
            title: "Tak trzymaj!",
            content: [
                "Nie jesteś uzależniony/a od palenia papiersów. Poleć naszą aplikację znajomym lub członkom rodziny borykającym się z tym nałogiem!",
            ]
        },
        
        block6: {
            title: "Może jednak?",
            content: [
                "Jesteś uzależniony/a od palenia papiersów. Być może jednak zdecydujesz się na próbę rzucenia?",
            ]
        },
    }
}