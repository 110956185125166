<template>
    <f7-block class="tips">

        <div v-for="(tip, i) in tips" v-bind:key="i" class="tip">

            <f7-card class="demo-card-header-pic">
                <f7-card-header
                    class="no-border"
                    valign="bottom"
                    v-if="tip.title != ''"
                >
                    <f7-icon :f7="getIcon(tip)" class="tip-icon" />
                    <span>{{tip.title}}</span>
                </f7-card-header>
                <f7-card-content>
                    <div class="tip-block" v-for="(paragraph, i) in tip.content" v-bind:key="i" v-html="paragraph"></div>
                </f7-card-content>
            </f7-card>
            
        </div>
        
    </f7-block>
</template>

<script>
    import $ from 'dom7';
    
    export default {
        name: "tips",
        props:['tips'],
        data() {
            return {
                
            }
        },
        methods: {
            getIcon(tip) {
                if (tip.icon) {
                    return tip.icon;
                }
                
                return tip.none ? 'lightbulb_slash' : 'lightbulb';
            }
        },
        mounted() {
            this.$f7ready(f7 => {
                $('.call-phone-no').on('click', function(e) {
                    window.plugins.CallNumber.callNumber(()=>{console.log('call')}, (e)=>{console.log(e)}, e.target.title, false)
                });
            })
        }
    }
</script>

<style lang="scss" scoped>
    @media only screen and (min-width: 601px) {
        .card-header {
            font-size: 3vw;
        }
        .card-content {
            font-size: 2.5vw;
        }
        .icon.tip-icon {
            font-size: 6vw;
        }
    }

    .tips {
        padding: 0 !important;
        margin: 0 !important;

        .tip {
            padding: 0.5vh 0 0.5vh 0;
        }
    }

    .icon.tip-icon {
        color: #d53e06;
        position: absolute;
        top: -10px;
        left: -5px;
    }

    .card-header {
        font-weight: bold;

        span {
            margin-left: 5vw;
        }
    }

    .card-content {
        text-align: justify;
    }

    .tip-block {
        margin: 5px 0;
    }

</style>