<template>
    <f7-page name="rating">
        <navbar-component hair-lines>
            <f7-link href="/diagnosis/" icon-f7="arrow_left"></f7-link>
            <template v-slot:title>{{$t('leftNavPanel.ocena')}}</template>
        </navbar-component>

        <f7-block>
            <f7-row>
                <f7-col class="">
                    <f7-icon material="control_point_duplicate" slot="media" class="nav-list-img"></f7-icon>
                </f7-col>
            </f7-row>
        </f7-block>

        <f7-block v-if="internetConnection">
            
            <template v-if="!ratingSubmitted && !ratingSubmittedStorage">
                <h3>
                    {{$t('rating.intro')}}
                </h3>
                
                <questions-component 
                    :name="'q_rating'" 
                    :questions="questions" 
                    :saveHandler="submit" 
                    :loadingAltTxt="$t('rating.submission')"
                ></questions-component>
            </template>

            <template v-else>
                <f7-card class="demo-card-header-pic">
                    <f7-card-header
                        class="no-border"
                        valign="bottom"
                    >
                        <h2>{{$t('rating.submitted_title')}}</h2>
                    </f7-card-header>
                    <f7-card-content>
                        {{$t('rating.submitted_txt')}}
                    </f7-card-content>
                </f7-card>
            </template>
        </f7-block>

        <f7-block v-else>
            <div class="card nointernet-container">
                <f7-icon f7="wifi_slash" class="nointernet-icon"></f7-icon>
                <div class="nointernet-message">
                    {{$t('rating.no_internet')}}
                </div>
            </div>
        </f7-block>

    </f7-page>

</template>

<script>
    import navbarComponent from '../components/navbar';
    import Cache from '../utils/storeCache';
    import User from '../utils/User';
    import Application from '../utils/Application';
    import QuestionsComponent from '../components/questions';

    export default {
        name: "rating",
        data(){
            return{
                questions: null,
                ratingSubmitted: false,
                internetConnection: Application.checkConnection(),
            }
        },
        components: {
            navbarComponent,
            QuestionsComponent,
        },
        computed: {
            ratingSubmittedStorage: function() {
                return this.$f7.data.stats.getRatingSubmitted();
            }
        },
        methods: {
            submit(data) {
                let answersReadable = Object.entries(data)
                .filter(answer => {
                    return answer[0] != 'score';
                })
                .map(answer => {
                    const qId = answer[0].split('_')[1];

                    const answerReadable = {
                        q: this.questions[qId].name,
                        a: this.questions[qId].answer[answer[1] - 1].name,
                    };

                    return answerReadable;
                });

                this.$f7.data.stats.saveRating(answersReadable)
                .then(res => {
                    this.$f7.data.stats.setRatingSubmitted();
                })
                .catch(err => {
                    console.log(err)
                });

                this.ratingSubmitted = true;
            },
        },
        created() {
            this.questions = this.$t('ratingQuestions').ratingQuestions;
        },
        mounted() {
            
        },
    }
</script>

<style lang="scss" scoped>
    @media only screen and (max-width: 600px) {
        .user-info {
            // font-size: 4.5vw;
        }
    }
    @media only screen and (min-width: 601px) {
        .user-info {
            font-size: 3vw;
        }
        h3 {
            font-size: 30px;
        }
    }

    .user-img {
        font-size: 30vw;
        color: #d53e06;
    }
    .user-info {
        display: flex;
        align-items: center;
    }
    .user-info > .block {
        margin: 0 !important;
        padding: 0 !important;
    }

    .btn-container {
        margin-top: 0;
        margin-bottom: 0;

        .btn-component {
            margin: 1vh !important;
        }
    }
</style>

<style lang="scss">
    .page[data-name="rating"] .page-content{
        background-color: #efeff4;
    }

    .page[data-name="rating"] #password_change_form {
    }
    .page[data-name="rating"] #password_change_form ul {
        padding: 2vw;
        border-radius: 25px;
    }
    .page[data-name="rating"] #password_change_form input {
        border-bottom: 1px solid grey;
    }
</style>