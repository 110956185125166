export default {
    quittingQuestionsQuestions:[
        {
            id: 0,
            name: 'Czy była dzisiaj sytuacja, w której zapanowałeś/łaś nad chęcią palenia i nie zapaliłeś/łaś?',
            type: 'select',
            required: true,
            dependsOn: null,
            answer: [
                {
                    name: 'Tak.',
                    value: '1',
                },
                {
                    name: 'Nie.',
                    value: '2',
                },
            ]
        },
        {
            id: 1,
            name: 'Jaki sukces dzisiaj odniosłeś/łaś w związku z rzucaniem palenia?',
            type: 'multicheckbox',
            required: true,
            dependsOn: [
                {
                    questionId: 0,
                    value: '1',
                }
            ],
            answer: [
                {
                    name: 'Nie zapaliłem/łam chociaż odczuwałem/łam chęć.',
                    value: '1',
                },
                {
                    name: 'Opóźniłem/łam zapalenie papierosa.',
                    value: '2',
                },
                {
                    name: 'Nie zapaliłem/łam w sytuacji, w której zwykle paliłem/łam.',
                    value: '3',
                },
                {
                    name: 'Odmówiłem/łam zapalenia papierosa.',
                    value: '4',
                },
                {
                    name: 'Nie myślałem/łam dłuższy czas o paleniu.',
                    value: '5',
                },
                {
                    name: 'Wypaliłem/łam mniej niż wczoraj.',
                    value: '6',
                },
                {
                    name: 'Wypaliłem/łam mniej niż przed tygodniem.',
                    value: '7',
                },
                {
                    name: 'Nie zapaliłem/łam ani jednego.',
                    value: '8',
                },
                {
                    name: 'Gdy pojawiła się chęć palenia udało mi się zająć swoja uwagę czymś innym.',
                    value: '9',
                },
                {
                    name: 'Inny sukces.',
                    value: '10',
                },
                {
                    name: 'Nie pamiętam dzisiaj żadnego sukcesu.',
                    value: '11',
                },
            ]
        },
    ],
}