<template>
    <f7-block id="prep-content-block">
        <div>
            <h2 id="pg_header">
                {{$t(showJournalResults || this.$f7.data.cigaretteJournalEnded ? 'beforeQuitting.journal_results' : 'beforeQuitting.journal')}}
            </h2>
        </div>

        <!-- Wyniki prowadzenia dziennika -->
        <beforeQuitting-krok1-component-results 
            :isModuleComplete="isModuleComplete" 
            :user-group="userGroup" 
            v-if="showJournalResults || this.$f7.data.cigaretteJournalEnded"
        >
        </beforeQuitting-krok1-component-results>

        <!-- Dziennik kontrolowanego palenia -->
        <beforeQuitting-krok1-component-journal 
            :user-group="userGroup"
            v-else
        >
        </beforeQuitting-krok1-component-journal>
        
        <div id="standard-btn">
            <button @click="nextStep()">
                <div>
                    <div>
                        {{$t('beforeQuitting.next_step')}}
                    </div>
                    <div>
                        <f7-icon f7="chevron_right"></f7-icon>
                    </div>
                </div>
            </button>
        </div>
    </f7-block>
</template>

<script>
    import StoreCache from '../../utils/storeCache.js';
    import User from '../../utils/User';
    import BeforeQuittingKrok1ComponentJournal from './beforeQuitting-krok1-journal';
    import BeforeQuittingKrok1ComponentResults from './beforeQuitting-krok1-results';

    export default {
        name: "beforeQuitting-krok1",
        props: ['nextStep', 'userGroup', 'showJournalResults', 'isModuleComplete'],
        data() {
            return {
            }
        },
        components: {
            BeforeQuittingKrok1ComponentJournal,
            BeforeQuittingKrok1ComponentResults
        },
    }
</script>

<style lang="scss" scoped>
    @media only screen and (max-width: 600px) {
        h2#pg_header {
            font-size: 4.5vw;
        }
    }
    @media only screen and (min-width: 601px) {
        h2#pg_header {
            font-size: 4.5vw;
        }
    }

    h2#pg_header {
        margin-bottom: 2vh;
        text-transform: uppercase;
    }
</style>

<style lang="scss">

</style>