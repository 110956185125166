<template>
    <f7-block id="prep-content-block">
        <div>
            <h2 id="pg_header">
                {{$t('beforeQuitting.step3_title')}}
            </h2>
        </div>
        <!-- Jeżeli pytania nie zostały zapisane -->
        <template v-if="!answersSaved">
            <questions-component
                :name="'q_s3_beforeQuitting'"
                :intro="$t('beforeQuitting.step3_intro')"
                :questions="questions"
                :saveHandler="saveQuestions"
            ></questions-component>
        </template>

        <!-- Wskazówki - jeżeli pytania zostały zapisane -->
        <template v-else>
            <tips-component :tips="tips"></tips-component>
        </template>

        <div id="standard-btn" v-if="answersSaved">
            <button @click="nextStep()">
                <div>
                    <div>
                        {{$t('beforeQuitting.next_step')}}
                    </div>
                    <div>
                        <f7-icon f7="chevron_right"></f7-icon>
                    </div>
                </div>
            </button>
        </div>
    </f7-block>
</template>

<script>
    import StoreCache from '../../utils/storeCache.js';
    import QuestionsComponent from '../../components/questions';
    import TipsComponent from '../../components/tips';
    import _ from 'lodash';

    export default {
        name: "beforeQuitting-krok2",
        props: ['nextStep', 'questionsAnswered', 'setQuestionsAnswered', 'isModuleComplete'],
        data() {
            return {
                answersSaved: this.questionsAnswered,
                questions: null,
                answer_1: null,
                answer_2: null,
                answer_3: null,
                step3Tips: null,
            }
        },
        computed: {
            tips: function() {
                let tips = [];
                const cache = new StoreCache();
                const answers = cache.get("_q_s3_beforeQuitting");

                // Wskazówka 1.
                let tip1 = { title: this.step3Tips.block1.title };
                if (JSON.parse(answers['q_0']).length > 0) {
                    tip1.content = [this.step3Tips.block1.content[0]];
                }
                else {
                    tip1.content = [this.step3Tips.block1.content[1]];
                }
                tips.push(tip1);

                // Wskazówka 2.
                let tip2 = { title: this.step3Tips.block2.title };
                switch (answers['q_1']) {
                    case "1":
                        tip2.content = [this.step3Tips.block2.content[0]];
                        break;
                    case "2":
                        tip2.content = [this.step3Tips.block2.content[1]];
                        break;
                    case "3":
                        tip2.content = [this.step3Tips.block2.content[2]];
                        break;
                    default:
                        tip2.content = [this.step3Tips.block2.content[3]];
                        break;
                }
                tips.push(tip2);

                return tips;
            }
        },
        components: {
            QuestionsComponent,
            TipsComponent,
        },
        methods: {
            saveQuestions() {
                this.answersSaved = true;
                this.setQuestionsAnswered('q_s3_beforeQuitting');
                this.isModuleComplete();
            }
        },
        created() {
            this.questions = this.$t('beforeQuittingStep3Questions').beforeQuittingStep3Questions;
            this.step3Tips = this.$t('beforeQuittingStep3Tips').beforeQuittingStep3Tips;
        },
    }
</script>

<style scoped>
    @media only screen and (max-width: 600px) {
        div#tips-title {
            font-size: 3.5vw;
        }
    }
    @media only screen and (min-width: 601px) {
        div#tips-title {
            font-size: 3vw;
        }
        h2#pg_header {
            font-size: 4.5vw;
        }
        div#prep-paragraphs {
            font-size: 3vw;
        }
    }

    h2#pg_header {
        margin-bottom: 2vh;
        text-transform: uppercase;
    }

    div#tips-title {
        color: #d53e06;
        font-weight: bold;
    }
    div#prep-paragraphs {
        flex-grow: 1;
    }
</style>

<style lang="scss">
</style>
