export default {
    infoContent: [
`
<h2>Szkodliwość palenia</h2>
<p>Papierosy w trakcie procesu produkcji poddawane są złożonej obróbce chemicznej. Do tytoniu dodawane są substancje konserwujące, poprawiające smak czy sprzyjające szybszemu uzależnianiu się. W efekcie wraz z dymem tytoniowym wchłaniasz 7 tysiące substancji szkodliwych, z czego ponad 70 to substancje rakotwórcze! Spośród powszechnie znanych trucizn poza nikotyną dym tytoniowy zawiera min: aceton, cyjanowodór, kadm, arsen, amoniak, tlenek węgla, metanol, DDT, toluen, butan itd.<p>
<p>Uzależnienie od tytoniu pogarsza jakość życia, powodując choroby: </p>
<ol>
    <li>układu oddechowego (astmę, rozedmę płuc, przewlekłą obturacyjną chorobę płuc POCHP, zapalenie oskrzeli i płuc);</li>
    <li>układu krążenia (chorobę wieńcową, zawał serca, miażdżycę naczyń krwionośnych, nadciśnienie, choroby zakrzepowo-zatorowe w tym udar mózgu, chorobę Bǘrgera, tętniaka aorty); </li>
    <li>nowotworowe (istnieje związek przyczynowo skutkowy palenia z licznymi nowotworami złośliwymi m.in. płuca, krtani, gardła, przełyku, jamy ustnej, nosa, wargi, miedniczek nerkowych, pęcherza moczowego, trzustki, żołądka, wątroby); </li>
    <li>przewodu pokarmowego (wrzody żołądka, wrzody dwunastnicy); </li>
    <li>inne: osteoporozę, podatność na przeziębienia, alergie. </li>
</ol>
<p>Trzy wielkie grupy chorobowe występują prawie wyłącznie u palaczy papierosów – to rak płuca, – przewlekła obturacyjna choroba płuc (POCHP) oraz zawał serca przed 50 r.ż. Szacuje się, że rocznie z powodu palenia na świecie umiera przedwcześnie 5 mln ludzi i liczba ta stale rośnie.</p>
<p>Oczekiwana długość życia osób palących jest krótsza o ok.10 lat niż u osób nigdy nie palących. <strong>Niezależnie od wieku i liczby lat palenia zaprzestanie używania tytoniu przynosi wymierne korzyści zdrowotne!</strong> Rzucenie palenia przed ukończeniem 40 roku życia zmniejsza ryzyko zgonu z powodu chorób odtytoniowych około 90%, ale nawet odstawienie papierosów w późniejszym wieku powoduje, że zagrożenie zachorowaniem na choroby odtytoniowe spada w sposób ciągły wraz z upływem czasu od zaprzestania palenia. widzenia zdrowia publicznego. </p>
`,
`
<h2>Jak reaguje organizm na rzucenie palenia?</h2>
<p>Zawarta w dymie tytoniowym nikotyna jest substancją psychoaktywną tzn. działa jak narkotyk i jej używanie może prowadzić do uzależnienia. Można mówić o dwóch „rodzajach” uzależnienia, które najczęściej wzajemnie na siebie wpływają:</p>
<ol type="I">
    <li>Uzależnienie psychiczne: silny nawyk do sięgania po papierosa w pewnych określonych sytuacjach np. po posiłku. Jest to również silne przyzwyczajenie do regulowania stanów emocjonalnych poprzez palenie papierosa np. w sytuacjach stresowych. Po odstawieniu papierosów pojawia się uczucie braku czynności palenia.  </li>
    <li>Uzależnienie biologiczne: organizm przyzwyczaja się do tego, że na pracę mózgu i innych układów wpływa nikotyna i przystosowuje się do tego. Po odstawieniu papierosów następuje okres, w którym przywracane jest normalne funkcjonowanie organizmu. W tym czasie mogą być odczuwane różne dolegliwości m.in: </li>
</ol>

<h3>1. Drażliwość</h3>
<p>Może pojawić się już w kilka godzin po odstawieniu papierosów i trwać nawet kilka tygodni. Uczucie to bywa określane jako: napięcie wewnętrzne, rozdygotanie, przeskoki od smutku do radości, płaczliwość.</p>
<p>Co robić: </p>
<ul>
    <li>rozważyć zastosowanie środków farmakologicznych wspomagających rzucenie palenia.  </li>
    <li>unikać sytuacji, które nawykowo kojarzą się z paleniem lub stosować triki, które zastąpią czynność palenia.  </li>
    <li>dbać o komfort psychiczny: odpowiednia ilość snu, chwile relaksu w ciągu dnia. </li>
    <li>zwiększyć poziom aktywności fizycznej by rozładować napięcie.  </li>
    <li>uprzedzić swoje otoczenie, że rzucasz palenie i poprosił o wyrozumiałość i wsparcie. </li>
    <li>skonsultować się z lekarzem lub poradzić się specjalisty dzwoniąc do TPPP. </li>
</ul>

<h3>2. Spadek nastroju</h3>
<p>Ten objaw również występuje z różnym nasileniem u większości osób rzucających palenie. Jest bardzo niebezpieczny ponieważ powoduje myślenie typu: „i co z tego, że nie palę, skoro nic mnie nie cieszy”, „straciłem jedyną przyjemność w życiu”. </p>
<p>Co robić: </p>
<ul>
    <li>rozważyć zastosowanie środków farmakologicznych wspomagających rzucenie palenia.  </li>
    <li>Pamiętać, że to stan przejściowy spowodowany brakiem nikotyny. Wkrótce organizm „przypomni sobie” jak prawidłowo funkcjonować. </li>
    <li>Koncentrowac się na wszystkich pozytywnych zmianach, jakie przynosi rzucenie palenia. </li>
    <li>Nagradzać się za każdy sukces! </li>
    <li>skonsultować się z lekarzem lub poradzić się specjalisty dzwoniąc do TPPP </li>
</ul>

<h3>3. Problemy z koncentracją</h3>
<p>Jest to jeden z objawów występujących u większości osób rzucających palenie. Może utrzymywać się kilka lub kilkanaście dni. Problemy z utrzymaniem uwagi i koncentracji mogą skutecznie utrudniać aktywność zawodową czy inną aktywność intelektualną i bywają powodem nawrotu do uzależnienia. </p>
<p>Co robić: </p>
<ul>
    <li>rozważyć zastosowanie środków farmakologicznych wspomagających rzucenie palenia.  </li>
    <li>Dać sobie przyzwolenie na czasowe rozkojarzenie (może kilka dni urlopu?) </li>
    <li>Pamiętać, że ten objaw mija już po kilku dniach </li>
    <li>skonsultować się z lekarzem lub poradzić się specjalisty dzwoniąc do TPPP </li>
</ul>

<h3>4. Zawroty głowy </h3>
<p>To objaw dość powszechny u wieloletnich palaczy. Może utrzymywać się z różnym nasileniem przez kilka dni i bywa dość dolegliwy. </p>
<p>Co robić: </p>
<ul>
    <li>Pamiętać, że ten objaw mija już po kilku dniach </li>
    <li>Unikać dużego wysiłku  </li>
</ul>

<h3>5. Bezsenność </h3>
<p>To objaw występujący dość często. Bywa bardzo niebezpieczny dla osoby walczącej z uzależnieniem, ponieważ powoduje przewlekłe uczucie przemęczenia a tym samym spadek nastroju i poczucie braku sensu starań. Nie może być lekceważony ze względu na ryzyko złamania abstynencji. </p>
<p>Co robić: </p>
<ul>
    <li>rozważyć zastosowanie środków farmakologicznych wspomagających rzucenie palenia.  </li>
    <li>ograniczyć spożywanie kawy i herbaty (zwłaszcza w godzinach popołudniowych) </li>
    <li>zwiększyć poziom wysiłku fizycznego (chociaż kilka ćwiczeń fizycznych w ciągu dnia, ale najlepiej nie później niż dwie godziny przed snem) </li>
    <li>dobrze wietrzyć pomieszczenia przed snem </li>
    <li>wypracować inny rytuał, który sprzyja zasypianiu  </li>
    <li>skonsultować się z lekarzem lub poradzić się specjalisty dzwoniąc do TPPP </li>
</ul>

<h3>6. Wzmożony kaszel, drapanie w gardle </h3>
<p>Jest to bardzo często występujący objaw  u osób palących od wielu lat. Pojawia się zwykle w kilka dni po rzuceniu palenia i może trwać do kilku tygodni. Istnieje czysto biologiczna przyczyna występowania tego objawu. Wzmożony kaszel i intensywne wydzielanie się flegmy są efektem oczyszczania się Twoich dróg oddechowych z osadu pozostawionego przez papierosy. Nawet więc, jeśli jest to dla Ciebie uciążliwe pamiętaj, że jest to objaw korzystny dla Twojego organizmu! </p>
<p>Co robić: </p>
<ul>
    <li>złagodzić ten objaw stosując środki zwykle wykorzystywane w takich przypadkach np.  pastylki lub tabletki na gardło, zastosować dostępne w aptekach środki wykrztuśne.  </li>
    <li>pić dużo płynów, a zwłaszcza wody.  </li>
    <li>zwiększyć poziom aktywności fizycznej (szczególnie treningi wydolnościowe pozwalają na systematyczne zwiększanie pojemności płuc) </li>
    <li>wykonywać ćwiczenia oddechowe, które przyspieszą oczyszczanie dróg oddechowych (np. usiądź wyprostowany i wciągnij maksymalnie dużo powietrza, a następnie powoli je wypuszczaj tak jakbyś zdmuchiwał świeczki na torcie) </li>
</ul>

<h3>7. Zwiększony apetyt </h3>
<p>Ten objaw występuje u większości osób rzucających palenie i może trwać nawet kilka miesięcy. Powodem jest m.in. spadek poziomu cukru, poprawa odczuwania smaków i zapachów oraz zastępowanie odruchu palenia odruchem podjadania. </p>
<p>Co robić: </p>
<ul>
    <li>zaplanować inne niż jedzenie sposoby zastąpienia czynności palenia </li>
    <li>stosować niskokaloryczne przekąski </li>
    <li>dbać o dobrze zbilansowaną dietę (5 posiłków dziennie) </li>
    <li>pić dużo płynów, a zwłaszcza wody  </li>
    <li>zwiększyć poziom aktywności fizycznej </li>
</ul>
`,

`
<h2>Gdzie szukać pomocy?</h2>
<h3>Porozmawiaj z ekspertem </h3>
<p>Rozmowa ze specjalistą o rzuceniu palenia może pomóc Ci lepiej przygotować się do próby odstawienia papierosów. W Telefonicznej Poradni Pomocy Palącym w prosty sposób możesz skontaktować się z doradcami  i uzyskać rzetelne poradnictwo, profesjonalne wsparcie oraz pomocne informacje. </p>

<p>Poradnictwo jest nieodpłatne! </p>
<h2>Jak się skontaktować? </h2>
<h3>Zadzwoń: </h3>
<p>Zadzwoń do Telefonicznej Poradni Pomocy Palącym i uzyskaj pomoc w rzuceniu palenia. Doradcy są dostępni od poniedziałku do piątku w godzinach 09.00-21.00 oraz w soboty 09.00-15.00.</p>
<span class="call-phone-no" title="801108108"><i class="f7-icons phone-call-icon">phone</i>801 108 108</span> – dla połączeń z telefonów stacjonarnych i komórkowych 
<ul>
    <li>koszt połączenia z telefonów stacjonarnych: równowartość jednego impulsu </li>
    <li>koszt połączenia z telefonów komórkowych: według taryfy operatora obsługującego numer rozmówcy </li>
</ul>

<span class="call-phone-no" title="222118015"><i class="f7-icons phone-call-icon">phone</i>22 211 80 15</span> – dla połączeń z telefonów komórkowych 
<ul>
    <li>koszt połączenia z telefonów komórkowych: według taryfy operatora obsługującego numer rozmówcy </li>
</ul>

<h3>Zamów konsultację: </h3>
<p>Doradcy pomogą w opracowaniu Indywidualnego Planu Terapii i wesprą przez cały czas trwania procesu rzucania palenia.</p>

<p>Zadzwoń do Telefonicznej Poradni Pomocy (<span class="call-phone-no" title="801108108"><i class="f7-icons phone-call-icon">phone</i>801 108 108</span>, <span class="call-phone-no" title="222118015"><i class="f7-icons phone-call-icon">phone</i>22 211 80 15</span>) od poniedziałku do piątku w  godzinach 09.00-21.00 oraz w soboty 09.00-15.00.</p>

<p>Powiedz doradcy, że chcesz zamówić konsultację. Ustal dogodny dla siebie termin (w godzinach pracy Poradni), podaj numer telefonu kontaktowego oraz imię, jakim będziesz się posługiwać.</p>

<h3>UWAGA!</h3>
<p>Ze względu na duże zainteresowanie, termin pierwszej konsultacji może wynosić od kilku do kilkunastu dni. Zachęcamy, by czas oczekiwania na konsultację wykorzystać na zastosowanie się do wskazówek zawartych w <a class="link external system-link" href="http://jakrzucicpalenie.pl/krok-1/">http://jakrzucicpalenie.pl/krok-1/</a>.</p>

<h3>Napisz do nas: </h3>
Napisz na adres <a class="link external system-link" href="mailto:biuro@jakrzucicpalenie.pl">biuro@jakrzucicpalenie.pl</a> <br />
Na pytanie odpowiemy w przeciągu trzech do siedmiu dni roboczych. <br /><br />
`
    ]
}