import Vue from 'vue';
import VueI18n from 'vue-i18n';
import pl from './lang/pl';
import StoreCache from '@/utils/storeCache';

Vue.use(VueI18n);

const cache = new StoreCache();

export function getLangConfig() {

    return cache.get('lang') || lang;
}

export function setLangConfig(lang) {
    cache.set('lang', lang);
    i18n.locale = lang
}

const messages = {
    pl,
};

let lang = 'pl';//navigator.language.split('-')[0];

const i18n = new VueI18n({
    locale: getLangConfig(),
    messages,
    fallbackLocale: 'pl'
})

export default i18n;