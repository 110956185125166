import Auth from './Auth.js';
import AppSettings from './AppSettings.js';
import Application from './Application.js';
import $ from 'dom7';

export default class User {

    static get id() {
        return this._id;
    }

    static set id(id) {
        this._id = id;
    }

    static get username() {
      return this._username;
    }

    static set username(username) {
        this._username = username;
    }

    static get email() {
      return this._email;
    }

    static set email(email) {
        this._email = email;
    }

    static get registered() {
      return this._registered;
    }

    static set registered(registered) {
        this._registered = registered;
    }

    static set f7(f7) {
        this._f7 = f7;
    }

    static set router(router) {
        this._router = router;
    }

    static set store(store) {
      this._store = store;
    }

    /**
     * Nadanie nowych uprawnień użytkownikowi.
     * Otrzymuje on wiadomość w aplikacji o dostępie
     * do kolejnych modułów.
     *
     * @static
     * @param {array} permissionNames
     * @memberof User
     */
    static addPermissions(permissionNames) {
      const self = this;

      let permissionsArray = this._store.get('_permissions') || [];
      permissionsArray = permissionsArray.concat(permissionNames);
      const permissionsArrayUnique = [...new Set(permissionsArray)]

      this._store.set('_permissions', permissionsArrayUnique);
      this._f7.data.stats.setUpdateRemote(true);

      if (permissionNames.length == 1) {
        // Powiadomienie o przynaniu dostępu.
        this._f7.dialog.create({
          title: window.vue.$t("permissions.permission_granted_title"),
          text: window.vue.$t("permissions.permission_granted_txt1") + window.vue.$t("permissions.names")[permissionNames[0]] + window.vue.$t("permissions.permission_granted_txt2"),
          buttons: [
              {
                  text: window.vue.$t("permissions.permission_granted_go_to_module"),
                  onClick: function() {
                    self._f7.tab.show('#view-' + permissionNames[0]);
                  }
              },
              {
                  text: "OK",
                  onClick: function(d) {
                      d.close();
                  }
              },
          ],
        }).open();
      }
      else {
        // Powiadomienie o przynaniu dostępów.
        this._f7.dialog.create({
          title: window.vue.$t("permissions.permissions_granted_title"),
          text: window.vue.$t("permissions.permissions_granted_txt", { modules: permissionNames.map(perm => window.vue.$t("permissions.names")[perm]).join(', ') }),
          buttons: [
              {
                  text: "OK",
                  onClick: function(d) {
                      d.close();
                  }
              },
          ],
        }).open();
      }
    }

    /**
     * Zmiana hasła użyt.
     *
     * @static
     * @param {*} newPwd
     * @returns {Promise}
     * @memberof User
     */
    static changePassword(newPwd) {
        const self = this;
        const headers = Auth.createBearerHeader();

        if (!headers) return;

        return new Promise((resolve, reject) => {
          this._f7.request({
            method: "POST",
            url: `${self._f7.data.api.url}/changePassword`,
            headers,
            data: {
                login: User.username,
                new_password: newPwd
            },
            success: function (response) {
                let parsedResponse = null;
                try {
                    parsedResponse = JSON.parse(response);
                }
                catch(e) {
                    console.log(e);
                    reject(window.vue.$t("userPanel.password_change_failure_unknown"));
                    return;
                }

                if (parsedResponse && parsedResponse.success) {
                    resolve(parsedResponse.message);
                }
                else {
                  reject(parsedResponse.message);
                }
            },
            error: function(err) {
              console.error('Error request');
              reject();
            }
          });
        });
    }

    /**
     * Sprawdza, czy użyt. posiada dane uprawnienie.
     *
     * @static
     * @param {string} key
     * @returns {boolean}
     * @memberof User
     */
    static checkPermission(key){
        let permissions = this._store.get('_permissions');

        if(permissions && permissions.includes(key))
            return true;

        return false;
    }

    /**
     * Zwraca grupę użytkownika.
     *
     * @static
     * @returns {number}
     * @memberof User
     */
    static getGroup() {
      const settings = this._store.get("_settings");
      if (settings && settings.userGroup) {
          return settings.userGroup;
      }
      return null;
    }

    /**
     * Pobiera uprawnienia użyt. z serwera.
     *
     * @static
     * @returns {Promise}
     * @memberof User
     */
    static getPermissions() {
        const self = this;
        const headers = Auth.createBearerHeader();
        const currentPermissionsArray = this._store.get('_permissions') || [];

        if (!headers) return;

        return new Promise((resolve, reject) => {
          this._f7.request({
            method: "GET",
            url: `${this._f7.data.api.url}/getAllUsersPermissions`,
            headers,
            success: function (response) {
              let parsedResponse = null;
              try {
                parsedResponse = JSON.parse(response);
              }
              catch(e) {
                console.error(e);
                reject();
                return;
              }

              if (parsedResponse && parsedResponse.success) {
                if (!self._f7.data.stats.getUpdateRemote()) {
                  let permissionsArray = parsedResponse.data ? parsedResponse.data.map(perm => {
                    return perm.name;
                  }) : [];

                  permissionsArray = permissionsArray.concat(currentPermissionsArray);
                  const permissionsArrayUnique = [...new Set(permissionsArray)];

                  self._store.set('_permissions', permissionsArrayUnique);
                }
                resolve();
              }
              else {
                console.error("Nie udało się pobrać uprawnień uzytkownika.");
              }
            },
            error: function (error) {
              console.error('Error request');
              reject();
            }
          });
        });

    }

    /**
     * Logowanie użytkownika.
     * Pobiera token autoryzacyjny.
     *
     * @static
     * @param {*} login
     * @param {*} pwd
     * @param {*} displayDialog funkcja do wyświetlania okna dialog
     * @param {*} resendActivationLink
     * @memberof User
     */
    static login(login, pwd, displayDialog, resendActivationLink) {
        const self = this;

        if (!Application.checkConnection()) {
          displayDialog(window.vue.$t("login.failure_nointernet_msg"), window.vue.$t("login.failure_nointernet_title"));
          return;
        }

        this._f7.request({
            method: "POST",
            url: `${this._f7.data.api.url}/appLogin`,
            data: {
                login: login,
                password: pwd
            },
            success: function (response) {
                let parsedResponse = null;
                try {
                    parsedResponse = JSON.parse(response);
                }
                catch(e) {
                    displayDialog(window.vue.$t("login.failure_unknown"), window.vue.$t("login.failure"));
                    console.log(e);
                    return;
                }
                console.log(response)

                if (parsedResponse && parsedResponse.success) {
                    Application.init(parsedResponse.data);
                }
                else {
                    if (parsedResponse.data && parsedResponse.data.inactive) {
                        self._f7.dialog.create({
                            title: window.vue.$t("login.failure"),
                            text: parsedResponse.message,
                            buttons: [
                                {
                                    text: window.vue.$t("login.send_activation"),
                                    onClick: resendActivationLink
                                },
                                {
                                    text: "OK",
                                    onClick: function(d) {
                                        d.close();
                                    }
                                },
                            ],
                        }).open();
                    }
                    else {
                        displayDialog(parsedResponse.message, window.vue.$t("login.failure"));
                    }
                    self._f7.data.isLoggedIn = false;
                }
            },
            error: function (error) {
                displayDialog(window.vue.$t("login.failure_unknown"), window.vue.$t("login.failure"));
                console.log("ERROR:", JSON.stringify(error));
            }
        });
    }

    /**
     * Wylogowane i usunięcie danych autoryzacyjnych z
     * local storage.
     *
     * @static
     * @memberof User
     */
    static logout() {
      this._f7.data.user = null;
      this._f7.data.isLoggedIn = false;
      this._store.remove('_loginData');
    }

    /**
     * Rejestracja użytkownika.
     *
     * @static
     * @param {*} login
     * @param {*} pwd
     * @param {*} email
     * @param {*} displayDialog funkcja do wyświetlania okna dialog
     * @memberof User
     */
    static register(login, pwd, email, displayDialog) {
        const self = this;

        this._f7.request({
            method: "POST",
            url: `${this._f7.data.api.url}/appRegister`,
            data: {
                login: login,
                password: pwd,
                email: email,
            },
            success: function (response) {
                let parsedResponse = null;

                try {
                    parsedResponse = JSON.parse(response);
                }
                catch(e) {
                    displayDialog(window.vue.$t("login.failure_unknown"), window.vue.$t("login.failure"));
                    console.log(e);
                    return;
                }

                if(parsedResponse.success){
                    self._f7.dialog.alert(window.vue.$t('registery.success_form_valid'),window.vue.$t('registery.success_form_title'),function(){
                        self._router.navigate('/login/');
                    });
                }
                else{
                    self._f7.dialog.alert(parsedResponse.message,window.vue.$t('registery.error_registery_account'));
                }

                self._f7.preloader.hide();

            },
            error: function (error) {
                self._f7.dialog.alert(window.vue.$t("login.failure_unknown"), window.vue.$t("registery.error_registery_account"));
                setTimeout(function (app) {
                        app.dialog.close();
                    },2000, self._f7);
                console.log(error);
                self._f7.preloader.hide();
            }
        });
    }

    static removePermission(permissionName) {
        const self = this;
        const headers = Auth.createBearerHeader();

        if (!headers || !permissionName || permissionName == "")
          return;

        this._f7.request({
          method: "GET",
          url: `${this._f7.data.api.url}/removeUserPermission/` + permissionName,
          headers,
          success: function (response) {
            let parsedResponse = null;
            try {
              parsedResponse = JSON.parse(response);
            }
            catch(e) {
              console.log(e);
              return;
            }

            if (parsedResponse && parsedResponse.success) {
              let permissionsArray = self._store.get('_permissions');
              permissionsArray = permissionsArray.filter(perm => {
                return perm != permissionName;
              });
              self._store.set('_permissions', permissionsArray);
            }

            console.log(parsedResponse.message);
          },
          error: function (error) {
            console.error('Error request');
          }
        });
    }

    /**
     * Wysyła uprawnienia użytkownika na serwer.
     *
     * @static
     * @returns {Promise}
     * @memberof User
     */
    static sendPermissions() {
      const self = this;

      return new Promise((resolve, reject) => {

        const headers = Auth.createBearerHeader();
        const permissionsArray = self._store.get('_permissions');

        self._f7.request({
          method: "POST",
          url: `${self._f7.data.api.url}/addUserPermissions`,
          headers,
          data: {
            permissions: JSON.stringify(permissionsArray),
          },
          success: function (response) {
            let parsedResponse = null;
            try {
                parsedResponse = JSON.parse(response);
            }
            catch(e) {
                console.error(e);
                reject();
                return;
            }

            if (parsedResponse && parsedResponse.success) {
                console.log('Zapisano uprawnienia.');
                resolve();
            }

          },
          error: function (error) {
            console.error('Error request');
            reject();
          }
        });

      });

    }

    /**
     * Przyporządkowuje użytkownika do grupy na podstawie
     * odpowiedzi z diagnostyki.
     *
     * @static
     * @param {*} groupNumber
     * @memberof User
     */
    static setGroup(groupNumber) {
      let settings = this._store.get("_settings");
      if (settings) {
          settings.userGroup = groupNumber;
      } else {
          settings = { userGroup: groupNumber };
      }
      this._store.set("_settings", settings);
    }

    /**
     * Sprawdza czy użytkownika ma aktywną sesją autoryzacyjną.
     * W razie braku połączenia z internetem i z danymi aytoryzacyjnymi
     * w local storage zwraca true - zakładamy wtedy, że użytkownik ciągle
     * jest zalogowany.
     *
     * @static
     * @returns {Promise}
     * @memberof User
     */
    static verifySession() {
      const self = this;
      const headers = Auth.createBearerHeader();

      const goToLogin = (err = null) => {
        if (err) {
          console.error(err);
        }
        this._f7.data.isLoggedIn = false;
        this._f7.views.main.router.navigate("/login/");
      };

      return new Promise((resolve, reject) => {
        // Użytkownik niezalogowany.
        if (!headers) {
          goToLogin();
          resolve(false);
          return;
        }

        // Użytkownik zalogowany, ale bez poł. z netem.
        if (headers && !Application.checkConnection()) {
          resolve(true);
          return;
        }

        this._f7.request({
          method: "GET",
          url: `${self._f7.data.api.url}/getUser`,
          headers,
          success: function (response) {
            let parsedResponse = null;
            try {
                parsedResponse = JSON.parse(response);
            }
            catch(e) {
                console.error(e);
                resolve(false);
                return;
            }

            if (parsedResponse && parsedResponse.success) {
              resolve(true);
            }
            else {
              goToLogin();
              resolve(false);
            }
          },
          error: function (err) {
            goToLogin(err);
            resolve(false);
          }
        });

      });
    }

}
