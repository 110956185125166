import Auth from './Auth.js';
import AppSettings from './AppSettings.js';
import User from './User.js';
import $ from 'dom7';
import * as moment from 'moment';

export default class Application {

    static set f7(f7) {
      this._f7 = f7;
    }

    static get params() {
        return this._params;
    }

    static set params(params) {
        this._params = params;
    }

    static set router(router) {
      this._router = router;
    }

    static set store(store) {
      this._store = store;
    }

    /**
     * Zwraca bool, czy urządzenie jest połączone z
     * internetem.
     *
     * @static
     * @returns {boolean}
     * @memberof Application
     */
    static checkConnection() {
      try {
        var networkState = navigator.connection.type;

        if (!Connection || !networkState || networkState == Connection.NONE) return false;

        var states = {};
        states[Connection.UNKNOWN]  = 'Unknown connection';
        states[Connection.ETHERNET] = 'Ethernet connection';
        states[Connection.WIFI]     = 'WiFi connection';
        states[Connection.CELL_2G]  = 'Cell 2G connection';
        states[Connection.CELL_3G]  = 'Cell 3G connection';
        states[Connection.CELL_4G]  = 'Cell 4G connection';
        states[Connection.CELL]     = 'Cell generic connection';
        states[Connection.NONE]     = 'No network connection';

        return states[networkState];
      } catch (err) {
         return 'dev'; // Do usunięcia na produkcji.
      }
      return false;
    }

    static checkUpdateRemote() {
      if (!Application.checkConnection() || !Auth.uid) return;

      const currentTime = moment().unix();

      if (currentTime % 10 === 0) {
        this._f7.data.stats.updateRemote();
      }
    }

    static get date() {
      const date = new Date();
      if (Application.platform === 'iOS') {
        const resultDate = date.setHours(date.getHours() + 1);
        return new Date(resultDate);
      }
      return date;
    }

    /**
     * Inicjalizacja aplikacji. Wywoływana po jej uruchomieniu.
     * Wykonuje różne działania w zależności od tego, czy
     * użytkownik jest zalogowany czy nie.
     *
     * Przede wszystkim:
     * 1. Ustawia event listenery.
     * 2. Pobiera dane z serwera.
     * 3. Ustawia statyczne właściwości klas.
     * 4. Tylko w tej metodzie zapisywana jest wizyta użytkownika
     *    na potrzeby statystyk.
     * 5. Jak wszystko poszło dobrze, przekierowuje użytkownika
     *    na diagnostykę.
     *
     * @static
     * @param {*} [loginResponse=null]
     * @memberof Application
     */
    static init(loginResponse = null) {
      // Eventy, powiadomienia.
      document.addEventListener("offline", Application.onOffline.bind(Application), false);
      document.addEventListener("online", Application.onOnline.bind(Application), false);
      document.addEventListener("pause", Application.onPause.bind(Application), false);
      document.addEventListener('beforeunload', Application.onShutdown.bind(Application));

      this._f7.data.isLoggedIn = true;

      if (loginResponse) {
        User.id = loginResponse.uid;
        User.username = loginResponse.username;
        User.email = loginResponse.email;
        User.registered = loginResponse.registered.replace(' ', 'T') + '.000Z';

        console.log({resp: loginResponse})

        this._store.set("_loginData", {
            uid: User.id,
            username: User.username,
            email: User.email,
            registered: User.registered,
            token: loginResponse.jwt,
        });
      }
      else {
        User.id = Auth.uid;
        User.username = Auth.username;
        User.email = Auth.email;
        User.registered = Auth.registered;
      }

      // Zrobione asynchronicznie, ponieważ dane z serwera są potrzebne
      // do poprawnego wyświetlenia widoków po logowaniu.
      if (Application.checkConnection()) {
        Promise.all([
          User.getPermissions(),
          this._f7.data.stats.getStats(),
          this._f7.data.stats.getUserSettings(),
          this._f7.data.stats.getAnswers(),
        ])
        .then(result => {
          this._f7.data.stats.updateRemote();

          if (loginResponse) {
              console.log("TUTAJ")

            this._router.navigate("/diagnosis/", {
              reloadAll: true,
            });
          }

          this._f7.data.stats.saveVisit();
        })
        .catch(err => {
          console.log(err)
        });
      }

      // Ustaw powiadomienia.
      AppSettings.setNotifications();

      console.log("TU");

      // Funkcje przy przejściach do poszczególnych tabów.
      this._f7.$("#view-beforeQuitting").on('tab:show', (event, ui) => {
        const stats = this._store.get('_stats');

        if (stats && stats.cigarettes)
          this._f7.data.cigaretteStats = Application.sortStats(stats.cigarettes);
        else if (!this._f7.data.stats.getUpdateRemote()) {
          this._f7.data.stats.getStats().then((res) => {
            this._f7.data.cigaretteStats = Application.sortStats(res.cigarettes);
          });
        }
      });

      // Zapisanie nowej wizyty na potrzeby rankingu (sprawdzanie aktywności użytkowników).
      if (!Application.checkConnection()) {
        this._f7.data.stats.saveVisit();
      }
    }

    static onOffline() {
      this._f7.data.stats.updateRemote();
      // alert("Handle the offline event");
    }

    static onOnline() {
      this._f7.data.stats.updateRemote();
      // alert("Handle the online event");
    }

    static onPause() {
      this._f7.data.stats.updateRemote();
      // alert("Handle the pause event");
    }

    static onShutdown() {
      alert('onShutdown')
      this._f7.data.stats.updateRemote();
      // alert("Handle the pause event");
    }

    static get platform() {
      if (typeof device === 'undefined') {
        return null;
      }
      return device.platform;
    }

    // Sortuje statystyki po dacie zapisu.
    static sortStats(stats) {
        return stats.sort((a, b) => (new Date(b.registered) > new Date(a.registered)) ? 1 : -1);
    }

}
