<template>
    <f7-block id="prep-content-block">
        <div>
            <h2 id="pg_header">
                {{$t('beforeQuitting.step4_title')}}
            </h2>
        </div>

        <div v-html="content.introGroup1" v-if="userGroup == 1"></div>

        <template v-else>
            <div v-html="content.introGroup234"></div>
            
            <!-- Jeżeli pytania nie zostały zapisane -->
            <template v-if="!answersSaved">
                <questions-component
                    :name="'q_s4_beforeQuitting'" 
                    :questions="questions" 
                    :saveHandler="saveQuestions" 
                ></questions-component>
            </template>

            <!-- Wskazówki - jeżeli pytania zostały zapisane -->
            <div class="tips-container" v-else>
                <tips-component :tips="tips"></tips-component>
            </div>
        </template>
        
        <div id="standard-btn" v-if="answersSaved || userGroup == 1">
            <button @click="nextModule()">
                <div>
                    <div>
                        {{$t('app.next_module')}}
                    </div>
                    <div>
                        <f7-icon f7="chevron_right"></f7-icon>
                    </div>
                </div>
            </button>
        </div>
    </f7-block>
</template>

<script>
    import StoreCache from '../../utils/storeCache.js';
    import QuestionsComponent from '../../components/questions';
    import TipsComponent from '../../components/tips';
    import _ from 'lodash';
    import $ from 'dom7';

    export default {
        name: "beforeQuitting-krok2",
        props: ['nextStep', 'questionsAnswered', 'setQuestionsAnswered', 'userGroup', 'isModuleComplete'],
        data() {
            return {
                content: null,
                answersSaved: this.questionsAnswered,
                questions: null,
                answer_1: null,
                answer_2: null,
                answer_3: null,
                step4Tips: null,
            }
        },
        computed: {
            tips: function() {
                let tips = [];
                const cache = new StoreCache();
                const answers = cache.get("_q_s4_beforeQuitting");

                // Wskazówka 1.
                let tip1 = { title: this.step4Tips.block1.title };
                
                if (answers['q_0'] == '2') {
                    tip1.content = [this.step4Tips.block1.content[0]];
                }
                else {
                    if (answers['q_1'] == '7') {
                        switch (answers['q_2']) {
                            case "1":
                                tip1.content = [this.step4Tips.block1.content[2]];
                                break;
                            case "2":
                                tip1.content = [this.step4Tips.block1.content[3]];
                                break;
                            case "3":
                                tip1.content = [this.step4Tips.block1.content[4]];
                                break;
                            case "4":
                                tip1.content = [this.step4Tips.block1.content[5]];
                                break;
                            case "5":
                                tip1.content = [this.step4Tips.block1.content[6]];
                                break;
                            case "6":
                                tip1.content = [this.step4Tips.block1.content[7]];
                                break;
                        }
                    }
                    else {
                        tip1.content = [this.step4Tips.block1.content[1]];
                    }
                }
                tips.push(tip1);

                return tips;
            }
        },
        components: {
            QuestionsComponent,
            TipsComponent,
        },
        methods: {
            nextModule() {
                const hasPermission = this.verifyPermission('quitting');
                if (hasPermission) {
                    this.$f7.tab.show('#view-quitting');
                }
            },
            saveQuestions() {
                this.answersSaved = true;
                this.setQuestionsAnswered('q_s4_beforeQuitting');
                this.isModuleComplete();
            }
        },
        created() {
            this.questions = this.$t('beforeQuittingStep4Questions').beforeQuittingStep4Questions;
            this.step4Tips = this.$t('beforeQuittingStep4Tips').beforeQuittingStep4Tips;
            this.content = this.$t('beforeQuittingStep4Content').beforeQuittingStep4Content;
        },
        mounted() {
            this.$f7ready(f7=>{
                $('.call-phone-no').on('click', function(e) {
                    window.plugins.CallNumber.callNumber(()=>{console.log('call')}, (e)=>{console.log(e)}, e.target.title, false)
                });
            })
        }
    }
</script>

<style scoped>
    @media only screen and (max-width: 600px) {
        div#tips-title {
            font-size: 3.5vw;
        }
    }
    @media only screen and (min-width: 601px) {
        div#tips-title {
            font-size: 3vw;
        }
        h2#pg_header {
            font-size: 4.5vw;
        }
        div#prep-paragraphs {
            font-size: 3vw;
        }
    }

    h2#pg_header {
        margin-bottom: 2vh;
        text-transform: uppercase;
    }

    div#tips-title {
        color: #d53e06;
        font-weight: bold;
    }
    div#prep-paragraphs {
        flex-grow: 1;
    }

    .tips-container {
        margin-top: 15px;
    }
</style>

<style lang="scss">
    .dashed-list {
        list-style-type: none;
    }
    .dashed-list > li {
        position: relative;
        left: -25px;
    }
    .dashed-list > li:before {
        content: "-";
        margin-right: 5px;
    }
</style>