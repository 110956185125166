<template>
    <div id="prep-content-block">
        <div v-if="$f7.data.timeTillEndOfCigaretteJournal" id="timer-container">
            <h4>{{$t('beforeQuitting.journal_time_till_end')}}:</h4>
            <div class="timer-block" id="timer-block-days" v-if="$f7.data.timeTillEndOfCigaretteJournal.days > 0">
                <div class="timer-block-inside">
                    <span class="timer-block-txt">
                        {{$t('beforeQuitting.journal_timer_days')}}
                    </span>
                    <div class="timer-block-counter">
                        {{$f7.data.timeTillEndOfCigaretteJournal ? $f7.data.timeTillEndOfCigaretteJournal.days : 0}}
                    </div>
                </div>
            </div>
            <div class="timer-block" id="timer-block-hours" v-if="$f7.data.timeTillEndOfCigaretteJournal.hours > 0">
                <div class="timer-block-inside">
                    <span class="timer-block-txt">
                        {{$t('beforeQuitting.journal_timer_hours')}}
                    </span>
                    <div class="timer-block-counter">
                        {{$f7.data.timeTillEndOfCigaretteJournal ? $f7.data.timeTillEndOfCigaretteJournal.hours : 0}}
                    </div>
                </div>
            </div>
            <div class="timer-block" id="timer-block-minutes" v-if="$f7.data.timeTillEndOfCigaretteJournal.minutes > 0">
                <div class="timer-block-inside">
                    <span class="timer-block-txt">
                        {{$t('beforeQuitting.journal_timer_minutes')}}
                    </span>
                    <div class="timer-block-counter">
                        {{$f7.data.timeTillEndOfCigaretteJournal ? $f7.data.timeTillEndOfCigaretteJournal.minutes : 0}}
                    </div>
                </div>
            </div>
            <div class="timer-block" id="timer-block-seconds" v-if="$f7.data.timeTillEndOfCigaretteJournal.seconds > 0">
                <div class="timer-block-inside">
                    <span class="timer-block-txt">
                        {{$t('beforeQuitting.journal_timer_seconds')}}
                    </span>
                    <div class="timer-block-counter">
                        {{$f7.data.timeTillEndOfCigaretteJournal ? $f7.data.timeTillEndOfCigaretteJournal.seconds : 0}}
                    </div>
                </div>
            </div>
        </div>
        <div id="tips" v-else>
            <f7-icon f7="lightbulb"></f7-icon> {{$t('beforeQuitting.journal_why')}}
        </div>
        <div id="journal-instructions" v-if="$f7.data.timeTillEndOfCigaretteJournal">
            {{$t('beforeQuitting.journal_intro_started', {group_time: userGroup != 1 ? $t('app.timeSpans.24h') : $t('app.timeSpans.week') })}}
        </div>
        <div id="journal-instructions" v-else>
            {{$t('beforeQuitting.journal_intro', {group_time: userGroup != 1 ? $t('app.timeSpans.24h') : $t('app.timeSpans.week') })}}
        </div>
        <div id="journal-inputs">

            <div class="journal-input">
                <div class="item-title top-journal-desc">{{$t('beforeQuitting.journal_input', { opt: userGroup != 1 ? 'godzinę i ' : '' })}}</div>
                <div class="journal-input-fields">
                    <div class="journal-input-fields-left">
                        <ul>
                            <li>
                                <div class="item-content item-input">
                                    <div class="item-inner">
                                        <f7-list form class="">
                                            <f7-list-input
                                                v-if="userGroup != 1"
                                                :label="$t('beforeQuitting.journal_time')"
                                                type="time"
                                                :value="cig_input.time"
                                                :error-message="errorMessage.time"
                                                :error-message-force="forceError.time"
                                                @input="cig_input.time = $event.target.value"
                                                ref="time"
                                            ></f7-list-input>
                                            <f7-list-input
                                                :label="$t('beforeQuitting.journal_situation')"
                                                placeholder="..."
                                                type="select"
                                                defaultValue=""
                                                :value="cig_input.situation"
                                                :error-message="$t('beforeQuitting.error_invalid_situation')"
                                                :error-message-force="forceError.situation"
                                                @input="cig_input.situation = $event.target.value"
                                                ref="situation"
                                            >
                                                <option value="">-</option>
                                                <option v-for="(situation, i) in situations" v-bind:key="i" :value="situation">{{situation}}</option>
                                            </f7-list-input>
                                        </f7-list>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="journal-input-fields-right" @click="addCigarette()">
                        <f7-icon material="smoking_rooms"></f7-icon>
                        <f7-button>{{$t($f7.data.timeTillEndOfCigaretteJournal ? 'app.save' : 'app.start')}}</f7-button>
                    </div>
                </div>
            </div>

            <template v-if="$f7.data.cigaretteStats">
                <f7-list id="registered-cigarettes-list">
                    <f7-list-item
                        v-for="(cigarette) in $f7.data.cigaretteStats"
                        :key="cigarette.id"
                        :header="cigaretteDate(cigarette.registered)"
                        :title="cigarette.situation"
                    >
                        <f7-icon material="smoking_rooms"></f7-icon>
                    </f7-list-item>
                </f7-list>
            </template>

        </div>
    </div>
</template>

<script>
    import StoreCache from '../../utils/storeCache.js';
    import User from '../../utils/User';
    import Application from '../../utils/Application';
    import * as moment from 'moment';

    export default {
        name: "beforeQuitting-krok1-journal",
        props: ['userGroup'],
        data() {
            return {
                cig_input: {
                    time: null,
                    situation: null
                },
                situations: this.$t('beforeQuitting.situations'),
                errorMessage: {
                    time: '',
                },
                forceError: {
                    time: null,
                    situation: null
                },
            }
        },
        computed: {
            timeToDate: function() {
                const currentDate = this.extractDate(this.getDate());

                if (this.cig_input.time) {
                    return moment(currentDate + " " + this.cig_input.time, "DD.MM.YYYY HH:mm");
                } else {
                    return this.getDate();
                }
            }
        },
        watch: {
            'cig_input.time': function(current, prev) {
                const journal = (new StoreCache).get('_settings').cigaretteJournal;

                let journalStartDate = null;
                if (journal) {
                    journalStartDate = moment(Number(journal.startTime));
                }

                console.log({
                  t1: this.timeToDate,
                  t2: this.getDate()
                })

                if (this.timeToDate > this.getDate() || (journal && this.timeToDate < journalStartDate) || current == '') {
                    this.forceError.time = true;
                    this.$refs.time.$data.isInvalid = true;
                    if (this.timeToDate > this.getDate())
                        this.errorMessage.time = this.$t('beforeQuitting.error_time_after_now');
                    else if (journal && this.timeToDate < journalStartDate) {
                        this.errorMessage.time = this.$t('beforeQuitting.error_time_before_journal');
                    }
                    else
                        this.errorMessage.time = this.$t('beforeQuitting.error_empty_time');
                } else {
                    this.forceError.time = false;
                    this.$refs.time.$data.isInvalid = false;
                  this.errorMessage.time = false;
                }
            },
            'cig_input.situation': function(current, prev) {
                const self = this;
                if (current == '') {
                    self.forceError.situation = true;
                    self.$refs.situation.$data.isInvalid = true;
                } else {
                    self.forceError.situation = false;
                    self.$refs.situation.$data.isInvalid = false;
                }
            },
        },
        methods: {
            addCigarette() {
                let validation = this.validate();
                if (!validation) return;

              let time = null;

              // Pobranie bieżącej daty jeżeli to rozpoczęcie dziennika.
              if (this.userGroup != 1) {
                time = this.timeToDate;
              }
              else {
                time = this.getDate();
              }

              // Różnica między czasem rejestracji papierosa a wprowadzonym czasem.
              const diff = moment().valueOf() - time.valueOf();

              this.$f7.data.stats.startCigaretteJournal(diff);
              this.$f7.data.stats.saveCigarette({
                time:       time.toISOString(),
                situation:  this.cig_input.situation
              });
              this.getCigaretteStats();
              this.reset();

                // this.$f7.dialog.create({
                //     title: this.$t('beforeQuitting.journal_input_confirm_title'),
                //     text: this.$t('beforeQuitting.journal_input_confirm_content'),
                //     buttons: [
                //         {
                //             text: this.$t('app.cancel'),
                //             onClick: (d) => { d.close(); }
                //         },
                //         {
                //             text: "OK",
                //             onClick: (d) => {
                //                 let time = null;
                //
                //                 // Pobranie bieżącej daty jeżeli to rozpoczęcie dziennika.
                //                 if (this.userGroup != 1) {
                //                     time = this.timeToDate;
                //                 }
                //                 else {
                //                     time = this.getDate();
                //                 }
                //
                //                 // Różnica między czasem rejestracji papierosa a wprowadzonym czasem.
                //                 const diff = moment().valueOf() - time.valueOf();
                //
                //                 this.$f7.data.stats.startCigaretteJournal(diff);
                //                 this.$f7.data.stats.saveCigarette({
                //                     time:       time.toISOString(),
                //                     situation:  this.cig_input.situation
                //                 });
                //                 this.getCigaretteStats();
                //                 this.reset();
                //                 d.close();
                //             }
                //         },
                //     ],
                // }).open();
            },
            cigaretteDate(date) {
                date = new Date(date);
                if (this.userGroup != 1) {
                    date = this.formatDate(date, true);
                }
                else {
                    date = this.formatDate(date) + ' ' + this.$t('app.daysOfWeek')[date.getDay()];
                }
                return date;
            },
            reset() {
                this.cig_input.time = null;
                this.cig_input.situation = null;
            },
            getCigaretteStats() {
                this.$f7.data.cigaretteStats = (new StoreCache).get('_stats') ? Application.sortStats((new StoreCache).get('_stats').cigarettes) : null;
            },
            validate() {
                let valid = true;

                for (let [key, ref] of Object.entries(this.$refs)) {
                    if (ref) {
                        if ((ref.state && ref.state.inputInvalid) || ref.$data.isInvalid || ref.value == "" || !ref.value) {
                            this.forceError[key] = true;
                            if (key === 'time' && this.errorMessage.time === '') {
                                this.errorMessage.time = this.$t('beforeQuitting.error_empty_time');
                            }
                            valid = false;
                        }
                    }
                }
                return valid;
            }
        },
        mounted() {
            this.$f7ready(f7 => {

            });
        },
    }
</script>

<style lang="scss" scoped>
    @media only screen and (max-width: 600px) {
        div#tips {
            font-size: 3.5vw;
        }
        div#tips i {
            font-size: 5vw;
        }
    }
    @media only screen and (min-width: 601px) {
        div#tips {
            font-size: 3vw;
        }
        div#tips i {
            font-size: 4vw;
        }
        div#journal-instructions {
            font-size: 3vw;
        }
    }

    div#tips {
        color: #d53e06;
        font-weight: bold;
    }

    .top-journal-desc {
        padding: 20px 20px 0 20px !important;
    }

    div#journal-inputs {
        flex-grow: 1;
    }
    .journal-input {
        margin: 2vh 0;
        border-radius: 25px;
        box-shadow: 2px 4px rgba(0, 0, 0, 0.22), 0 1px 2px rgba(0, 0, 0, 0.24);
    }
    .journal-input ul {
        padding: 0 !important;
    }
    .journal-input-fields {
        display: flex;
    }
    .journal-input-fields-left {
        flex-grow: 1;
    }
    .journal-input-fields-right {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #d53e06;
        min-width: 20%;
        padding: 10px;
    }
    .journal-input-fields-right i {
        font-size: 15vw;
    }
    .journal-input-fields-right .button {
        color: #d53e06;
        font-family: 'TitilliuBold';
        font-size: 4vw;
    }

    #timer-container {
        margin-bottom: 10px;

        h4 {
            margin: 2px 0;
        }
    }
    #timer-container {
        margin-bottom: 10px;
    }
    .timer-block {
        display: inline-block;
        padding: 3px 7px;
        border-radius: 15px;
        background-color: rgb(34, 35, 36);
        box-shadow: 0 0 3px 0px #000;
    }
    .timer-block-inside > span {
        color: #fff;
        font-weight: bold;
    }
    .timer-block-inside {
        white-space: nowrap;
    }
    .timer-block-counter {
        display: inline-block;
        font-weight: bold;
        color: #d53e06;
    }
</style>

<style lang="scss">
    @media only screen and (max-width: 600px) {
        .page[data-name="beforeQuitting"] .journal-input .item-label {
            font-size: 4.5vw;
        }
    }
    @media only screen and (min-width: 601px) {
        .page[data-name="beforeQuitting"] .journal-input .item-title {
            font-size: 3vw;
        }
        .page[data-name="beforeQuitting"] .journal-input .item-label {
            font-size: 3vw;
        }
        .page[data-name="beforeQuitting"] .journal-input input {
            font-size: 4vw;
        }
        .page[data-name="beforeQuitting"] .journal-input .item-input-error-message {
            font-size: 2.5vw;
        }
        .page[data-name="beforeQuitting"] #registered-cigarettes-list .item-header {
            font-size: 2.5vw;
        }
        .page[data-name="beforeQuitting"] #registered-cigarettes-list .item-title {
            font-size: 3vw;
        }
        .page[data-name="beforeQuitting"] #registered-cigarettes-list li i {
            font-size: 6vw;
        }
    }

    /* Krok I */
    .page[data-name="beforeQuitting"] .journal-input ul:after{
        width: 0 !important;
    }

    .page[data-name="beforeQuitting"] .journal-input ul:before{
        width: 0 !important;
    }

    .page[data-name="beforeQuitting"] .journal-input ul{
        background: transparent !important;
    }

    .page[data-name="beforeQuitting"] .journal-input{
        background-color: white;
        border-radius: 25px;
    }
    .page[data-name="beforeQuitting"] .journal-input li {
        list-style: none;
    }

    .page[data-name="beforeQuitting"] .simple-list li:after, .links-list a:after, .journal-input .item-inner:after{
        width: 0;
    }

    .page[data-name="beforeQuitting"] .journal-input .item-title {
        padding: 2vw 2vw 0 2vw;
    }
    .page[data-name="beforeQuitting"] .journal-input .list {
        width: 90%;
        margin: 0 3vw 3vw 3vw;
    }
    .page[data-name="beforeQuitting"] .journal-input .item-label {
        margin-bottom: 1vh;
        font-weight: bold;
        color: #000;
    }
    .page[data-name="beforeQuitting"] .journal-input .item-input-invalid .item-label {
        color: red;
    }
    .page[data-name="beforeQuitting"] .journal-input input {
        border-bottom: 1px solid grey;
    }
    .page[data-name="beforeQuitting"] .journal-input .item-input-error-message {
        margin: 2vw;
        text-overflow: clip;
        overflow: visible;
        white-space: normal;
    }

    .page[data-name="beforeQuitting"] #registered-cigarettes-list ul {
        background: transparent !important;
    }
    .page[data-name="beforeQuitting"] #registered-cigarettes-list li {
        background-color: white;
        border-radius: 25px;
        margin: 1vh 0;
    }
    .page[data-name="beforeQuitting"] #registered-cigarettes-list .item-inner::after {
        display: none !important;
    }
    .page[data-name="beforeQuitting"] #registered-cigarettes-list li {
        border: 1px solid rgb(217, 190, 178);
    }
    .page[data-name="beforeQuitting"] #registered-cigarettes-list li:first-child {
        border: 1px solid #d53e06;
    }
    .page[data-name="beforeQuitting"] #registered-cigarettes-list li:nth-child(2) {
        border: 1px solid rgb(219, 115, 70);
    }
    .page[data-name="beforeQuitting"] #registered-cigarettes-list li:nth-child(3) {
        border: 1px solid rgb(217, 143, 111);
    }
    .page[data-name="beforeQuitting"] #registered-cigarettes-list li:nth-child(4) {
        border: 1px solid rgb(217, 162, 139);
    }
    .page[data-name="beforeQuitting"] #registered-cigarettes-list li:nth-child(5) {
        border: 1px solid rgb(217, 177, 160);
    }
    .page[data-name="beforeQuitting"] #registered-cigarettes-list li i {
        color: rgb(217, 190, 178);
    }
    .page[data-name="beforeQuitting"] #registered-cigarettes-list li:first-child i {
        color: #d53e06;
    }
    .page[data-name="beforeQuitting"] #registered-cigarettes-list li:nth-child(2) i {
        color: rgb(219, 115, 70);
    }
    .page[data-name="beforeQuitting"] #registered-cigarettes-list li:nth-child(3) i {
        color: rgb(217, 143, 111);
    }
    .page[data-name="beforeQuitting"] #registered-cigarettes-list li:nth-child(4) i {
        color: rgb(217, 162, 139);
    }
    .page[data-name="beforeQuitting"] #registered-cigarettes-list li:nth-child(5) i {
        color: rgb(217, 177, 160);
    }
    .page[data-name="beforeQuitting"] #registered-cigarettes-list .item-header {
        font-style: italic;
    }
</style>
