<template>

    <f7-page no-toolbar no-navbar no-swipeback name="registery">

        <div class="bg">
            
            <div class="logo">
                <img src="../assets/img/logo.png">
            </div>
            
            <div class="logo-text" v-html="$t('login.how_to')"></div>

            <f7-block class="no-margin-top">
                <f7-row>
                    <f7-col>
                        <f7-list form class="form-registery">
                            <f7-list-input
                                    :label="$t('login.login')"
                                    type="text"
                                    :placeholder="$t('login.your_login')"
                                    :value="login"
                                    @input="login = $event.target.value"
                                    ref="login"
                            ></f7-list-input>
                            <f7-list-input
                                    :label="$t('login.password')"
                                    type="password"
                                    :value="password"
                                    :placeholder="$t('login.your_password')"
                                    :class="{error: shortLogin}"
                                    @input="password = $event.target.value"
                                    ref="password"
                            ></f7-list-input>

                            <f7-list-input
                                    :label="$t('login.password_second')"
                                    type="password"
                                    :placeholder="$t('login.your_password_second')"
                                    :value="passwordSecond"
                                    @input="passwordSecond = $event.target.value"
                                    ref="passwordSecond"
                            ></f7-list-input>

                            <f7-list-input
                                    :label="$t('login.email')"
                                    type="email"
                                    :placeholder="$t('login.your_email')"
                                    :value="email"
                                    @input="email = $event.target.value"
                                    ref="email"
                            ></f7-list-input>

                            <f7-list-input
                                    :label="$t('login.email_second')"
                                    type="email"
                                    :placeholder="$t('login.your_email_second')"
                                    class="border-last"
                                    :value="emailSecond"
                                    @input="emailSecond = $event.target.value"
                                    ref="emailSecond"
                            ></f7-list-input>

                        </f7-list>
                    </f7-col>
                </f7-row>

                <f7-row>
                    <f7-col width="100">
                        <button @click="registery" class="button login-button">{{$t('registery.registery')}}</button>
                    </f7-col>
                    <f7-col width="100">
                        <button @click="$f7router.navigate('/login/')" class="button register-button">{{$t('registery.cancel')}}</button>
                    </f7-col>
                </f7-row>

            </f7-block>

        </div>

    </f7-page>

</template>

<script>
    import User from '../utils/User.js';
    import Application from '../utils/Application.js';
    
    export default {
        name: "registery",
        data(){
            return{
                password: '',
                passwordSecond: '',
                email: '',
                emailSecond: '',
                login: '',
                shortLogin: false,
                formValid: false,
                formError: {login:true,password:true,passwordSecond:true,email:true,emailSecond:true},
                reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
            }
        },
        watch:{

            login(current,prev){
                this.validate(this.$refs.login.$el,current,this.$t('registery.login_length'),4,'login')
            },
            password(current,prev) {
                this.validate(this.$refs.password.$el,current,this.$t('registery.password_length'),8,'password')
            },
            passwordSecond(current,prev){
                this.validateSecond(this.$refs.passwordSecond.$el,current,this.$t('registery.other_password'),this.password,'passwordSecond');
            },
            email(current){
                this.validateEmail(this.$refs.email.$el,current,this.$t('registery.email_valid'),this.reg,'email')
            },
            emailSecond(current){
                this.validateSecond(this.$refs.emailSecond.$el,current,this.$t('registery.other_email'),this.email,'emailSecond');

            }
        },
        methods:{

            displayErrorDialog(msg, title) {
                const app = this.$f7;
                app.dialog.alert(msg, title);
                setTimeout(function () {
                    app.dialog.close();
                }, 2000);
            },

            registery(){
                if (!Application.checkConnection()) {
                    this.displayErrorDialog(window.vue.$t("registery.failure_nointernet_msg"), window.vue.$t("registery.failure_nointernet_title"));
                    return;
                }
                
                let arr = Object.values(this.formError);
                if(!arr.includes(true)){
                    this.$f7.preloader.show();
                    User.register(this.login, this.password, this.email, this.displayDialog);
                }
                else{
                    this.$f7.dialog.alert(this.$t('registery.error_form_valid'),this.$t('registery.error_form_title'));
                    self.$f7.preloader.hide();
                }
            },

            validate(element,current,lang,length,field){
                let parentElem = element;
                let input = parentElem.querySelector('input');
                let spanElement = parentElem.querySelector('span');

                if(current.length < length) {
                    if (spanElement == null) {
                        let span = document.createElement('span');
                        span.innerText = lang;//this.$t('registery.login_length');
                        input.parentNode.append(span);
                        this.formError[field] = true;
                    }
                    else if(current == ''){
                        let spanElem = parentElem.querySelector('span');
                        if(spanElem) spanElem.remove();
                    }
                }
                else{
                    let spanElem = parentElem.querySelector('span');
                    if(spanElem) spanElem.remove();
                    this.formError[field] = false;
                }

            },

            validateSecond(element,current,lang,word,field){
                let parentElem = element
                let input = parentElem.querySelector('input');
                let spanElement = parentElem.querySelector('span');

                if(current != word) {
                    if (spanElement == null) {
                        let span = document.createElement('span');
                        span.innerText = lang;
                        input.parentNode.append(span);
                        this.formError[field] = true;
                    }
                    else if(current == ''){
                        let spanElem = parentElem.querySelector('span');
                        if(spanElem) spanElem.remove();
                    }
                }

                else{
                    let spanElem = parentElem.querySelector('span');
                    if(spanElem) spanElem.remove();
                    this.formError[field] = false;
                }
            },
            validateEmail(element,current,lang,email,field){
                let parentElem = element
                let input = parentElem.querySelector('input');
                let spanElement = parentElem.querySelector('span');

                if(!email.test(current)) {
                    if (spanElement == null) {
                        let span = document.createElement('span');
                        span.innerText = lang;
                        input.parentNode.append(span);
                        this.formError[field] = true;
                    }
                    else if(current == ''){
                        let spanElem = parentElem.querySelector('span');
                        if(spanElem) spanElem.remove();
                    }
                }
                else{
                    let spanElem = parentElem.querySelector('span');
                    if(spanElem) spanElem.remove();
                    this.formError[field] = false;
                }
            }
        }

    }
</script>

<style>
.page[data-name="registery"] .page-content {
    background-image: url("../assets/img/bg.png");
    padding-bottom: 0px !important;
    width: 100vw;
    height: 100vh;
    background-position: center;
    background-size: cover;
    font-family: TitilliuRegular;
}
</style>

<style lang="scss" scoped>
    @media only screen and (max-width: 600px) {
        button.button {
            font-size: 5vw;
        }
    }
    @media only screen and (min-width: 601px) {
        button.button {
            font-size: 4vw;
            height: 5vh !important;
        }
    }

    button.button {
        border: 2px solid #fff;
        border-radius: 25px;
        height: 45px;
        width: 100%;
        text-transform: none !important;
        font-weight: 100 !important;
        margin-bottom: 10px;
    }
    button.login-button {
        border: 2px solid #fff;
        background: #fff;
        color: rgb(216, 39, 0);
    }
    button.register-button {
        color: #fff;
    }
    button.info-button {
        border: 2px solid #000;
        color: #000;
    }

    .form-registery ul{
        background: transparent !important;
    }

    .logo{
        display: flex;
        justify-content: center;
        height: 10vh;
        width: 100%;
        padding-top: 15%;

    }

    .logo img{
        object-fit: contain;
        width: 20vw;
    }


    .logo-text /deep/ span:nth-child(2){
        color: white;
    }

    .logo-text{
        text-align: center;
        font-size: 7vw;
        text-transform: uppercase;
        padding-top: 15px;
    }

</style>

<style>

    .error input{
        color: #920d00 !important;
    }

    .error .item-inner:after{
        color: red;
        background-color: red !important;
    }

    .page[data-name="registery"] .page-content{
        padding: 0;
        background-image: url("../assets/img/bg.png");
        width: 100vw;
        height: 100%;
        background-position: center;
        background-size: cover;
    }

    .no-margin-top{
        margin-top: 0px;
    }

    .page[data-name="registery"] .list ul{
        background: transparent !important;
    }

    .page[data-name="registery"] .list ul:first-child:before{
        width: 0px;
    }

    .page[data-name="registery"] .item-input-wrap input::placeholder{
        color: white;
    }

    .page[data-name="registery"] .simple-list li:after, .links-list a:after, .list .item-inner:after{
        background-color: white;
        height: 5px;
    }

    .md .item-input:not(.item-input-outline) .item-input-wrap:after, .md .input:not(.input-outline):after{
        background-color: white !important;
    }

    /*.simple-list li:after, .links-list a:after, .list .item-inner:after*/

    .page[data-name="registery"] .list ul:after{
       width: 0;
    }

    .page[data-name="registery"] .input-focused:after{
        color: white;
        background-color: white;
    }

    .page[data-name="registery"] .item-content span{
        font-size: 3vw;
        color: #d6cdcc;
    }

    .page[data-name="registery"] .input-with-value{
        color: white;
    }

    .page[data-name="registery"] .border-last .item-inner:before{
        content: '';
        position: absolute;
        background-color: white;
        display: block;
        z-index: 15;
        top: auto;
        right: auto;
        bottom: 0;
        left: 0;
        height: 5px;
        width: 100%;
        transform-origin: 50% 100%;
        transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
    }

</style>