import HomePage from '../pages/home.vue';
import LoginPage from '../pages/login.vue';
import RegisteryPage from '../pages/registery.vue'
import NotFoundPage from '../pages/404.vue';
import ResetPasswordPage from '../pages/reset-password.vue';

import DiagnosisPage from '../pages/diagnosis';
import beforeQuiting from '../pages/beforeQuitting/beforeQuitting';
import quittingPage from '../pages/quitting/quitting';
import abstinencePage from '../pages/abstinence/abstinence';
import successPage from '../pages/success';
import InfoPage from '../pages/info';
import RatingPage from '../pages/rating';
import InfoLoggedOutPage from '../pages/info-loggedout';
import userPanel from "../pages/userPanel";

var routes = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/login/',
    component: LoginPage,
  },
  {
    path: '/diagnosis/',
    component: DiagnosisPage,
  },
  {
    path: '/registery/',
    component: RegisteryPage,
  },
  {
    path: '/resetPassword/',
    component: ResetPasswordPage,
  },
  {
    path: '/beforeQuitting/',
    component: beforeQuiting,
  },
  {
    path: '/userPanel/',
    component: userPanel,
  },
  {
    path: '/quitting/',
    component: quittingPage,
  },
  {
    path: '/abstinence/',
    component: abstinencePage,
  },
  {
    path: '/success/',
    component: successPage,
  },
  {
    path: '/info/',
    component: InfoPage,
  },
  {
    path: '/rating/',
    component: RatingPage,
  },
  {
    path: '/info-loggedout/',
    component: InfoLoggedOutPage,
  },

  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
