<template>
    <div class="info-container" v-html="content">
    </div>
</template>

<script>
    import $ from 'dom7';
    
    export default {
        name: "info-component",
        props:['content'],
        data() {
            return {
                
            }
        },
        methods: {
            getIcon(tip) {
                if (tip.icon) {
                    return tip.icon;
                }
                
                return tip.none ? 'lightbulb_slash' : 'lightbulb';
            }
        },
        mounted() {
            this.$f7ready(f7 => {
                $('.call-phone-no').on('click', function(e) {
                    window.plugins.CallNumber.callNumber(()=>{console.log('call')}, (e)=>{console.log(e)}, String(e.target.title), false);
                });
            })
        }
    }
</script>

<style lang="scss" scoped>
    @media only screen and (min-width: 601px) {
        .info-content {
            font-size: 3vw;
        }
    }

    .info-content {
        margin-bottom: 20px;
    }
</style>