export default {
    quitting12WeekQuestion:[
        {
            id: 0,
            name: 'Czy udało Ci się utrzymać abstynencję?',
            type: 'select',
            required: true,
            dependsOn: null,
            answer: [
                {
                    name: 'Tak.',
                    value: '1',
                },
                {
                    name: 'Nie.',
                    value: '2',
                },
            ]
        },
    ],
}