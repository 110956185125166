// Import Vue
import Vue from 'vue';
// Import Framework7
import Framework7 from 'framework7/framework7-lite.esm.bundle.js';
// Import Framework7-Vue Plugin
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle.js';
// Import Framework7 Styles
import 'framework7/css/framework7.bundle.css';
// Import Icons and App Custom Styles
import '../css/icons.css';
import '../css/app.scss';
// Import App Component
import App from '../components/app.vue';
import i18n from '../i18n';
import StoreCache from '../utils/storeCache.js';
import Application from '../utils/Application.js';
import AppSettings from '../utils/AppSettings.js';
import User from '../utils/User.js';
import * as moment from 'moment';

// Init Framework7-Vue Plugin
Framework7.use(Framework7Vue);

const mixin = {
  methods:{

    addDatePadding(n) {
      return String(n).padStart(2, '0');
    },

    extractDate(date) {
      return date.toDate().toLocaleDateString('pl-PL');
    },

    formatDate(date, withHours = false) {
      let result = [date.getDate(), date.getMonth() + 1, date.getFullYear()].map(this.addDatePadding).join('.');
      if (withHours)
        result += ' ' + [date.getHours(), date.getMinutes()].map(this.addDatePadding).join(':');
      return result;
    },

    getDate() {
      const date = moment();
      const dateObj = new Date();
      const localeTime = dateObj.toLocaleTimeString('pl-PL');
      const localeDate = dateObj.toLocaleDateString('pl-PL');
      const current = moment(localeDate + ' ' + localeTime, "DD.MM.YYYY HH:mm:ss");

      if (current.isValid()) {
        return current;
      }
      console.error("Invalid date.")
      return date;
    },

    resetCigaretteZero() {
      let time = this.getDate();

      this.$f7.data.stats.saveCigarette({
          time: time.toISOString(),
      });
      this.$f7.data.stats.setTimerZeroCigarette(time);
      AppSettings.setNotifications();
    },

    // Funkcja sprawdzająca uprawnienia przy klikaniu na taby.
    verifyPermission(permission) {
      const hasPermission = User.checkPermission(permission);
      let permissionIndex = this.$f7.data.permissions.indexOf(permission);

      if (!hasPermission) {
          this.$f7.dialog.alert(this.$t("permissions.no_permission_txt"), this.$t("permissions.no_permission_title"));
          setTimeout(() => {
              this.$f7.dialog.close();
          }, 2000);

          let safetyCounter = 0;

          while (!User.checkPermission(this.$f7.data.permissions[permissionIndex])) {
            if (permissionIndex < 0) {
              break;
            }
            permissionIndex--;
            safetyCounter++;
            if (safetyCounter > 10) break;
          }

          if (permissionIndex < 0) {
            this.$f7.tab.show('#view-diagnosis');
          }
          else {
            this.$f7.tab.show('#view-' + this.$f7.data.permissions[permissionIndex]);
          }

          return false;
      }

      return true;
    },
  }
}

Vue.mixin(mixin);

// Init App
window.vue = new Vue({
  el: '#app',
  render: (h) => h(App),
  i18n,

  // Register App Component
  components: {
    app: App,
  },
});
