export default {
    quittingQuestionsTips: {
        block1: {
            title: 'Panowanie nad chęcią palenia',
            content: [
                'Gratulujemy! Zastanów się co zrobiłeś, że udało Ci się nie zapalić? Zapisz to sobie i rób tak częściej.',

                'Świetnie! To świadczy o tym, że coraz lepiej potrafisz kontrolować potrzebę palenia!',

                'Wspaniale, że udało Ci się nie zapalić, pomimo, że miałeś/łaś na to ochotę!',

                'Gratulujemy! Zastanów się jakie twoje cechy pozwoliły ci przetrwać chęć palenia? Możesz je wykorzystać przy innych sytuacjach.',

                'Zastanów się z jakiego powodu dzisiaj nie udało Ci się zapanować nad chęcią palenia. Co stanowiło największy problem? Jak możesz poradzić sobie jutro? Zaplanuj kilka pomysłów. Jeśli chcesz zadzwoń do Telefonicznej Poradni Pomocy Palącym (<span class="call-phone-no" title="801108108"><i class="f7-icons phone-call-icon">phone</i>801 108 108</span>) i omów ze specjalistami jak można kontrolować potrzebę palenia.',
            ]
        },

        block2: {
            title: 'Dzisiejszy sukces',
            content: [
                'Wspaniale! Ważne jest żebyś dostrzegał/ła swoje sukcesy. To dowód na to, że coraz lepiej kontrolujesz swoje palenie! Codziennie analizuj te sytuacje i zastanów się na ile przybliżają Cię do Twojego celu. Czy czujesz się dzięki temu bardziej przygotowany/na?',

                'Nie zniechęcaj się. Jeśli nie dostrzegasz dzisiaj żadnego sukcesu, to może mieć dwojakie znaczenie. Jesteś zbyt krytyczny/na wobec siebie i stawiasz sobie zbyt wysokie wymagania. Zastanów się raz jeszcze czy była dzisiaj sytuacja, w której coś, nawet niewielkiego Ci się udało. Może też być tak, że dzisiaj miałeś/łaś słabszy dzień. W takiej sytuacji nie zrażaj się i jutro rozpocznij dzień z nowymi postanowieniami i nową energią do wprowadzania zmian. Powodzenia!',
            ]
        },
    }
}