<template>
    <f7-page no-toolbar no-navbar no-swipeback login-screen name="login">
            <div id="logo">
            <img src="../assets/img/logo.png" id="logo-img" />
        </div>

        <div id="slogan">
            <span v-html="templateHowTo"></span>
        </div>

        <div id="form">
            <f7-block>
                <label>
                    {{$t("login.login")}}
                </label>
                <input id="login" type="text" :placeholder="$t('login.your_login')" :value="username" @input="username = $event.target.value" />
                <label>
                    {{$t("login.password")}}
                </label>
                <input id="password" type="password" :placeholder="$t('login.your_password')" :value="password" @input="password = $event.target.value" />
            </f7-block>
        </div>

        <div id="form-forgot-passwd">
            <f7-block id="forgot-container">
                <a href="/resetPassword/">
                    {{$t("login.forgot")}}
                </a>
            </f7-block>
        </div>

        <div id="login-btn">
            <f7-list class="buttons">
                <button class="button login-button" @click="signIn">{{$t("login.log_in")}}</button>
            </f7-list>
        </div>

        <div id="register-btn">
            <f7-list class="buttons">
                <button class="button register-button" @click="$f7router.navigate('/registery/')">{{$t("login.register")}}</button>
            </f7-list>
        </div>

        <div id="info-btn">
            <f7-list class="buttons">
                <button class="button info-button" @click="$f7router.navigate('/info-loggedout/')">{{$t("login.info")}}</button>
            </f7-list>
        </div>

        <div id="footer">
            <img src="../assets/img/stopka.png" id="footer-img" />
        </div>
    </f7-page>
</template>

<script>
    import User from '../utils/User.js';
    import StoreCache from '../utils/storeCache.js';

    export default {
        name: "login",
        data() {
            return {
                username: '',
                password: '',
                templateHowTo: this.$t("login.how_to"),
            };
        },
        mounted: function() {
            // console.log(this.$f7.data)
        },
        methods: {

            displayDialog(msg, title) {
                const app = this.$f7;
                app.dialog.alert(msg, title);
                setTimeout(function () {
                    app.dialog.close();
                }, 2000);
            },

            resendActivationLink(dialog) {
                const self  = this;
                const app   = self.$f7;
                
                dialog.close();
                app.request({
                    method: "GET",
                    url: `${this.$f7.data.api.url}/resendActivationLink/${self.username}`,
                    success: function (response) {
                        let parsedResponse = null;
                        try {
                            parsedResponse = JSON.parse(response);
                        }
                        catch(e) {
                            self.displayDialog(self.$t("login.failure_unknown"), self.$t("login.activation_error"));
                            console.log(e);
                            return;
                        }

                        if (parsedResponse && parsedResponse.success) {
                            self.displayDialog(self.$t("login.activation_sent_msg"), self.$t("login.activation_sent"));
                        }
                        else {
                            self.displayDialog(parsedResponse.message, self.$t("login.activation_error"));
                        }
                    },
                    error: function (error) {
                        self.displayDialog(self.$t("login.failure_unknown"), self.$t("login.activation_error"));
                        console.error(error);
                    }
                });
            },

            signIn() {
                const app           = this.$f7;
                const router        = this.$f7router;
                const storeCache    = new StoreCache();

                if (storeCache.get("_loginData")) {
                    storeCache.remove("_loginData");
                    this.$f7.data.isLoggedIn = false;
                    this.$f7.data.user = null;
                }

                User.login(this.username, this.password, this.displayDialog, this.resendActivationLink);
            },
        },
        
    };
</script>

<style>
.page[data-name="login"] .page-content {
    background-image: url("../assets/img/bg.png");
    padding-bottom: 0px !important;
    width: 100vw;
    height: 100vh;
    background-position: center;
    background-size: cover;
    font-family: TitilliuRegular;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 4fr 50px 1fr 30px 1fr 1fr 3fr 3fr;
    grid-template-areas:
        "logo"
        "slogan"
        "form"
        "form-forgot-passwd"
        "login-btn"
        "register-btn"
        "info-btn"
        "footer";
}
</style>

<style lang="scss" scoped>
@media only screen and (max-width: 600px) {
    #form-forgot-passwd {
        font-size: 3.5vw;
    }
    button.button {
        font-size: 5vw;
    }
    div#slogan /deep/ span {
        font-size: 7vw;
    }
    #form label {
        font-size: 3.5vw;
    }
    #form input {
        font-size: 5vw;
    }
    #form input::placeholder {
        font-size: 5vw;
    }
    #logo-img {
        margin-bottom: 1vh;
    }
}
@media only screen and (min-width: 601px) {
    #form-forgot-passwd {
        font-size: 2.5vw;
    }
    button.button {
        font-size: 4vw;
        height: 5vh !important;
    }
    div#slogan /deep/ span {
        font-size: 7vw;
    }
    #form label {
        font-size: 2.5vw;
    }
    #form input {
        font-size: 3.5vw;
    }
    #form input::placeholder {
        font-size: 3.5vw;
    }
    #form input {
        height: 5vh;
    }
    #logo-img {
        margin-bottom: 3vh;
    }
}

a {
    color: rgb(0, 33, 38);
    text-decoration: underline dotted;
    font-style: italic;
}

#logo {
    grid-area: logo;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
#logo-img {
    width: 23vw;
    height: auto;
    background-image: url("../assets/img/logo.png");
    background-size: contain;
    background-repeat: no-repeat;
}

div#slogan /deep/ span:nth-child(2n) {
    color: #fff;
}

div#slogan /deep/ span:nth-child(2n - 1) {
    color: rgb(0, 33, 38);
}

div#slogan /deep/ span {
    text-transform: uppercase;
}

#slogan {
    grid-area: slogan;
    display: flex;
    justify-content: center;
    align-items: center;
}
#form {
    grid-area: form;
}
#form label {
    display: block;
    font-style: italic;
    padding: 7px 0px;
}
#form input {
    box-sizing: border-box !important;
    border-bottom: 1px solid #fff !important;
    color: #fff !important;
    width: 100%;
    padding: 5px;
}
#form input::placeholder {
    font-style: italic;
    color: #fff !important;
}
#form input:focus {
    border-bottom: 2px solid #fff !important;
}

#form-forgot-passwd {
    grid-area: form-forgot-passwd;
    margin-left: 20px;
}
#forgot-container {
    margin-top: 0;
}
#login-btn {
    grid-area: login-btn;
    display: flex;
    justify-content: center;
}
#register-btn {
    grid-area: register-btn;
    display: flex;
    justify-content: center;
}
#info-btn {
    grid-area: info-btn;
    display: flex;
    justify-content: center;
}
#footer {
    grid-area: footer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
}

div.buttons {
    padding: 0;
    margin: 5px;
}
.buttons.list {
    width: 90vw;
}
button.button {
    border: 2px solid #fff;
    border-radius: 25px;
    height: 45px;
    width: 100%;
    text-transform: none !important;
    font-weight: 100 !important;
}
button.login-button {
    border: 2px solid #fff;
    background: #fff;
    color: rgb(216, 39, 0);
}
button.register-button {
    color: #fff;
}
button.info-button {
    border: 2px solid #000;
    color: #000;
}

#footer-img {
    width: 90vw;
    height: auto;
}
</style>
