<template>
    <f7-block class="quitting-calculator">

        <f7-row>
            <f7-col>
                <div class="header-block">
                    <div>
                        <f7-icon material="attach_money"></f7-icon>
                    </div>
                    <div>
                        <h3>
                            {{$t('quitting_calculator.calculator')}}
                        </h3>
                    </div>
                </div>
            </f7-col>
        </f7-row>

        <div v-if="!savings">
            <f7-row>
                <f7-col class="form-quitting-calculator-container">
                    <f7-list form class="form-quitting-calculator">
                        <f7-list-input
                                :label="$t('quitting_calculator.last_cig_full')"
                                type="datetime-local"
                                :value="inputs.last_cig"
                                :error-message="$t('quitting_calculator.error_invalid_date')"
                                :error-message-force="forceError.last_cig"
                                @input="inputs.last_cig = $event.target.value"
                                ref="last_cig"
                        ></f7-list-input>
                        <f7-list-input
                                :label="$t('quitting_calculator.daily_cigs')"
                                type="text"
                                :placeholder="$t('quitting_calculator.daily_cigs')"
                                :value="inputs.daily_cigs"
                                validate
                                :error-message="$t('quitting_calculator.error_invalid_value')"
                                :error-message-force="forceError.daily_cigs"
                                pattern="[0-9]*"
                                @input="inputs.daily_cigs = $event.target.value"
                                ref="daily_cigs"
                        ></f7-list-input>
                        <f7-list-input
                                :label="$t('quitting_calculator.cigs_price')"
                                type="text"
                                :placeholder="$t('quitting_calculator.cigs_price')"
                                :value="inputs.cigs_price"
                                validate
                                :error-message="$t('quitting_calculator.error_invalid_value')"
                                :error-message-force="forceError.cigs_price"
                                pattern="[0-9]*"
                                @input="inputs.cigs_price = $event.target.value"
                                ref="cigs_price"
                        ></f7-list-input>
                        <f7-list-input
                                :label="$t('quitting_calculator.cigs_quantity')"
                                type="text"
                                :placeholder="$t('quitting_calculator.cigs_quantity')"
                                :value="inputs.cigs_quantity"
                                validate
                                :error-message="$t('quitting_calculator.error_invalid_value')"
                                :error-message-force="forceError.cigs_quantity"
                                pattern="[0-9]*"
                                @input="inputs.cigs_quantity = $event.target.value"
                                ref="cigs_quantity"
                        ></f7-list-input>
                    </f7-list>
                </f7-col>
            </f7-row>

            <f7-row>
                <f7-col width="100">
                    <button @click.prevent="calculate" class="btn btn-calculate">{{$t('quitting_calculator.calculate')}}</button>
                </f7-col>
                <f7-col width="100">
                    <button @click.prevent="reset" class="btn btn-reset">{{$t('quitting_calculator.reset')}}</button>
                </f7-col>
            </f7-row>
        </div>

        <div v-else>
            <f7-row>
                <f7-col class="form-quitting-calculator-container">
                    <div class="calculation-block" id="calculation-block-days">
                        <div class="calculation-block-inside">
                            <span class="calculation-block-txt" v-html="$t('quitting_calculator.your_savings')">
                            </span>
                            <div class="calculation-block-counter">
                                {{savings}} {{$t('quitting_calculator.pln')}}
                            </div>
                        </div>
                    </div>
                </f7-col>
            </f7-row>

            <f7-row>
                <f7-col width="100">
                    <button @click.prevent="calculateAgain" class="btn btn-calculate">{{$t('quitting_calculator.calculate_again')}}</button>
                </f7-col>
            </f7-row>
        </div>


    </f7-block>
</template>

<script>
    export default {
        name: "quitting-calculator",
        data() {
            return {
                inputs: {
                    last_cig: '',
                    daily_cigs: '',
                    cigs_price: '',
                    cigs_quantity: '',
                },
                forceError: {
                    last_cig: false,
                    daily_cigs: false,
                    cigs_price: false,
                    cigs_quantity: false,
                },
                savings: null,
            }
        },
        watch: {
            'inputs.last_cig': function(current, prev) {
                const self = this;
                const currentDate = new Date(current);
                if (currentDate > this.getDate() || current == '') {
                    self.forceError.last_cig = true;
                    self.$refs.last_cig.$data.isInvalid = true;
                } else {
                     self.forceError.last_cig = false;
                    self.$refs.last_cig.$data.isInvalid = false;
                }
            },
            'inputs.daily_cigs': function(current, prev) {
                const self = this;
                if (current == '') {
                    // self.forceError.daily_cigs = true;
                    self.$refs.daily_cigs.$data.isInvalid = true;
                } else {
                    // self.forceError.daily_cigs = false;
                    self.$refs.daily_cigs.$data.isInvalid = false;
                }
            },
            'inputs.cigs_price': function(current, prev) {
                const self = this;
                if (current == '') {
                    // self.forceError.cigs_price = true;
                    self.$refs.cigs_price.$data.isInvalid = true;
                } else {
                    // self.forceError.cigs_price = false;
                    self.$refs.cigs_price.$data.isInvalid = false;
                }
            },
            'inputs.cigs_quantity': function(current, prev) {
                const self = this;
                if (current == '') {
                    // self.forceError.cigs_quantity = true;
                    self.$refs.cigs_quantity.$data.isInvalid = true;
                } else {
                    // self.forceError.cigs_quantity = false;
                    self.$refs.cigs_quantity.$data.isInvalid = false;
                }
            },
        },
        methods: {
            calculate() {
                let validation = this.validate();
                if (validation.invalid) {
                    const app = this.$f7;
                    let msg;

                    if (validation.areInvalid && validation.areEmpty) {
                        msg = this.$t("quitting_calculator.error_fields_invalid_and_empty");
                    }
                    else if (validation.areInvalid) {
                        msg = this.$t("quitting_calculator.error_fields_invalid");
                    }
                    else {
                        msg = this.$t("quitting_calculator.error_fields_empty");
                    }

                    app.dialog.alert(msg, this.$t("quitting_calculator.error_cant_calc"));
                    setTimeout(function () {
                        app.dialog.close();
                    }, 3000);
                    return;
                }

                const cigPrice = this.inputs.cigs_price / this.inputs.cigs_quantity;
                const lastCigaretteDate = new Date(this.inputs.last_cig);
                const lastCigaretteSeconds = lastCigaretteDate.getTime() / 1000;
                const secondsSinceLastCigarette = (this.getDate().unix()) - lastCigaretteSeconds;
                const timeSinceLastCigarette = {
                    days: Math.floor(secondsSinceLastCigarette / (3600*24)),
                    hours: Math.floor(secondsSinceLastCigarette % (3600*24) / 3600),
                    minutes: Math.floor(secondsSinceLastCigarette % 3600 / 60),
                    seconds: Math.floor(secondsSinceLastCigarette % 60),
                };

                const dailySpending = cigPrice * this.inputs.daily_cigs;
                const dailySavings = timeSinceLastCigarette.days * dailySpending;
                const dayFraction = (timeSinceLastCigarette.hours + (timeSinceLastCigarette.minutes / 60) + (timeSinceLastCigarette.seconds / 3600)) / 24;
                const todaysSavings = dayFraction * dailySpending;
                const totalSavings = (dailySavings + todaysSavings).toFixed(2);
                console.table({
                    dailySpending,
                    dailySavings,
                    dayFraction,
                    todaysSavings,
                    totalSavings
                });
                this.savings = totalSavings;

                // Zapisanie wartości pól do local storage.
                this.$f7.data.stats.saveCalculatorInputs(this.inputs);
            },

            calculateAgain() {
                this.savings = null;
            },

            reset() {
                this.inputs.last_cig = '';
                this.inputs.daily_cigs = '';
                this.inputs.cigs_price = '';
                this.inputs.cigs_quantity = '';
            },

            validate() {
                let result = {
                    invalid: false,
                    areEmpty: false,
                    areInvalid: false,
                };

                for (let [key, ref] of Object.entries(this.$refs)) {
                    if (ref.state.inputInvalid || ref.$data.isInvalid || ref.value == "") {
                        // this.forceError[key] = true;
                        result.invalid = true;
                    }
                    if (ref.state.inputInvalid || ref.$data.isInvalid) {
                        result.areInvalid = true;
                    }
                    if (ref.value == "") {
                        result.areEmpty = true;
                    }
                }

                return result;
            }
        },
        mounted() {
            this.$f7ready(f7 => {
                // Pobieranie wartości pól z local storage.
                const calculatorInputs = f7.data.stats.getCalculatorInputs();
                if (calculatorInputs) {
                    this.inputs = calculatorInputs;
                }
            })
        }
    }
</script>

<style lang="scss" scoped>
    @media only screen and (max-width: 600px) {
        .content-container {
            padding: 23vh 3vw 3vw 3vw;
            min-height: 40vh;
        }
        .calculation-block-inside {
            padding: 5vw;
        }
        .calculation-block-txt {
            font-size: 4vw;
        }
        .calculation-block-counter {
            font-size: 13.5vw;
        }
    }
    @media only screen and (min-width: 601px) {
        .content-container {
            padding: 23vh 3vw 3vw 3vw;
            min-height: 40vh;
        }
        .content-container {
            padding: 10vh 3vw 3vw 3vw;
            min-height: 55vh;
        }
        #txt-content {
            font-size: 3vw;
        }
        .calculation-block {
            width: 90vw;
            min-height: 22vh;
        }
        .calculation-block-inside {
            padding: 2.5vw;
        }
        .calculation-block-txt {
            font-size: 4vw;
        }
        .calculation-block-counter {
            font-size: 9vw;
        }
    }

    .content-container {
        background-color: #efeff4;
    }

    .form-quitting-calculator-container {
        min-height: 50vh;
        display: flex;
        align-items: center;
    }

    .form-quitting-calculator {
        width: 100%;
    }

    .btn{
        background-color: white;
        border-radius: 25px;
        padding: 15px 0;
        font-family: TitilliuRegular;
        font-size: 4vw;
        box-shadow: none !important;
        border: 2px solid #fff;
    }

    .btn-calculate{
        color: #d44817;
    }

    .btn-reset{
        background-color: transparent;
        color: black;
        margin: 20px 0;
    }

    .form-quitting-calculator ul{
        background: transparent !important;
    }

    .calculation-block {
        margin: 5vw;
        border-radius: 15px;
        background-color: rgb(34, 35, 36);
        box-shadow: 0 0 7px 0px #000;
    }
    .calculation-block-inside > span {
        color: #fff;
        font-weight: bold;
    }
    .calculation-block-counter {
        font-weight: bold;
        color: #ee562b;
    }
</style>

<style>

    @media only screen and (min-width: 601px) {
        .block.quitting-calculator .item-title {
            font-size: 2.25vw !important;
        }
        .block.quitting-calculator .item-input-wrap input::placeholder{
            font-size: 3vw !important;
        }
        .block.quitting-calculator .input-with-value{
            font-size: 3vw !important;
        }
        .block.quitting-calculator input[type="datetime-local"] {
            font-size: 3vw !important;
        }
        .block.quitting-calculator input[type="datetime-local"].input-with-value {
            font-size: 3vw !important;
        }
        .block.quitting-calculator .item-input-error-message {
            font-size: 2.25vw !important;
        }
    }

    .block.quitting-calculator .list ul{
        background: transparent !important;
    }

    .block.quitting-calculator .list ul:first-child:before{
        width: 0px;
    }

    .block.quitting-calculator .item-input-wrap input::placeholder{
        color: rgba(256,256,256,0.65);
    }

    .block.quitting-calculator .simple-list li:after, .links-list a:after, .list .item-inner:after{
        background-color: white;
        height: 5px;
    }

    .md .item-input:not(.item-input-outline) .item-input-wrap:after, .md .input:not(.input-outline):after{
        background-color: white !important;
    }

    /*.simple-list li:after, .links-list a:after, .list .item-inner:after*/

    .block.quitting-calculator .list ul:after{
       width: 0;
    }

    .block.quitting-calculator .input-focused:after{
        color: white;
        background-color: white;
    }

    .block.quitting-calculator .item-content span{
        font-size: 3vw;
        color: #d6cdcc;
    }

    .block.quitting-calculator .input-with-value{
        color: white;
    }

    .block.quitting-calculator .border-last .item-inner:before{
        content: '';
        position: absolute;
        background-color: white;
        display: block;
        z-index: 15;
        top: auto;
        right: auto;
        bottom: 0;
        left: 0;
        height: 5px;
        width: 100%;
        transform-origin: 50% 100%;
        transform: scaleY(calc(1 / var(--f7-device-pixel-ratio)));
    }

    .block.quitting-calculator .item-input-error-message{
        color: #fff;
        text-decoration: underline;
    }
    .block.quitting-calculator .item-title{
        color: #000;
    }
    .block.quitting-calculator input[type="datetime-local"].input-with-value {
        color: #fff;
    }
    .block.quitting-calculator input[type="datetime-local"] {
        color: rgba(256,256,256,0.65);
    }
    .block.quitting-calculator .item-input-invalid input {
        text-decoration: line-through;
    }

    .ios .item-input-invalid .item-inner:after {
        background: #fff !important;
    }

</style>
