<template>
    <f7-block id="prep-content-block">
        <div>
            <h2 id="pg_header">
                {{$t('beforeQuitting.step2_title')}}
            </h2>
        </div>
        <!-- Jeżeli pytania nie zostały zapisane -->
        <template v-if="!answersSaved">
            <questions-component 
                :name="'q_s2_beforeQuitting'" 
                :intro="$t('beforeQuitting.step2_intro')" 
                :questions="questions"
                :saveHandler="saveQuestions"
            ></questions-component>
        </template>

        <!-- Wskazówki - jeżeli pytania zostały zapisane -->
        <template v-else>
            <div id="tips-title" v-if="tips[0] && !tips[0].none">
                <f7-icon f7="lightbulb"></f7-icon> {{$t('beforeQuitting.tips')}}
            </div>
            <tips-component :tips="tips"></tips-component>
        </template>
        
        <div id="standard-btn" v-if="answersSaved">
            <button @click="nextStep()">
                <div>
                    <div>
                        {{$t('beforeQuitting.next_step')}}
                    </div>
                    <div>
                        <f7-icon f7="chevron_right"></f7-icon>
                    </div>
                </div>
            </button>
        </div>
    </f7-block>
</template>

<script>
    import StoreCache from '../../utils/storeCache.js';
    import QuestionsComponent from '../../components/questions';
    import TipsComponent from '../../components/tips';
    import _ from 'lodash';

    export default {
        name: "beforeQuitting-krok2",
        props: ['nextStep', 'questionsAnswered', 'setQuestionsAnswered', 'isModuleComplete'],
        data() {
            return {
                answersSaved: this.questionsAnswered,
                questions: null,
                answer_1: null,
                answer_2: null,
                answer_3: null,
                step2Tips: null,
            }
        },
        computed: {
            tips: function() {
                let tips = [];
                const cache = new StoreCache();
                const answers = cache.get("_q_s2_beforeQuitting");
                
                const positiveAnswers = Object.keys(answers)
                    .filter(answerKey => {
                        return answerKey.match(/^q_\d+$/g) && answers[answerKey] == "1";
                    });
                if (positiveAnswers.length == 0) {
                    let noTips = { 
                        title: this.$t('tips.none'),
                        none: true,
                        content: [],
                    };
                    tips.push(noTips);
                    return tips;
                }

                // Wskazówka 1.
                let topTip = { 
                    title: this.step2Tips.block1.title ,
                    content: [this.step2Tips.block1.content[0], this.step2Tips.block1.content[1]],
                };
                tips.push(topTip);
                
                // Wskazówka 2.
                if (positiveAnswers.includes('q_0')) {
                    const question = _.find(this.questions[0].answer, {'value': answers['q_0']});
                    let tip = {
                        title: '"' + question.name + '"',
                        content: [],
                    };

                    tip.content.push(this.step2Tips.block2.content[0]);
                    tips.push(tip);
                }

                // Wskazówka 3.
                if (positiveAnswers.includes('q_1')) {
                    const question = _.find(this.questions[1].answer, {'value': answers['q_1']});
                    let tip = {
                        title: '"' + question.name + '"',
                        content: [],
                    };

                    tip.content.push(this.step2Tips.block3.content[0]);
                    tip.content.push(this.step2Tips.block3.content[1]);
                    tip.content.push(this.step2Tips.block3.content[2]);
                    tip.content.push(this.step2Tips.block3.content[3]);
                    tips.push(tip);
                }

                // Wskazówka 4.
                if (positiveAnswers.includes('q_2')) {
                    const question = _.find(this.questions[2].answer, {'value': answers['q_2']});
                    let tip = {
                        title: '"' + question.name + '"',
                        content: [],
                    };

                    tip.content.push(this.step2Tips.block4.content[0]);
                    tips.push(tip);
                }

                return tips;
            }
        },
        components: {
            QuestionsComponent,
            TipsComponent,
        },
        methods: {
            saveQuestions() {
                this.answersSaved = true;
                this.setQuestionsAnswered('q_s2_beforeQuitting');
                this.isModuleComplete();
            }
        },
        created() {
            this.questions = this.$t('beforeQuittingStep2Questions').beforeQuittingStep2Questions;
            this.step2Tips = this.$t('beforeQuittingStep2Tips').beforeQuittingStep2Tips;
        },
    }
</script>

<style scoped>
    @media only screen and (max-width: 600px) {
        div#tips-title {
            font-size: 3.5vw;
        }
    }
    @media only screen and (min-width: 601px) {
        div#tips-title {
            font-size: 3vw;
        }
        h2#pg_header {
            font-size: 4.5vw;
        }
        div#prep-paragraphs {
            font-size: 3vw;
        }
    }

    h2#pg_header {
        margin-bottom: 2vh;
        text-transform: uppercase;
    }

    div#tips-title {
        color: #d53e06;
        font-weight: bold;
    }
    div#prep-paragraphs {
        flex-grow: 1;
    }
</style>

<style lang="scss">
</style>