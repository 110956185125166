export default {
    beforeQuittingStep4Content: {
        introGroup1: `
<p>Nie jesteś jeszcze silnie uzależniona/y od nikotyny, a palenie  tytoniu wynika głównie z potrzeb psychologicznych. Prawdopodobnie nie ma potrzeby stosowania leków wspomagających rzucanie palenie. Skoncentruj się na przygotowaniu psychicznym. Wszystko wskazuje na to, że potrafisz kontrolować chęć palenia. Przypomnij sobie wszystkie sytuacje, w których potrafisz odmówić sobie papierosa. Zastanów się jak wtedy to osiągasz? Jakie Twoje umiejętności i cechy charakteru wykorzystujesz? Wykorzystaj to podejmując próbę rzucenia palenia.</p>
        `,
        
        introGroup234: `
<p>Przygotowując się do rzucania palenia, warto zapoznać się z metodami farmakologicznymi które, mogą poprawić komfort rzucania palenia.</p>

<p>Pamiętaj jednak, że wsparcie lekowe nie zastąpi Twojego psychicznego nastawienia.</p>

<p>Na polskim rynku są dostępne specjalistyczne leki, opracowane z myślą o osobach, które chcą rzucić palenie. Polecamy stosowanie wyłącznie środków o udowodnionej w badaniach klinicznych skuteczności i bezpieczeństwie. Ważne jest aby nie stosować ich w sposób magiczny tzn. wezmę lek i zapomnę o paleniu. Nie istnieje lek, który działa w taki sposób. Lek ma pomóc w rzuceniu palenia poprzez m.in. łagodzenie objawów głodu nikotynowego.</p>

Wśród leków wspomagających rzucanie palenia znajdują się m.in.: 
<ul>
    <li>kuracje z nikotyną tzw. Nikotynowa Terapia Zastępcza,</li>
    <li>Desmoxan, Tabex lub Recigar,</li>
    <li>Champix,</li>
    <li>Zyban.</li>
</ul>

<h3>Kuracje z nikotyną tzw. Nikotynowa Terapia Zastępcza  </h3>

<p>Leczenie terapeutyczną nikotyną stosowane jest z powodzeniem od ponad 30 lat i służy wytłumieniu objawów zespołu abstynencji w momencie kiedy przestajesz palić papierosy. Przyjmując czystą nikotynę, czy to w postaci gumy czy plastra, uwalniasz się od szeregu groźnych substancji zawartych w dymie tytoniowym. </p>

Nikotynową Terapię Zastępczą możesz stosować, gdy jesteś fizjologicznie uzależniony od nikotyny. Możesz to sprawdzić odpowiadając sobie na pytania: 
<ul class="dashed-list">
    <li>czy budzisz się w nocy i palisz papierosy?</li>
    <li>czy w ciągu pół godziny po przebudzeniu zapalasz swojego pierwszego papierosa?</li>
    <li>czy palisz 20 papierosów lub więcej? </li>
</ul>

Jeżeli na dwa z tych pytań odpowiesz twierdząco to oznacza to, że jesteś biologicznie uzależniony od nikotyny. Proces leczenia nikotyną można podzielić na dwa etapy: 
<ol>
    <li>Etap: Stosowanie preparatów z nikotyną po to by zminimalizować objawy głodu nikotynowego. To czas na naukę codziennego życia bez papierosa w ręku. </li>
    <li>Etap: Stopniowe zmniejszanie dawek nikotyny, aż do całkowitego odstawienia </li>
</ol>

<p>Dla osób silnie uzależnionych zaleca się jednoczesne stosowanie różnych form NTZ (np. długo działające plastry z krótko działającymi gumami czy pastylkami), co zwiększa skuteczność terapii. <br />

Produkty lecznicze z nikotyną dostępne są w różnych formach:</p> 
<ul>
    <li>Gumy z nikotyną</li>
    <li>Plastry z nikotyną</li>
    <li>Pastylki do ssania</li>
    <li>Pastylki podjęzykowe</li>
    <li>Spray</li>
</ul>

<strong><a class="link external system-link" href="https://jakrzucicpalenie.pl/metody-rzucania-palenia/">Szczegółowy opis środków</a></strong>

<h4>Środki z Cytyzyną</h4>

<p>Obecnie na rynku dostępne są trzy preparaty, które zawierają Cytyzynę: Tabex, Desmoxan oraz Recigar. Środki te nie zawierają nikotyny tylko Cytyzynę, która imituje działanie nikotyny. Po przedostaniu się do organizmu cytyzyna „zastępuje” nikotynę w mózgu. Dzięki temu mniej odczuwana jest potrzeba palenia i słabsze są objawy odstawienia. Lek powoduje również, że palenie staje się mniej „przyjemne”. Lek zaczyna się stosować na kilka dni przed ostatecznym odstawieniem papierosów. Najlepiej żebyś pierwszy dzień bez papierosa wyznaczył ok. 5 dnia kuracji. Lek powinno się przyjmować 25 dni. </p>

<strong><a class="link external system-link" href="https://jakrzucicpalenie.pl/metody-rzucania-palenia/">Szczegółowy opis środków</a></strong>

<h4>Champix</h4>

<p>Champix to tabletki zawierające wareniklinę, która łączy się z receptorami nikotynowymi w mózgu pacjenta przez co przyczynia się do zmniejszenia głodu nikotynowego oraz nieprzyjemnych objawów odstawienia związanych z zaprzestaniem palenia oraz zmniejsza uczucie przyjemności związanej z paleniem. 

Champix jest lekiem stosowanym z przepisu lekarza tj. na receptę. 

Lek zaczyna się stosować na kilka - kilkanaście dni przed ostatecznym odstawieniem papierosów. Najlepiej żeby pierwszy dzień bez papierosa nastąpił na 1 do 2 tygodni od rozpoczęcia brania leku. Dawkowanie leku jest ściśle określone w ulotce, jeśli lekarz przepisujący lek nie zaleci inaczej. Powinno się przyjmować ten lek przez 12 tygodni. Po tym okresie jest możliwe przedłużenie kuracji o następne 12 miesięcy. </p>

<strong><a class="link external system-link" href="https://jakrzucicpalenie.pl/metody-rzucania-palenia/">Szczegółowy opis środków</a></strong>



<h4>Zyban</h4>

<p>Zyban jest lekiem z grupy antydepresantów działającym na tzw. układ nagrody w mózgu oraz układ kary podobnie jak to robi nikotyna. Łagodzi on objawy zespołu odstawienia nikotyny, a z drugiej strony zmniejsza chęć zapalenia papierosa. Badania kliniczne wykazały mniejszy przyrost wagi w przypadku stosowania Zybanu. 

Zyban jest lekiem stosowanym z przepisu lekarza tj. na receptę. 

Lek zaczyna się stosować na kilka - kilkanaście dni przed ostatecznym odstawieniem papierosów. Najlepiej żeby pierwszy dzień bez papierosa nastąpił w 2 tygodniu od rozpoczęcia brania leku. Leczenie Zybanem powinno trwać minimum 7 tygodni. Terapię można przedłużać. </p>

<strong><a class="link external system-link" href="https://jakrzucicpalenie.pl/metody-rzucania-palenia/">Szczegółowy opis środków</a></strong>
        `
    },
}