export default {
    questions:[
        {
            id: 0,
            name: 'Czy palisz codziennie?',
            type: 'select',
            required: true,
            dependsOn: null,
            answer: [
                {
                    name: 'Codziennie.',
                    value: '1',
                    score: 3,
                    link: null,
                    linkType: null
                },
                {
                    name: 'Kilka razy w tygodniu.',
                    value: '2',
                    score: 0,
                    link: 6,
                    linkType: 'questions'
                },
                {
                    name: 'Okazjonalnie raz na miesiąc.',
                    value: '3',
                    score: 0,
                    link: 6,
                    linkType: 'questions'
                }
            ],
         },
        {
            id: 1,
            name: 'Ile papierosów wypalasz dziennie?',
            dependsOn: 0,
            required: true,
            type: 'select',
            answer: [
                {
                    name: '1-5',
                    value: '1',
                    score: 1,
                    link: 5,
                    linkType: null,
                },
                {
                    name: '6-10',
                    value: '2',
                    score: 1,
                    link: null,
                    linkType: null,
                },
                {
                    name: '11-20',
                    value: '3',
                    score: 2,
                    link: null,
                    linkType: null,
                },
                {
                    name: 'Powyżej 20.',
                    value: '4',
                    score: 3,
                    link: null,
                    linkType: null,
                },
            ]
        },
        {
            id: 2,
            name: 'Ile czasu mija od obudzenia się rano do wypalenia pierwszego papierosa?',
            required: true,
            type: 'select',
            answer: [
                {
                    name: '0-5 min',
                    value: '1',
                    score: 3,
                    link: null,
                    linkType: null,
                },
                {
                    name: '06-30 min',
                    value: '2',
                    score: 2,
                    link: null,
                    linkType: null,
                },
                {
                    name: '31-60 min',
                    value: '3',
                    score: 1,
                    link: null,
                    linkType: null,
                },
                {
                    name: 'Więcej niż godzina.',
                    value: '4',
                    score: 0,
                    link: null,
                    linkType: null,
                },
            ]
        },
        {
            id: 3,
            name: 'Czy budzisz się w nocy i palisz papierosy?',
            required: true,
            type: 'select',
            answer: [
                {
                    name: 'Tak.',
                    value: '1',
                    score: 3,
                    link: null,
                    linkType: null,
                },
                {
                    name: 'Nie.',
                    value: '2',
                    score: 0,
                    link: null,
                    linkType: null,
                },

            ]
        },
        {
            id: 4,
            name: 'Z jakich powodów chcesz rzucić palenie?',
            required: true,
            type: 'multicheckbox',
            answer: [
                {
                    name: 'Lekarz zalecił mi rzucenie palenia.',
                    value: '1',
                    score: 0,
                    link: null,
                    linkType: null,
                },
                {
                    name: 'Chcę zadbać o zdrowie i uniknąć chorób.',
                    value: '2',
                    score: 0,
                    link: null,
                    linkType: null,
                },
                {
                    name: 'Chcę poprawić swój stan zdrowia.',
                    value: '3',
                    score: 0,
                    link: null,
                    linkType: null,
                },
                {
                    name: 'Chcę poprawić kondycję.',
                    value: '4',
                    score: 0,
                    link: null,
                    linkType: null,
                },
                {
                    name: 'Chcę oszczędzić pieniądze.',
                    value: '5',
                    score: 0,
                    link: null,
                    linkType: null,
                }, {
                    name: 'Chcę zadbać o swój wygląd i ładnie pachnieć.',
                    value: '6',
                    score: 0,
                    link: null,
                    linkType: null,
                }, {
                    name: 'Chcę starać się o dziecko.',
                    value: '7',
                    score: 0,
                    link: null,
                    linkType: null,
                }, {
                    name: 'Jestem w ciąży.',
                    value: '8',
                    score: 0,
                    link: null,
                    linkType: null,
                }, {
                    name: 'Chcę chronić swoje dzieci/bliskich przed biernym paleniem i być dobrym przykładem.',
                    value: '9',
                    score: 0,
                    link: null,
                    linkType: null,
                }, {
                    name: 'Chcę czuć się wolny/a.',
                    value: '10',
                    score: 0,
                    link: null,
                    linkType: null,
                }, {
                    name: 'Chcę rzucić dla kogoś bliskiego.',
                    value: '11',
                    score: 0,
                    link: null,
                    linkType: null,
                },
            ]
        },
        {
            id: 5,
            name: 'Czy wprowadziłeś/łaś jakieś zmiany?',
            required: true,
            type: 'multicheckbox',
            answer: [
                {
                    name: 'Podejmowałem/łam próby.',
                    value: '1',
                    score: 0,
                    link: null,
                    linkType: null,
                },
                {
                    name: 'Ograniczyłem/łam.',
                    value: '2',
                    score: 0,
                    link: null,
                    linkType: null,
                },
                {
                    name: 'Zmieniłem/łam na słabsze.',
                    value: '3',
                    score: 0,
                    link: null,
                    linkType: null,
                },
                {
                    name: 'Zmieniłem/łam na cienkie.',
                    value: '4',
                    score: 0,
                    link: null,
                    linkType: null,
                },
                {
                    name: 'Całkowicie zmieniłem/łam na e-papierosy/tytoń podgrzewany.',
                    value: '5',
                    score: 0,
                    link: null,
                    linkType: null,
                }, {
                    name: 'Czasem zamiast papierosa używam e-papierosy/tytoń podgrzewany.',
                    value: '6',
                    score: 0,
                    link: null,
                    linkType: null,
                },
                {
                    name: 'Nie, nie wprowadziłem/łam żadnych zmian.',
                    value: '7',
                    score: 0,
                    link: null,
                    linkType: null,
                },
            ]
        },
        {
            id: 6,
            name: 'Czy już próbowałeś/łaś rzucić palenie?',
            required: true,
            type: 'select',
            answer: [
                {
                    name: 'Nie, nigdy.',
                    value: '1',
                    score: 0,
                    link: null,
                    linkType: null,
                },
                {
                    name: 'Tak, raz udało się ograniczyć przez jakiś czas.',
                    value: '2',
                    score: 0,
                    link: null,
                    linkType: null,
                },
                {
                    name: 'Tak, kilka razy udało się ograniczyć przez jakiś czas.',
                    value: '3',
                    score: 0,
                    link: null,
                    linkType: null,
                },
                {
                    name: 'Tak, raz udało się nie palić przez jakiś czas.',
                    value: '4',
                    score: 0,
                    link: null,
                    linkType: null,
                },
                {
                    name: 'Tak, kilka razy udało się nie palić przez jakiś czas.',
                    value: '5',
                    score: 0,
                    link: null,
                    linkType: null,
                },

            ]
        },
    ],
}