export default {
    beforeQuittingStep3Questions:[
        {
            id: 0,
            name: 'Co się zmieni jeśli przestaniesz palić?',
            type: 'multicheckbox',
            dependsOn: null,
            answer: [
                {
                    name: 'Będę dumny/a z pokonania słabości.',
                    value: '1',
                },
                {
                    name: 'Będę wolny/a.',
                    value: '2',
                },
                {
                    name: 'Pozbędę się uczucia wstydu.',
                    value: '3',
                },
                {
                    name: 'Zyskam wewnętrzny spokój.',
                    value: '4',
                },
                {
                    name: 'Będę ładniej pachnieć.',
                    value: '5',
                },
                {
                    name: 'Zadbam o swoje zdrowie.',
                    value: '6',
                },
                {
                    name: 'Zadbam o zdrowie bliskich.',
                    value: '7',
                },
                {
                    name: 'Będę dobrym wzorem dla bliskich.',
                    value: '8',
                },
                {
                    name: 'Poprawię kondycję.',
                    value: '9',
                },
                {
                    name: 'Zaoszczędzę pieniądze.',
                    value: '10',
                },
                {
                    name: 'Poprawią się moje relacje z bliskimi.',
                    value: '11',
                },
                {
                    name: 'Inne.',
                    value: '12',
                },
            ],
         },
         {
            id: 1,
            name: 'Jak sądzisz, na ile potrafisz sobie radzić z potrzebą palenia?',
            type: 'select',
            required: true,
            dependsOn: null,
            answer: [
                {
                    name: 'Nie wiem, jeszcze nigdy nie rzucałem/łam.',
                    value: '1',
                },
                {
                    name: 'Słabo sobie radzę, kiedy czuję potrzebę palenia zwykle zapalam papierosa. ',
                    value: '2',
                },
                {
                    name: 'Nie radzę sobie najlepiej, ale potrafię powstrzymać chęć palenia przez jakiś czas.',
                    value: '3',
                },
                {
                    name: 'Radze sobie dość dobrze i potrafię powstrzymać się od zapalenia papierosa.',
                    value: '4',
                },
                {
                    name: 'Radzę sobie dobrze i bez problemu powstrzymuję chęć palenia.',
                    value: '5',
                },
            ]
        },
    ],
}