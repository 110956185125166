<template>
    <f7-page name="success">
        <navbar-component hair-lines>
            <f7-link icon-f7="bars" panel-open="left"></f7-link>
            <template v-slot:title>{{$t('info.title')}}</template>
        </navbar-component>

        <f7-block id="top-block">
            <div class="header-block">
                <div>
                    <img src="../assets/img/emoji_events-24px.svg" slot="media" class="nav-list-img" style="filter: invert(100%) sepia(15%) saturate(0%) hue-rotate(39deg) brightness(104%) contrast(103%);" />
                </div>
                <div>
                    <h3>
                        {{$t('success.title')}}
                    </h3>
                </div>
            </div>
        </f7-block>

        <div class="content-container">
            <f7-block id="prep-content-block" v-if="internetConnection">
                <div>
                    <h2 id="pg_header">
                        {{$t('success.ranking_header')}}
                    </h2>
                </div>
                
                <div class="table-cnt">
                    <table>
                        <tr>
                            <th rowspan="2" style="border-bottom: 2px solid #E96331;">
                                {{$t('success.table_position')}}
                            </th>
                            <th rowspan="2" style="border-bottom: 2px solid #E96331;">
                                {{$t('success.table_login')}}
                            </th>
                            <th colspan="3">
                                {{$t('success.table_time_since')}}
                            </th>
                        </tr>
                        <tr style="border-bottom: 2px solid #E96331;">
                            <th>
                                {{$t('success.table_days')}}
                            </th>
                            <th>
                                {{$t('success.table_hours')}}
                            </th>
                            <th>
                                {{$t('success.table_minutes')}}
                            </th>
                        </tr>
                        <tr v-for="(user, key) in ranking" :key="key" :style="user.uid == userId ? 'color: #ee562b; font-weight: bold;' : ''">
                            <td>
                                {{user.position}}
                            </td>
                            <td>
                                {{user.login}}
                            </td>
                            <td>
                                {{user.time.days}}
                            </td>
                            <td>
                                {{user.time.hours}}
                            </td>
                            <td>
                                {{user.time.minutes}}
                            </td>

                        </tr>
                    </table>
                </div>

                <div class="ranking_user_info" v-if="usersRanking">
                    <h3 v-html="$t('success.ranking_user_info', {position: usersRanking.position})"></h3>
                </div>
            </f7-block>

            <f7-block v-else>
                <div class="card nointernet-container">
                    <f7-icon f7="wifi_slash" class="nointernet-icon"></f7-icon>
                    <div class="nointernet-message">
                        {{$t('app.no_internet')}}
                    </div>
                </div>
            </f7-block>

        </div>

    </f7-page>
</template>

<script>
    import NavbarComponent from '../components/navbar.vue';
    import User from '../utils/User';
    import Application from '../utils/Application';
    import _ from 'lodash';

    export default {
        name: "success",
        data(){
            return{
                userId: 0,
                content: null,
                ranking: null,
                usersRanking: null,
                internetConnection: Application.checkConnection(),
            }
        },
        components: {
            NavbarComponent
        },
        methods:{
        },
        created() {
            this.content = this.$t('infoContent').infoContent
        },
        mounted() {
            this.$f7ready(f7=>{
                document.querySelector('#view-success').addEventListener('tab:show',()=>{
                    this.userId = User.id;
                    
                    this.$f7.data.stats.getRanking().then(ranking => {
                        this.ranking = ranking;

                        const userInRanking = _.find(this.ranking, ['uid', String(User.id)]);
                        if (!userInRanking) {
                            this.$f7.data.stats.getUsersRanking().then(usersRanking => {
                                if (usersRanking && usersRanking.position > 0){
                                    this.usersRanking = usersRanking;
                                }
                            });
                        }
                    });
                });
            })
        }
    }
</script>

<style lang="scss" scoped>
    @media only screen and (max-width: 600px) {
        table {
            font-size: 15px;
        }
        #header-icon {
            top: 7px;
        }
        div#back-btn > button {
            font-size: 3.5vw;
        }
        div#back-btn > button i {
            font-size: 4vw;
        }
        table td, table th {
            padding: 5px 0;
        }
    }
    @media only screen and (min-width: 601px) {
        #header-icon {
            top: 15px;
        }
        #pg_header {
            font-size: 4.5vw;
        }
        h2#work {
            font-size: 4.5vw;
        }
        div#prep-paragraphs {
            font-size: 3vw;
        }
        div#back-btn {
            margin-bottom: 50px;
        }
        div#back-btn > button {
            font-size: 3vw;
        }
        div#back-btn > button i {
            font-size: 3vw;
        }
        .info-content {
            font-size: 3vw;
        }
        table td, table th {
            font-size: 2.5vw;
            padding: 8px 0;
        }
    }

    .page[data-name="success"]{
        background-image: url("../assets/img/bg.png");
        background-position: center;
        background-size: cover;
    }

    .content-container{
        background-color: #efeff4;
        min-height: calc(100% - 70px);
        padding: 3vw;
    }

    #top-block {
        margin: 3vh 0 !important;
    }
    #header-icon {
        position: relative;
    }
    /deep/ .nav-list-img {
        height: auto;
        color: #fff;
    }

    #prep-content-block {
        display: flex;
        margin: 0 !important;
        flex-direction: column;
        min-height: 70vh;
    }
    #pg_header {
        margin: 8px 0;
    }
    h2#work {
        margin-bottom: 2vh;
        text-transform: uppercase;
    }

    .table-cnt {
        border: 2px solid #E96331;
        border-radius: 10px;
        margin: 10px 0;
        background-color: #fff;
    }
    table {
        text-align: center;
        border-collapse: collapse;
        width: 100%; 
    }
    table td {
        word-wrap: break-word !important;
    }
    table td:nth-child(2) {
        max-width: 35vw;
    }

    /deep/ .position-highlight {
        color: #ee562b;
    }

    div#back-btn > button {
        background-color: #ee562b;
        border-radius: 25px;
        border-color: #ee562b !important;
        color: #fff;
        padding: 3vw;
        font-family: 'TitilliuRegular' !important;
    }
    div#back-btn > button > div {
        display: flex;
        justify-content: space-between;
        padding: 0 10px;
    }
    div#back-btn > button i {
        font-weight: bold;
    }
</style>

<style lang="scss">

    .page[data-name="success"] .page-content{
        font-family: 'TitilliuRegular';
    }

</style>