<template>
    <div>
        <div id="top-timers">
        </div>
        
        <div class="content-container">
            <f7-block class="cig-input-container">
                <div class="cig-input">
                    <f7-block class="answer-loader" style="height: auto;" v-if="loading">
                        <div class="answer-loader-inside">
                            <f7-preloader color="orange"></f7-preloader>
                            <div class="answer-loader-txt">
                                {{$t('app.loading')}}
                            </div>
                        </div>
                    </f7-block>

                    <template v-else>
                    <div class="item-title top-desc">{{$t('zero_cigarette.intro')}}</div>
                    <div class="cig-input-fields">
                        <div class="cig-input-fields-left">
                            <ul>
                                <li>
                                    <div class="item-content item-input">
                                        <div class="item-inner">
                                            <f7-list form class="">
                                                <f7-list-input
                                                    :label="$t('beforeQuitting.journal_time')"
                                                    type="time"
                                                    :value="cig_input.time"
                                                    :error-message="errorMessage.time"
                                                    :error-message-force="forceError.time"
                                                    @input="cig_input.time = $event.target.value"
                                                    ref="time"
                                                ></f7-list-input>
                                            </f7-list>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="cig-input-fields-right" @click="submit()">
                            <f7-icon material="smoking_rooms"></f7-icon>
                            <f7-button>{{$t($f7.data.timeTillEndOfCigaretteJournal ? 'app.save' : 'app.start')}}</f7-button>
                        </div>
                    </div>
                    </template>

                </div>
            </f7-block>
        </div>
    </div>
</template>

<script>
    import $ from 'dom7';
    import Application from '../utils/Application.js';
    import * as moment from 'moment';
    
    export default {
        name: "zero-cigarette-component",
        props:['cb'],
        data() {
            return {
                loading: false,
                cig_input: {
                    time: null,
                },
                errorMessage: {
                    time: '',
                },
                forceError: {
                    time: null,
                    situation: null
                },
            }
        },
        computed: {
            timeToDate: function() {
                const currentDate = this.extractDate(this.getDate());

                if (this.cig_input.time) {
                    return moment(currentDate + " " + this.cig_input.time, "DD.MM.YYYY HH:mm");
                } else {
                    return this.getDate();
                }
            }
        },
        watch: {
            'cig_input.time': function(current, prev) {
                if (this.timeToDate > this.getDate() || current == '') {
                    this.forceError.time = true;
                    this.$refs.time.$data.isInvalid = true;
                    
                    if (this.timeToDate > this.getDate())
                        this.errorMessage.time = this.$t('beforeQuitting.error_time_after_now');
                    else
                        this.errorMessage.time = this.$t('beforeQuitting.error_empty_time');
                } else {
                    this.forceError.time = false;
                    this.$refs.time.$data.isInvalid = false;
                }
            },
        },
        methods: {
            reset() {
                this.cig_input.time = null;
            },

            submit() {
                let validation = this.validate();
                if (!validation) return;

                this.$f7.dialog.create({
                    title: this.$t('zero_cigarette.zero_cig_input_confirm_title'),
                    text: this.$t('zero_cigarette.zero_cig_input_confirm_content'),
                    buttons: [
                        {
                            text: this.$t('app.cancel'),
                            onClick: (d) => { d.close(); }
                        },
                        {
                            text: "OK",
                            onClick: (d) => {
                                this.loading = true;

                                let time = this.timeToDate;
                                
                                this.$f7.data.stats.saveCigarette({
                                    time:       time.toISOString(),
                                });
                                this.$f7.data.stats.setTimerZeroCigarette(time);

                                setTimeout(() => {
                                    this.reset();
                                    this.cb();
                                    this.loading = false;
                                }, 1000);

                                d.close();
                            }
                        },
                    ],
                }).open();
            },

            validate() {
                let valid = true;
                
                for (let [key, ref] of Object.entries(this.$refs)) {
                    if (ref) {
                        if ((ref.state && ref.state.inputInvalid) || ref.$data.isInvalid || ref.value == "" || !ref.value) {
                            this.forceError[key] = true;
                            if (key === 'time' && this.errorMessage.time === '') {
                                this.errorMessage.time = this.$t('beforeQuitting.error_empty_time');
                            }
                            valid = false;
                        }
                    }
                }
                return valid;
            }

        },
        mounted() {
            this.$f7ready(f7 => {
            })
        }
    }
</script>

<style lang="scss" scoped>
    @media only screen and (max-width: 600px) {
        #top-timers {
            padding-bottom: 150px;
        }
        .content-container {
            padding: 100px 0 3vw 0;
            min-height: 55vh;
        }
        .cig-input-container {
            top: -175px;
        }
        .cig-input {
            height: 350px;
        }
        .cig-input .item-title {
            font-size: 20px;
        }
        /deep/ .cig-input .item-label {
            font-size: 20px !important;
        }
        /deep/ .cig-input input {
            font-size: 25px;
        }
        div#journal-instructions {
            font-size: 3vw;
        }
    }
    @media only screen and (min-width: 601px) {
        #top-timers {
            height: 300px;
            padding-bottom: 10px;
        }
        .content-container {
            padding: 190px 0 3vw 0;
            min-height: 50vh;
        }
        .cig-input-container {
            top: -300px;
        }
        .cig-input {
            height: 550px;
        }
        .cig-input .item-title {
            font-size: 5vw;
        }
        /deep/ .cig-input .item-label {
            font-size: 45px;
        }
        /deep/ .cig-input input {
            font-size: 45px;
        }
        /deep/ .cig-input .item-input-wrap {
            margin: 15px 0;
        }
        /deep/ .cig-input .item-input-error-message {
            font-size: 2.5vw;
        }
    }

    .content-container {
        background-color: #efeff4;
        position: relative;
    }
    .top-desc {
        padding: 30px !important;
    }

    .cig-input-container {
        position: absolute;
        width: 100%;
    }

    div#cig-inputs {
        flex-grow: 1;
    }
    .cig-input {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 2vh 0;
        border-radius: 25px;
        box-shadow: 2px 4px rgba(0, 0, 0, 0.22), 0 1px 2px rgba(0, 0, 0, 0.24);
    }
    .cig-input ul {
        padding: 0 !important;
    }
    .cig-input-fields {
        display: flex;
    }
    .cig-input-fields-left {
        display: flex;
        align-items: center;
        flex-grow: 2;
    }
    .cig-input-fields-left ul {
        width: 100%;
    }
    .cig-input-fields-right {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #d53e06;
        min-width: 20%;
    }
    .cig-input-fields-right i {
        font-size: 20vw;
    }
    .cig-input-fields-right .button {
        color: #d53e06;
        font-family: 'TitilliuBold';
        font-size: 4vw;
    }

    /deep/ .cig-input ul:after{
        width: 0 !important;
    }

    /deep/ .cig-input ul:before{
        width: 0 !important;
    }

    /deep/ .cig-input ul{
        background: transparent !important;
    }

    /deep/ .cig-input{
        background-color: white;
        border-radius: 25px;
    }
    /deep/ .cig-input li {
        list-style: none;
    }

    /deep/ .simple-list li:after, .links-list a:after, .cig-input .item-inner:after{
        width: 0;
    }

    /deep/ .cig-input .item-title {
        padding: 2vw 2vw 0 2vw;
    }
    /deep/ .cig-input .list {
        width: 90%;
        margin: 0 3vw 3vw 3vw;
    }
    /deep/ .cig-input .item-label {
        margin-bottom: 1vh;
        font-weight: bold;
        color: #000;
    }
    /deep/ .cig-input .item-input-invalid .item-label {
        color: red;
    }
    /deep/ .cig-input input {
        border-bottom: 1px solid grey;
    }
    /deep/ .cig-input .item-input-error-message {
        margin: 2vw;
        text-overflow: clip;
        overflow: visible;
        white-space: normal;
    }
</style>