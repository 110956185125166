export default {
    beforeQuittingStep2Questions:[
        {
            id: 0,
            name: 'Czy sądzisz, że rzucisz jeśli tylko zechcesz?',
            type: 'select',
            dependsOn: null,
            required: true,
            answer: [
                {
                    name: 'Palę bo lubię, ale w każdej chwili mogę przestać.',
                    value: '1',
                    score: 3,
                    link: null,
                    linkType: null
                },
                {
                    name: 'Nie.',
                    value: '2',
                    score: 0,
                    link: 6,
                    linkType: 'questions'
                },
            ],
         },
        {
            id: 1,
            name: 'Czy myślisz, że przy Twoim trybie życia rzucenie palenia jest niemal niemożliwe?',
            type: 'select',
            dependsOn: 0,
            required: true,
            answer: [
                {
                    name: 'Mam tyle stresów w codziennym życiu, że tylko palenie pozwala mi przetrwać.',
                    value: '1',
                    score: 1,
                    link: 5,
                    linkType: null,
                },
                {
                    name: 'Nie.',
                    value: '2',
                    score: 1,
                    link: null,
                    linkType: null,
                },
            ]
        },
        {
            id: 2,
            name: 'Czy uważasz, że informacje o szkodliwości palenia są przesadzone?',
            type: 'select',
            dependsOn: 1,
            required: true,
            answer: [
                {
                    name: 'Tak, znam osoby, które wiele lat paliły i dożyły późnej starości.',
                    value: '1',
                    score: 3,
                    link: null,
                    linkType: null,
                },
                {
                    name: 'Nie.',
                    value: '2',
                    score: 2,
                    link: null,
                    linkType: null,
                },
            ]
        },
    ],
}