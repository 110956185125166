import Auth from './Auth.js';
import Application from './Application.js';
import User from './User.js';
import Util from './Util.js';
import * as moment from 'moment';

export default class Statistics {

    constructor(store, f7) {
        this.store = store;
        this.f7 = f7;
        //console.log(f7.data.api.url)
    }

    /**
     * Kończy dziennik kontrolowanego palenia.
     *
     * @memberof Statistics
     */
    endCigaretteJournal() {
        let journal = this.getCigaretteJournal();
        if (!journal) return;
        
        let settings = this.store.get("_settings");

        if (settings) {
            settings.cigaretteJournal.ended = true;
        }
        this.store.set("_settings", settings);
        this.setUpdateRemote(true);
    }

    /**
     * Usuwa statystyki z numerami ID przed wysłaniem.
     * Statystyki z numerami ID to te, które zostały już 
     * zapisane na serwerze.
     *
     * @param {*} storage
     * @returns {object}
     * @memberof Statistics
     */
    extractNotSavedStats(storage) {
        storage.visits = storage.visits.filter(stat => {
            return stat.id == null;
        });
        storage.cigarettes = storage.cigarettes.filter(stat => {
            return stat.id == null;
        });

        if (storage.visits.length == 0 && storage.cigarettes.length == 0) 
            return null;

        return storage;
    }

    /**
     * Pobiera odpowiedzi na pytania z jednego lub wszystkich modułów.
     * Brak argumentu = pobiera wszystkie.
     *
     * @param {*} [moduleName=null]
     * @returns {Promise}
     * @memberof Statistics
     */
    getAnswers(moduleName = null) {
        let requests = [];
        
        if (!moduleName) {
            this.f7.data.questionModules.forEach(name => {
                requests.push(this.getModuleAnswers(name));
            });
        }
        else {
            requests.push(this.getModuleAnswers(moduleName));
        }

        return Promise.all(requests);
    }

    /**
     * Pobiera dane wprowadzone w polach kalkulatora z local storage.
     *
     * @returns {object}
     * @memberof Statistics
     */
    getCalculatorInputs() {
        let stats = this.store.get('_stats');
        if (stats.calculatorInputs) {
            return stats.calculatorInputs;
        }
        return null;
    }

    /**
     * Zwraca dziennik kontrolowanego palenia.
     *
     * @returns {object}
     * @memberof Statistics
     */
    getCigaretteJournal() {
        const settings = this.store.get("_settings");
        if (settings && settings.cigaretteJournal) {
            return settings.cigaretteJournal;
        }
        return null;
    }

    /**
     * Pobiera odpowiedzi na pytania z danego modułu i 
     * zapisuje je w local storage.
     *
     * @param {*} moduleName
     * @returns {Promise}
     * @memberof Statistics
     */
    getModuleAnswers(moduleName) {
        const self = this;
        const successCallback = function(response) {
            let parsedResponse = null;
            try {
                parsedResponse = JSON.parse(response);
            }
            catch(e) {
                console.log(e);
                this.reject();
                return;
            }

            if (parsedResponse && parsedResponse.success) {
                const answers = self.store.get(`_${moduleName}`);
                let parsedResponseAnswers = {};
                
                // Pobiera odpowiedzi tylko, jeżeli nie ma ich jeszcze w local storage. 
                if (!answers && parsedResponse.data.question_answers.length > 0) {
                    parsedResponse.data.question_answers.forEach(ans => {
                        parsedResponseAnswers[ans["qkey"]] = JSON.parse(ans["answer"]);
                    });
                    parsedResponseAnswers.sent = true;

                    self.store.set(`_${moduleName}`, parsedResponseAnswers);
                }
                
                this.resolve(parsedResponseAnswers);
            }
            else {
                console.error(`Nie udało się pobrać odpowiedzi użytkownika z modułu ${moduleName}.`);
                this.reject();
            }
        };

        return this.sendRequest("GET", `/loadUserQuestions/${moduleName}`, successCallback, null);
    }

    /**
     * Pobiera ranking użytkowników.
     *
     * @returns {Promise}
     * @memberof Statistics
     */
    getRanking() {
        const self = this;
        const successCallback = function(response) {
            let parsedResponse = null;
            try {
                parsedResponse = JSON.parse(response);
            }
            catch(e) {
                console.log(e);
                this.reject();
                return;
            }

            if (parsedResponse && parsedResponse.success) {
                const ranking = parsedResponse.data.ranking.map(rank => {
                    const date = rank['last_registered'].replace(' ', 'T') + '.000Z';
                    const dateSeconds = new Date(date).getTime() / 1000;

                    let currentDate = Application.date;
                    if (Application.platform === 'iOS') {
                        currentDate = new Date(currentDate.setHours(currentDate.getHours() - 1));
                    }
                    const currentSeconds = currentDate.getTime() / 1000;

                    rank['time'] = Util.secondsToTime(currentSeconds - dateSeconds);
                    
                    return rank;
                });

                this.resolve(ranking);
            }
            else {
                console.error('Nie udało się pobrać rankingu');
                this.reject();
            }
        };

        return this.sendRequest('GET', '/loadRanking', successCallback, null);
    }

    /**
     * Zwraca bool czy użytkownik wysłał już ocenę aplikacji.
     *
     * @returns {boolean}
     * @memberof Statistics
     */
    getRatingSubmitted() {
        const settings = this.store.get("_settings");
        if (settings && settings.ratingSubmitted) {
            return settings.ratingSubmitted;
        }
        return null;
    }

    /**
     * Pobiera statystyki i zapisuje je w local storage.
     *
     * @returns {Promise}
     * @memberof Statistics
     */
    getStats() {
        const self = this;
        const successCallback = function(response) {
            let parsedResponse = null;
            try {
                parsedResponse = JSON.parse(response);
            }
            catch(e) {
                console.log(e);
                this.reject();
                return;
            }

            if (parsedResponse && parsedResponse.success) {
                let stats = self.store.get('_stats');

                if (!self.getUpdateRemote()) {
                    if (stats) {
                        stats.visits = parsedResponse.data.visits;
                        stats.cigarettes = parsedResponse.data.cigarettes;
                    } else {
                        stats = parsedResponse.data;
                    }

                    stats = self.parseStatsDates(stats);
                    self.store.set('_stats', stats);
                }

                this.resolve(stats);
            }
            else {
                console.error("Nie udało się pobrać statystyk użytkownika.");
                this.reject();
            }
        };

        return this.sendRequest("GET", "/loadUserStats", successCallback, null);
    }

    /**
     * Zwraca czas papierosa "0".
     *
     * @returns {string}
     * @memberof Statistics
     */
    getTimerZeroCigarette() {
        const settings = this.store.get("_settings");
        if (settings && settings.cigaretteZero) {
            return settings.cigaretteZero;
        }
        return null;
    }

    /**
     * Zwraca wartość 'updateRemote'.
     *
     * @returns {boolean}
     * @memberof Statistics
     */
    getUpdateRemote() {
        const settings = this.store.get("_settings");
        if (settings && settings.updateRemote) {
            return settings.updateRemote;
        }
        return null;
    }

    /**
     * Pobiera ranking zalogowanego użytkownika.
     *
     * @returns {Promise}
     * @memberof Statistics
     */
    getUsersRanking() {
        const self = this;
        const successCallback = function(response) {
            let parsedResponse = null;
            try {
                parsedResponse = JSON.parse(response);
            }
            catch(e) {
                console.log(e);
                this.reject();
                return;
            }

            if (parsedResponse && parsedResponse.success) {
                const ranking = parsedResponse.data;
                this.resolve(ranking);
            }
            else {
                console.error('Nie udało się pobrać rankingu użytkownika.');
                this.reject();
            }
        };

        return this.sendRequest('GET', '/loadUsersRanking', successCallback, null);
    }

    /**
     * Pobiera odpowiedzi na pytania z danego modułu i 
     * zapisuje je w local storage.
     *
     * @returns {Promise}
     * @memberof Statistics
     */
    getUserSettings() {
        const self = this;

        const successCallback = function(response) {
            let parsedResponse = null;
            try {
                parsedResponse = JSON.parse(response);
            }
            catch(e) {
                console.log(e);
                this.reject();
                return;
            }

            if (parsedResponse && parsedResponse.success) {
                let settings = self.store.get("_settings");

                if (!self.getUpdateRemote()) {
                    if (!settings) {
                        settings = {};
                    }

                    settings.userGroup = Number(parsedResponse.data.userGroup);
                    settings.cigaretteZero = Number(parsedResponse.data.cigaretteZero);
                    settings.ratingSubmitted = Number(parsedResponse.data.ratingSubmitted);
    
                    if (parsedResponse.data.cigaretteJournal) {
                        settings.cigaretteJournal = parsedResponse.data.cigaretteJournal;
                        settings.cigaretteJournal.startTime = settings.cigaretteJournal.startTime;
                        settings.cigaretteJournal.duration = settings.cigaretteJournal.duration;
                        settings.cigaretteJournal.ended = settings.cigaretteJournal.ended == "0" ? false : true;
                    }
                    self.store.set("_settings", settings);
                }
                
                this.resolve(settings);
            }
            else {
                console.error(parsedResponse.message);
                this.resolve(parsedResponse.message);
            }
        };

        return this.sendRequest("GET", "/loadUserSettings", successCallback, null);
    }

    /**
     * Oznacza odpowiedzi na pytania z danego 
     * modułu jako wysłane.
     *
     * @param {*} moduleName
     * @memberof Statistics
     */
    markAnswersAsSent(moduleName) {
        const answers = this.store.get(`_${moduleName}`);

        if (answers) {
            answers.sent = true;
            this.store.set(`_${moduleName}`, answers);
        }
    }

    /**
     * Ustawia odpowiedni format dat pobranych z serwera.
     *
     * @param {*} stats
     * @returns {array}
     * @memberof Statistics
     */
    parseStatsDates(stats) {
        const parseDates = (s) => {
            return s.map(stat => {
                stat.registered = stat.registered.replace(' ', 'T');
                stat.registered += '.000Z';
                return stat;
            });
        }
        
        stats.visits = parseDates(stats.visits);
        stats.cigarettes = parseDates(stats.cigarettes);
        return stats;
    }

    /**
     * Zapisuje odpowiedzi na pytania z danego modułu.
     *
     * @param {*} moduleName
     * @param {*} answers
     * @memberof Statistics
     */
    saveAnswers(moduleName, answers) {
        this.store.set(`_${moduleName}`, answers);
        this.setUpdateRemote(true);
        console.log(`Zapisano odpowiedzi z modułu "${moduleName}".`);
    }

    /**
     * Zapisuje dane wprowadzone w polach kalkulatora do local storage.
     *
     * @param {*} inputs wartości pól kalkulatora
     * @memberof Statistics
     */
    saveCalculatorInputs(inputs) {
        for (let [key, val] of Object.entries(inputs)) {
            if (!val) return;
        }

        let stats = this.store.get('_stats');
        const calculatorInputs = {
            last_cig: inputs.last_cig,
            daily_cigs: inputs.daily_cigs,
            cigs_price: inputs.cigs_price,
            cigs_quantity: inputs.cigs_quantity,
        };
        stats.calculatorInputs = calculatorInputs;
        this.store.set('_stats', stats);
        console.log("Zapisano pola kalkulatora.");
    }

    /**
     * Dodaje nowego spalonego papierosa do local storage.
     *
     * @param {*} [cig={}] dane papierosa: czas, sytuacja (opcjonalna)
     * @memberof Statistics
     */
    saveCigarette(cig = {}) {
        if (!cig.time) {
            cig.time = new Date().toISOString();
        }
        if (!cig.situation) {
            cig.situation = null;
        }

        let stats = this.store.get('_stats');
        const newCigarette = {
            id: null,
            registered: cig.time,
            situation: cig.situation,
        };
        stats.cigarettes.push(newCigarette);
        this.store.set('_stats', stats);
        this.setUpdateRemote(true);
        console.log("Zapisano spalonego papierosa.");
    }

    /**
     * Wysyła ocenę aplikacji na serwer, gdzie
     * zostaje wysłana dalej na wskazanego maila.
     *
     * @param {*} rating
     * @returns
     * @memberof Statistics
     */
    saveRating(rating) {
        const self = this;
        
        const successCallback = function(response) {
            let parsedResponse = null;
            try {
                parsedResponse = JSON.parse(response);
            }
            catch(e) {
                console.log(e);
                return;
            }

            if (parsedResponse && parsedResponse.success) {
                console.log(parsedResponse);
                self.setUpdateRemote(true);
                this.resolve();
            }
            else {
                console.error('Nie udało się wysłać oceny aplikacji.');
                this.reject();
            }
        };

        const data = {
            rating: JSON.stringify(rating)
        };

        return this.sendRequest('POST', '/saveRating', successCallback, data)
    }

    /**
     * Rejestruje nową wizytę użytkownika.
     * Przez wizytę rozumiemy każde uruchomienie aplikacji.
     * Wykorzystywa później w statystykach i rankingu.
     *
     * @memberof Statistics
     */
    saveVisit() {
        const time = new Date().toISOString();

        let stats = this.store.get('_stats');
        if (stats) {
            const newVisit = {
                id: null,
                registered: time,
            };
            stats.visits.push(newVisit);
            this.store.set('_stats', stats);
            console.log("Zapisano nową wizytę.");
        }
    }

    /**
     * Wysyła ustawienia użytkownika na serwer.
     *
     * @memberof Statistics
     */
    saveUserSettings() {
        const settings = this.store.get('_settings');
        if (!settings) {
            console.log("Brak ustawień użytkownika.");
            return;
        }

        const successCallback = function(response) {
            let parsedResponse = null;
            try {
                parsedResponse = JSON.parse(response);
            }
            catch(e) {
                console.log(e);
                return;
            }

            if (parsedResponse && parsedResponse.success) {
                console.log(parsedResponse);
                this.resolve();
            }
            else {
                console.error("Nie udało się wysłać ustawień użytkownika.");
                this.reject();
            }
        };

        const data = {
            user_settings: JSON.stringify(settings)
        };

        this.sendRequest("POST", "/saveUserSettings", successCallback, data)
    }

    /**
     * Wysyła odpowiedzi na pytania z danego modułu.
     *
     * @param {*} moduleName
     * @memberof Statistics
     */
    sendAnswers(moduleName) {
        const self = this;
        const answers = this.store.get(`_${moduleName}`);

        if (!answers) {
            console.log(`Brak odpowiedzi dla modułu "${moduleName}".`);
            return;
        }
        if (answers.sent) {
            console.log(`Odpowiedzi na pytania z modułu "${moduleName}" są oznaczone jako wysłane.`);
            return;
        }

        const successCallback = function(response) {
            let parsedResponse = null;
            try {
                parsedResponse = JSON.parse(response);
            }
            catch(e) {
                console.log(e);
                return;
            }

            if (parsedResponse && parsedResponse.success) {
                console.log(parsedResponse.message);
                self.markAnswersAsSent(moduleName);
                this.resolve();
            }
            else {
                console.error(parsedResponse.message);
                this.reject();
            }
        };

        const data = {
            question_answers: JSON.stringify(answers)
        };

        this.sendRequest("POST", `/saveUserQuestions/${moduleName}`, successCallback, data);
    }

    /**
     * Wysyła statystyki i następnie pobiera je z serwera 
     * z nadanymi ID i zapisuje je w local storage.
     *
     * @returns
     * @memberof Statistics
     */
    sendStats() {
        const self = this;
        const stats = this.extractNotSavedStats(this.store.get('_stats'));
        if (!stats) {
            console.log("Brak nowych statystyk do wysłania.");
            return;
        }

        const successCallback = function(response) {
            let parsedResponse = null;
            try {
                parsedResponse = JSON.parse(response);
            }
            catch(e) {
                console.log(e);
                return;
            }
            
            if (parsedResponse && parsedResponse.success) {
                console.log(parsedResponse.message);
                self.getStats();
                this.resolve();
            }
            else {
                console.error("Nie udało się wysłać statystyk użytkownika.");
                this.reject();
            }
        };

        const data = {
            stats: JSON.stringify(stats)
        };

        this.sendRequest("POST", "/saveUserStats", successCallback, data)
    }

    /**
     * Funkcja wysyłająca zapytania HTTP.
     *
     * @param {*} method
     * @param {*} URI
     * @param {*} successCallback
     * @param {*} data
     * @returns {Promise}
     * @memberof Statistics
     */
    sendRequest(method, URI, successCallback, data) {
        const headers = Auth.createBearerHeader();

        if (!headers) return null;

        return new Promise((resolve, reject) => {

            const ctx = {
                resolve: resolve,
                reject: reject,
            };
    
            this.f7.request({
                method: method,
                url: this.f7.data.api.url + URI,
                data: data,
                headers,
                success: successCallback.bind(ctx),
                error: function (error) {
                    console.error('Request error.');
                    console.error(error);
                }
            });

        });
    }

    /**
     * Zapisuje w local storage informacje, że
     * użytkownik wysłał ocenę aplikacji.
     *
     * @memberof Statistics
     */
    setRatingSubmitted() {
        let settings = this.store.get("_settings");

        if (settings) {
            settings.ratingSubmitted = 1;
        } else {
            settings = {
                ratingSubmitted: 1
            };
        }
        this.store.set("_settings", settings);
    }

    /**
     * Zapis czasu papierosa "0", wprowadzanego po
     * zakończeniu dziennika kontrolowanego palenia
     * w module rzucania lub abstynencji.
     *
     * @param {*} t
     * @memberof Statistics
     */
    setTimerZeroCigarette(t) {
        let settings = this.store.get("_settings");
        const time = String(t.valueOf());

        if (settings) {
            settings.cigaretteZero = time;
        } else {
            settings = {
                cigaretteZero: time
            };
        }
        this.store.set("_settings", settings);
    }

    /**
     * Funkcja wywoływana w wielu miejscach aplikacji,
     * Ustawia updateRemote na true lub false - 
     * jeżeli true, to znaczy, że w ciągu następnych 10 sekund
     * zostanie podjęta próba wysłania danych z local storage
     * na serwer.
     *
     * @param {*} update
     * @memberof Statistics
     */
    setUpdateRemote(update) {
        let settings = this.store.get("_settings");
        if (settings) {
            settings.updateRemote = update;
        } else {
            settings = { updateRemote: update };
        }
        this.store.set("_settings", settings);
    }

    /**
     * Rozpoczęcie dziennika kontrolowanego palenia.
     *
     * @param {*} diff między czasem rejestracji papierosa a wprowadzonym czasem
     * @memberof Statistics
     */
    startCigaretteJournal(diff) {
        if (this.getCigaretteJournal()) return;
        
        let settings = this.store.get("_settings");
        const time = String(moment().subtract(1, 'minutes').valueOf() - diff);
        const duration = String(1000 * 60 * 60 * 24 * (User.getGroup() != 1 ? 1 : 7) + diff + 60000);
        // const duration = String(1000 * 60 * 2 + diff + 60000);

        if (settings) {
            settings.cigaretteJournal = {
                startTime: time,
                duration: duration,
                ended: false,
            };
        } else {
            settings = { 
                cigaretteJournal: {
                    startTime: time,
                    duration: duration,
                    ended: false,
                }
            };
        }
        this.store.set("_settings", settings);
        this.setUpdateRemote(true);
    }

    /**
     * Aktualizacja wszystkich danych z local storage 
     * na serwerze, jeżeli 'updateRemote' jest true.
     * 
     * 'updateRemote' ustawiane jest na true po większości
     * zapisów danych w local storage. 
     *
     * @memberof Statistics
     */
    updateRemote() {
        const updateRemote = this.getUpdateRemote();
        if (updateRemote && Application.checkConnection()) {
            this.sendStats();
            this.f7.data.questionModules.forEach(moduleName => {
                this.sendAnswers(moduleName);
            });
            this.saveUserSettings();
            User.sendPermissions();
            this.setUpdateRemote(false);
        }
    }
    
}