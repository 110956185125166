<template>
    <f7-page name="userPanel">
        <navbar-component hair-lines>
            <f7-link href="/diagnosis/" icon-f7="arrow_left"></f7-link>
            <template v-slot:title>{{$t('leftNavPanel.panel')}}</template>
        </navbar-component>

        <f7-block>
            <f7-row>
                <f7-col class="">
                    <f7-icon f7="person_alt_circle" class="user-img"></f7-icon>
                </f7-col>
            </f7-row>
        </f7-block>

        <f7-block>
            <f7-col width="75" class="user-info">

                <f7-block class="">
                    <f7-row>
                        <f7-col>
                            Login:
                        </f7-col>
                        <f7-col>
                            <div>
                                {{username}}
                            </div>
                        </f7-col>
                    </f7-row>
                    <f7-row>
                        <f7-col>
                            {{$t('userPanel.email')}}
                        </f7-col>
                        <f7-col>
                            <div>
                                {{email}}
                            </div>
                        </f7-col>
                    </f7-row>
                    <f7-row>
                        <f7-col>
                            {{$t('userPanel.registered')}}
                        </f7-col>
                        <f7-col>
                            <div>
                                {{registered}}
                            </div>
                        </f7-col>
                    </f7-row>
                </f7-block>
                
            </f7-col>
        </f7-block>

        <f7-block class="btn-container">
            <send :text="$t('leftNavPanel.wyloguj')" @click.native="logout"></send>
        </f7-block>

        <f7-block class="btn-container">
            <send :text="changingPassword ? $t('userPanel.cancel_change_password') : $t('userPanel.change_password')" @click.native="togglePassChange"></send>
        </f7-block>

        <f7-block v-if="changingPassword">

            <f7-list form id="password_change_form">
                <f7-list-input
                    :label="$t('userPanel.new_password')"
                    type="password"
                    :error-message="$t('userPanel.error_invalid_password')"
                    :error-message-force="formError.new_password"
                    @input="new_password = $event.target.value"
                    ref="new_password"
                ></f7-list-input>
                <f7-list-input
                    :label="$t('userPanel.confirm_new_password')"
                    type="password"
                    :error-message="$t('userPanel.error_wrong_password_confirm')"
                    :error-message-force="formError.confirm_new_password"
                    @input="confirm_new_password = $event.target.value"
                    ref="confirm_new_password"
                ></f7-list-input>

                <f7-block class="btn-container">
                    <send :text="$t('userPanel.change_password')" @click.native="submitPassChange"></send>
                </f7-block>
            </f7-list>
        </f7-block>

    </f7-page>

</template>

<script>
    import navbarComponent from '../components/navbar';
    import Cache from '../utils/storeCache';
    import User from '../utils/User';
    import send from '../components/component-button'

    export default {
        name: "userPanel",
        data(){
            return{
                changingPassword: false,
                new_password: '',
                confirm_new_password: '',
                formError: {
                    new_password: false,
                    confirm_new_password: false,
                },
            }
        },
        components: {
            navbarComponent,
            send,
        },
        computed: {
            username: function() {
                return User.username;
            },
            email: function() {
                return User.email;
            },
            registered: function() {
                const date = new Date(User.registered);
                return this.formatDate(date);
            },
        },
        methods: {
            displayDialog(msg, title, timeout = 2000) {
                const app = this.$f7;
                app.dialog.alert(msg, title);
                setTimeout(function () {
                    app.dialog.close();
                }, timeout);
            },
            logout() {
                User.logout();
                const app = this.$f7;
                app.views.main.router.navigate('/login/', {
                    reloadAll: true,
                });
                app.tab.show('#view-diagnosis');
                app.panel.close("left");
            },
            resetErrors() {
                this.formError = {
                    new_password: false,
                    confirm_new_password: false,
                };
            },
            submitPassChange() {
                this.resetErrors();
                
                if (this.validatePassChange()) {
                    this.$f7.dialog.create({
                        title: this.$t('userPanel.password_change_confirm_title'),
                        text: this.$t('userPanel.password_change_confirm_content'),
                        buttons: [
                            {
                                text: this.$t('app.cancel'),
                                onClick: (d) => { d.close(); }
                            },
                            {
                                text: "OK",
                                onClick: (d) => {
                                    User.changePassword(this.new_password)
                                    .then(result => {
                                        this.displayDialog(result, window.vue.$t("userPanel.password_change_success"), 10000);
                                        this.togglePassChange();
                                    })
                                    .catch(err => {
                                        this.displayDialog(err, window.vue.$t("userPanel.password_change_failure"));
                                    });
                                    d.close();
                                }
                            },
                        ],
                    }).open();
                }
            },
            togglePassChange() {
                this.changingPassword = !this.changingPassword;
            },
            validatePassChange() {
                let valid = true;
                
                if (this.new_password.length < 8) {
                    this.formError['new_password'] = true;
                    valid = false;
                }
                if (this.new_password != this.confirm_new_password) {
                    this.formError['confirm_new_password'] = true;
                    valid = false;
                }

                return valid;
            },
        },
        mounted() {
            
        },
    }
</script>

<style lang="scss" scoped>
    @media only screen and (max-width: 600px) {
        .user-info {
            // font-size: 4.5vw;
        }
    }
    @media only screen and (min-width: 601px) {
        .user-info {
            font-size: 3vw;
        }
    }

    .user-img {
        font-size: 30vw;
        color: #d53e06;
    }
    .user-info {
        display: flex;
        align-items: center;
        overflow-wrap: break-word;
        word-wrap: break-word;
    }
    .user-info > .block {
        margin: 0 !important;
        padding: 0 !important;
    }

    .btn-container {
        margin-top: 0;
        margin-bottom: 0;

        .btn-component {
            margin: 1vh !important;
        }
    }
</style>

<style lang="scss">
    .page[data-name="userPanel"] .page-content{
        background-color: #efeff4;
    }

    .page[data-name="userPanel"] #password_change_form {
    }
    .page[data-name="userPanel"] #password_change_form ul {
        padding: 2vw;
        border-radius: 25px;
    }
    .page[data-name="userPanel"] #password_change_form input {
        border-bottom: 1px solid grey;
    }
</style>