import Auth from './Auth.js';
import User from './User.js';
import Util from './Util.js';
import Application from './Application.js';
import StoreCache from './storeCache';
import * as moment from 'moment';
import $ from 'dom7';

export default class AppSettings {

    static set f7(f7) {
        this._f7 = f7;
    }

    static get settings() {
        return this._settings;
    }

    static set settings(settings) {
        this._settings = settings;
    }

    static set store(store) {
        this._store = store;
    }

    /**
     * Pobiera z serwera ustawienia aplikacji.
     *
     * @static
     * @param {*} settingName
     * @returns {Promise}
     * @memberof AppSettings
     */
    static get(settingName) {
        const self = this;
        const headers = Auth.createBearerHeader();

        if (!headers) return;

        return new Promise((resolve, reject) => {
          this._f7.request({
            method: "GET",
            url: `${this._f7.data.api.url}/ustawienia/${settingName}`,
            headers,
            success: function (response) {
                let parsedResponse = null;
                try {
                    parsedResponse = JSON.parse(response);
                }
                catch(e) {
                    console.error(e);
                    reject();
                    return;
                }

                if (parsedResponse && parsedResponse.success) {
                    const settingValue = parsedResponse.data.settingValue;
                    let appSettings = self._store.get('_app_settings');

                    if (appSettings) {
                        if (!appSettings.notif_freq) {
                            appSettings.notif_freq = {
                                [settingName]: settingValue
                            };
                        }
                        else {
                            appSettings.notif_freq[settingName] = settingValue;
                        }
                    }
                    else {
                        appSettings = {
                            notif_freq: {
                                [settingName]: settingValue
                            }
                        };
                    }
                    self._store.set('_app_settings', appSettings);

                    resolve(settingValue);
                }
                else {
                    console.error("Nie udało się pobrać ustawienia.");
                }
            },
            error: function (error) {
                console.error('Error request');
                reject();
            }
          });
        });

    }

    /**
     * Na podstawie ostatniego powiadomienia z modułu abstynencji
     * Ustawia bieżący tekst powiadomienia widocznego w module.
     *
     * Zapewnia synchronizację między natywnymi powiadomieniami,
     * a tekstem modułu.
     *
     * @static
     * @param {*} setting
     * @memberof AppSettings
     */
    static getCurrentAbstinenceTip(setting) {
        const tipNotifications = this._store.get('_s2_abstinence_notifications') || [];

        let tipCycle = this._store.get("_s2_abstinence_questions_tip_cycle");
        let lastChanged;
        let notifTime;
        try {
            lastChanged = moment(tipCycle.last_changed);
            notifTime = moment(tipCycle.ntf_time);
        }
        catch(e) {
            lastChanged = null;
            notifTime = null;
        }

        const currentNotification = tipNotifications.filter(ntf => {
            const time = moment();
            const timeDiff = time.diff(moment(ntf.time), 'hours');

            return timeDiff >= 0 && timeDiff <= setting;
        });

        const timeSinceChanged = lastChanged && moment().diff(lastChanged, 'hours') >= setting;
        const timeSinceNotif = notifTime && moment().diff(notifTime, 'hours') >= setting;
        if (currentNotification.length > 0 && (timeSinceChanged || timeSinceNotif)) {
            const currentNtf = currentNotification[currentNotification.length - 1];
            tipCycle = {
                tip: currentNtf.tip,
                ntf_time: currentNtf.time,
                last_changed: moment().toDate(),
            };
            this._store.set("_s2_abstinence_questions_tip_cycle", tipCycle);
        }
        else if (!tipCycle) {
            tipCycle = {
                tip: 0,
                ntf_time: null,
                last_changed: moment().toDate(),
            };
            this._store.set("_s2_abstinence_questions_tip_cycle", tipCycle);
        }
    }

    /**
     * Na podstawie ostatniego powiadomienia z modułu farmakoterapii
     * Ustawia bieżący tekst powiadomienia widocznego w module.
     *
     * Zapewnia synchronizację między natywnymi powiadomieniami,
     * a tekstem modułu.
     *
     * @static
     * @memberof AppSettings
     */
    static getCurrentPharmacotherapyTip() {
        const tipNotifications = this._store.get('_s4_pharmacotherapy_notifications') || [];

        let tipCycle = this._store.get("_quitting_pharmacotherapy_tip_cycle");
        let lastChanged;
        let notifTime;
        try {
            lastChanged = moment(tipCycle.last_changed);
            notifTime = moment(tipCycle.ntf_time);
        }
        catch(e) {
            lastChanged = null;
            notifTime = null;
        }

        const currentNotification = tipNotifications.filter(ntf => {
            const time = moment();
            const timeDiff = time.diff(moment(ntf.time), 'hours');

            return timeDiff >= 0 && timeDiff <= 24;
        });

        const daySinceChanged = lastChanged && moment().diff(lastChanged, 'hours') >= 24;
        const daySinceNotif = notifTime && moment().diff(notifTime, 'hours') >= 24;
        if (currentNotification.length > 0 && (daySinceChanged || daySinceNotif)) {
            const currentNtf = currentNotification[0];
            tipCycle = {
                tip: currentNtf.tip,
                ntf_time: currentNtf.time,
                last_changed: moment().toDate(),
            };
            this._store.set("_quitting_pharmacotherapy_tip_cycle", tipCycle);
        }
        else if (!tipCycle) {
            tipCycle = {
                tip: Math.floor(Math.random() * 12),
                ntf_time: null,
                last_changed: moment().toDate(),
            };
            this._store.set("_quitting_pharmacotherapy_tip_cycle", tipCycle);
        }
    }

    /**
     * Pobiera częstotliwość wysyłania danych powiadomień z
     * local storage.
     * Używana, jeżeli nie ma połączenia z internetem.
     *
     * @static
     * @param {*} m
     * @returns {number}
     * @memberof AppSettings
     */
    static getNotifFreq(m) {
        const appSettings = this._store.get("_app_settings");
        if (appSettings && appSettings.notif_freq) {
            return appSettings.notif_freq[m];
        }
        return null;
    }

    /**
     * Funkcja do event listenera na 'ondeviceready'.
     *
     * @static
     * @memberof AppSettings
     */
    static onDeviceReady() {
        try {
            // window.open = cordova.InAppBrowser.open;
            // $(document).on('click', function (e) {
            //     var $t = $(e.target);
            //     if ( $t.is('a') && $t.hasClass('external') ) {
            //         e.preventDefault();
            //         window.open($t.attr('href'), $t.hasClass('system-link') ? '_system' : '_blank', 'location=yes');
            //     }
            // });
        }
        catch(e) {
            console.log('onDeviceReady:');
            console.log(e);
        }
    }

    /**
     * Ustawia powiadomienia z czasomierza modułu abstynencji.
     * "20 minut od zapalenia papierosa..." itd.
     * Godziny wysyłania powiadomień obliczane są od papierosa
     * "0".
     *
     * @static
     * @param {string} cigZero czas papierosa "0"
     * @param {boolean} [dev=false] czy to wersja developerska
     * @memberof AppSettings
     */
    static setAbstinence1Notifications(cigZero, dev = false) {
        const cigZeroDate = moment(Number(cigZero));
        const secSinceCgiZero = moment().diff(cigZeroDate, 's');
        const abstinenceTimerTips = window.vue.$t('abstinenceTimerTips').abstinenceTimerTips;

        let notificationsStore = [];
        let i = 200;

        console.log("abstynencja 1")
        for (let [secThreshold, tip] of Object.entries(abstinenceTimerTips).reverse()) {
            if (secThreshold <= secSinceCgiZero) {
                break;
            }

            const cigZeroDateClone = moment(Number(cigZero));
            const time = cigZeroDateClone.add(Number(secThreshold), 'seconds');

            // console.log(time.toDate())

            if (!dev) {
                cordova.plugins.notification.local.schedule({
                    id: i,
                    title: window.vue.$t('abstinence.notification_title', {time: Util.getTimespanWord(secThreshold)}),
                    text: tip,
                    icon: Application.platform === 'iOS' ? 'res://icon' : 'res://ic_launcher',
                    smallIcon: Application.platform === 'iOS' ? 'res://icon' : 'res://ic_launcher2',
                    sound: null,
                    trigger: { at: time.toDate() },
                });
            }

            notificationsStore.push({
                id: i,
                time: time.toDate(),
                title: window.vue.$t('abstinence.notification_title', {time: Util.getTimespanWord(secThreshold)}),
                text: tip,
            });

            i++;
        }

        this._store.set('_s1_abstinence_notifications', notificationsStore);
    }

    /**
     * Ustawia powiadomienia z części powiadomień modułu abstynencji.
     * Domyślna godzina wysłania powiadomienia to 15:00, więc jeżeli
     * na serwerze ustawiono częstotliwość 6h to następne powiadomienie
     * będzie o 21:00 itd.
     *
     * @static
     * @param {*} setting częstotliwość powiadomień
     * @param {boolean} [dev=false] czy to wersja developerska
     * @memberof AppSettings
     */
    static setAbstinence2Notifications(setting, dev = false) {
        AppSettings.getCurrentAbstinenceTip(setting);

        let time;
        const defaultTime = moment().set({'hour': 15, 'minute': 0});
        const defaultTimeDiff = defaultTime.diff(moment(), 'h');
        const defaultTimeDiffDiv = Math.floor(defaultTimeDiff / setting);

        // Jeżeli np. domyslna godzina powiadomienia to 20, a uzyt. odpowiedzial na pytania o 19 (przy czestot. > 1 h),
        // powiadomienie ustawione zostanie na godzine 20 + czestotliwosc powiadomien w h.
        // Jeżeli w powyższej sytuacji odpowiedzial o 17, przt czestot = 2h to pierwsze powiadomienie zostanie ustawione na 18.
        if (defaultTimeDiffDiv > 0) {
            time = defaultTime.subtract(defaultTimeDiffDiv * setting, 'h');
        }
        else {
            time = defaultTime;
        }

        const q = setting < 6 ? 20 : 10;

        let notificationsStore = [];

        const abstinenceQuestionsTips = window.vue.$t('abstinenceQuestionsTips').abstinenceQuestionsTips;
        const abstinenceQuestionsTipsLength = abstinenceQuestionsTips.block1.content.length;

        let tipCycle = this._store.get("_s2_abstinence_questions_tip_cycle");
        let currentTip;
        try {
            currentTip = tipCycle.tip;
        }
        catch(e) {
            currentTip = 0;
        }

        console.log("abstynencja 2")
        for (let i = 1; i <= q; i++) {
            if (i > 1)
                time = time.add(setting, 'h');

            if (currentTip === abstinenceQuestionsTipsLength - 1) {
                currentTip = 0;
            }
            else {
                currentTip++;
            }

            // console.log(time.toDate())

            if (!dev) {
                cordova.plugins.notification.local.schedule({
                    id: 300 + i,
                    title: window.vue.$t('abstinence_questions.notification_title'),
                    text: abstinenceQuestionsTips.block1.content[currentTip],
                    icon: Application.platform === 'iOS' ? 'res://icon' : 'res://ic_launcher',
                    smallIcon: Application.platform === 'iOS' ? 'res://icon' : 'res://ic_launcher2',
                    sound: null,
                    trigger: { at: time.toDate() },
                });
            }

            notificationsStore.push({
                id: 300 + i,
                time: time.toDate(),
                tip: currentTip,
            });
        }

        this._store.set('_s2_abstinence_notifications', notificationsStore);
    }

    /**
     * Ustawia powiadomienia z modułu farmakoterapii.
     * Wysyłane zawsze o godzinie 17:00.
     *
     * @static
     * @param {boolean} [dev=false] czy to wersja developerska
     * @memberof AppSettings
     */
    static setBeforeQuittingNotifications(dev = false) {
        AppSettings.getCurrentPharmacotherapyTip();

        let time = moment().set({'hour': 17, 'minute': 0});
        if (time < moment()) {
            time = time.add(1, 'd');
        }

        let notificationsStore = [];
        const pharmacotherapyTips = window.vue.$t('quittingPharmacotherapyTips').quittingPharmacotherapyTips;

        console.log("farmakoterapia")
        for (let i = 1; i <= 10; i++) {
            if (i > 1)
                time = time.add(1, 'd');

            // console.log(time.toDate())

            const randTip = Math.floor(Math.random() * 12);

            if (!dev) {
                cordova.plugins.notification.local.schedule({
                    id: 100 + i,
                    title: window.vue.$t('quitting_pharmacotherapy.notification_title'),
                    text: pharmacotherapyTips.block1.content[randTip],
                    icon: Application.platform === 'iOS' ? 'res://icon' : 'res://ic_launcher',
                    smallIcon: Application.platform === 'iOS' ? 'res://icon' : 'res://ic_launcher2',
                    sound: null,
                    trigger: { at: time.toDate() },
                });
            }

            notificationsStore.push({
                id: 100 + i,
                time: time.toDate(),
                tip: randTip,
            });
        }

        this._store.set('_s4_pharmacotherapy_notifications', notificationsStore);
    }

    /**
     * Ustawia powiadomienia z części pytań modułu abstynencji.
     * Domyślna godzina wysłania powiadomienia to 20:00, więc jeżeli
     * na serwerze ustawiono częstotliwość 6h to następne powiadomienie
     * będzie o 02:00 itd.
     *
     * @static
     * @param {*} setting częstotliwość powiadomień
     * @param {boolean} [dev=false] czy to wersja developerska
     * @memberof AppSettings
     */
    static setQuittingNotifications(setting, dev = false) {
        let answeredAt;
        try {
            answeredAt = cache.get("_settings").questionsAnsweredAt.q_s3_quitting;
        }
        catch(e) {
            answeredAt = null;
        }

        let time;
        const defaultTime = moment().set({'hour': 20, 'minute': 0});
        const defaultTimeDiff = defaultTime.diff(moment(), 'h');
        const defaultTimeDiffDiv = Math.floor(defaultTimeDiff / setting);

        // Jeżeli np. domyslna godzina powiadomienia to 20, a uzyt. odpowiedzial na pytania o 19 (przy czestot. > 1 h),
        // powiadomienie ustawione zostanie na godzine 20 + czestotliwosc powiadomien w h.
        // Jeżeli w powyższej sytuacji odpowiedzial o 17, przt czestot = 2h to pierwsze powiadomienie zostanie ustawione na 18.
        if (defaultTimeDiffDiv > 0) {
            time = defaultTime.subtract(defaultTimeDiffDiv * setting, 'h');
        }
        else if (answeredAt && moment(answeredAt).add(setting, 'h') > defaultTime) {
            time = defaultTime.add(setting, 'h');
        }
        else {
            time = defaultTime;
        }

        const q = setting < 6 ? 20 : 10;

        let notificationsStore = [];

        console.log("pytania")
        for (let i = 1; i <= q; i++) {
            if (i > 1)
                time = time.add(setting, 'h');

            // console.log(time.toDate())

            if (!dev) {
                cordova.plugins.notification.local.schedule({
                    id: i,
                    title: window.vue.$t('quitting_questions.notification_title'),
                    text: window.vue.$t('quitting_questions.notification_text'),
                    icon: Application.platform === 'iOS' ? 'res://icon' : 'res://ic_launcher',
                    smallIcon: Application.platform === 'iOS' ? 'res://icon' : 'res://ic_launcher2',
                    sound: null,
                    trigger: { at: time.toDate() },
                    // trigger: { every: 'minute' },
                });
            }

            notificationsStore.push({
                id: i,
                time: time.toDate(),
            });
        }

        this._store.set('_s3_quitting_notifications', notificationsStore);
    }

    /**
     * Wywoływana przy każdym uruchomieniu aplikacji (jeżeli użytkownik
     * jest już zalogowany) lub pod zalogowaniu.
     *
     * Usuwa wszystkie powiadomienia i dodaje je na nowo. Powiadomienia
     * kolejkowane są na 10 lub 20.
     * Pobiera przy tym częstotliwości wysyłania powiadomień
     * z serwera.
     *
     * @static
     * @memberof AppSettings
     */
    static setNotifications() {
        console.log('Ustawianie powiadomień...');

        const self = this;
        const notifFreq = [this.getNotifFreq('NOTIF_FREQ_RZUCANIE_PALENIA_QUESTIONS'), this.getNotifFreq('NOTIF_FREQ_UTRZYMANIE_ABSTYNENCJI')];

        const setAll = (s, dev = false) => {
            // Powiadomienia z pytań dot. rzucania palenia.
            if (User.checkPermission('quitting')) {
                AppSettings.setQuittingNotifications(s[0], dev);
            }

            // Powiadomienia z farmakoterapii modułu rzucania palenia.
            const pharmacotherapyAnswers = self._store.get("_q_s4_beforeQuitting");

            // Weryfikacja, czy uzyt. ma farmakoterapie.
            if (pharmacotherapyAnswers && pharmacotherapyAnswers.score > 0) {
                AppSettings.setBeforeQuittingNotifications(dev);
            }

            // Powiadomienia z modułu abstynencji.
            const cigZero = self._f7.data.stats.getTimerZeroCigarette();

            if (User.checkPermission('abstinence') && cigZero) {
                AppSettings.setAbstinence1Notifications(cigZero, dev);
            }

            // Powiadomienia z modułu abstynencji - powiadomienia.
            if (User.checkPermission('abstinence')) {
                AppSettings.setAbstinence2Notifications(s[1], dev);
            }

            console.log('Powiadomienia ustawione.');
        }

        try {
            cordova.plugins.notification.local.cancelAll(function(r) {

                if (Application.checkConnection()) {
                    Promise.all([
                        AppSettings.get('NOTIF_FREQ_RZUCANIE_PALENIA_QUESTIONS'),
                        AppSettings.get('NOTIF_FREQ_UTRZYMANIE_ABSTYNENCJI')
                    ]).then(settings => {
                        console.log('Częstotliwości z serwera:');
                        console.log(settings);

                        setAll(settings);
                    });
                }
                else {
                    console.log('Częstotliwości z local storage:');
                    console.log(notifFreq);
                    setAll(notifFreq);
                }

            });
        }
        // @TODO: usunac z produkcji
        catch(e) {
            if (Application.checkConnection()) {
                Promise.all([
                    AppSettings.get('NOTIF_FREQ_RZUCANIE_PALENIA_QUESTIONS'),
                    AppSettings.get('NOTIF_FREQ_UTRZYMANIE_ABSTYNENCJI')
                ]).then(settings => {
                    setAll(settings, true);
                });
            }
            else {
                setAll(notifFreq, true);
            }
        }

    }

}
