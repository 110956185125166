<template>

<f7-panel left>
    <f7-view>
        <f7-page>
            <div id="left-nav-top">
                <div>
                    <img src="../assets/img/logo.png" id="left-nav-logo-img" />
                </div>
                <div id="slogan" v-html="templateHowTo"></div>
            </div>
            
            <div id="left-nav-middle">
                <f7-list>
                    <f7-list-item no-chevron @click="navigateTo('diagnosis')" :title="$t('leftNavPanel.diagnostyka')">
                        <f7-icon material="insert_comment" slot="media" class="nav-list-img"></f7-icon>
                    </f7-list-item>
                    <f7-list-item no-chevron @click="navigateTo('beforeQuitting')" :title="$t('leftNavPanel.przygotowanie')">
                        <f7-icon material="restore" slot="media" class="nav-list-img"></f7-icon>
                    </f7-list-item>
                    <f7-list-item no-chevron @click="navigateTo('quitting')" :title="$t('leftNavPanel.rzucanie')">
                        <f7-icon material="smoke_free" slot="media" class="nav-list-img"></f7-icon>
                    </f7-list-item>
                    <f7-list-item no-chevron @click="navigateTo('abstinence')" :title="$t('leftNavPanel.utrzymanie')">
                        <f7-icon material="thumb_up" slot="media" class="nav-list-img"></f7-icon>
                    </f7-list-item>
                    <f7-list-item no-chevron @click="navigateTo('success')" :title="$t('leftNavPanel.sukcesy')">
                        <img src="../assets/img/emoji_events-24px.svg" slot="media" class="nav-list-img" style="filter: invert(37%) sepia(44%) saturate(2594%) hue-rotate(352deg) brightness(95%) contrast(97%);" />
                    </f7-list-item>
                    <f7-list-item no-chevron @click="navigateTo('info')" :title="$t('leftNavPanel.info')">
                        <f7-icon material="info" slot="media" class="nav-list-img"></f7-icon>
                    </f7-list-item>
                    <f7-list-item no-chevron @click="navigateTo('rating', true)" :title="$t('leftNavPanel.ocena')">
                        <f7-icon material="control_point_duplicate" slot="media" class="nav-list-img"></f7-icon>
                    </f7-list-item>
                    <f7-list-item no-chevron @click="navigateTo('userPanel', true)" :title="$t('leftNavPanel.panel')">
                        <f7-icon f7="person_alt_circle" slot="media" class="nav-list-img"></f7-icon>
                    </f7-list-item>
                    <f7-list-item no-chevron @click="logout()" panel-toggle :title="$t('leftNavPanel.wyloguj')">
                            <f7-icon f7="power" slot="media" class="nav-list-img"></f7-icon>
                    </f7-list-item>
                </f7-list>
            </div>

            <div id="left-nav-bottom">
                <div>
                    <img src="../assets/img/tel-pomoc.png" id="bottom-help-img" />
                </div>
            </div>

            <div id="left-nav-credits">
                Created by <strong>MEDWAY</strong>
            </div>
        </f7-page>
    </f7-view>
</f7-panel>

</template>

<style lang="scss" scoped>
@media only screen and (max-width: 600px) {
    /deep/ .nav-list-img {
        font-size: 7vw;
        width: 7vw;
    }
    /deep/ .item-title {
        font-size: 3.5vw;
    }
    /deep/ .item-content {
        min-height: 7vh;
    }
}
@media only screen and (min-width: 601px) {
    /deep/ .nav-list-img {
        font-size: 5vw;
        width: 5vw;
    }
    /deep/ .item-title {
        font-size: 3.7vw;
    }
}

.page-current {
    font-family: TitilliuRegular;
    background-color: #fff !important;
}

.panel {
    width: 75vw !important;
}

// Top
div#left-nav-top {
    display: flex;
    flex-direction: column;
    padding: 7vw 7vw 0 7vw;
}

img#left-nav-logo-img {
    width: 45%;
    height: auto;
}

div#slogan /deep/ span:nth-child(2n) {
    color: rgb(238, 79, 22);
}

div#slogan /deep/ span:nth-child(2n - 1) {
    color: rgb(0, 33, 38);
}

div#slogan {
    padding: 2vh 0;
}

div#slogan /deep/ span {
    font-size: 6vw;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0 .25vw;
}

// Middle
div.list {
    margin: 0 !important;
}

div.list li {
    background-color: rgb(234, 235, 234);
    margin-bottom: .3vh;
}

/deep/ .item-content {
    margin-left: 6vw !important;
}

/deep/ .item-inner::after {
    display: none !important;
}

/deep/ .nav-list-img {
    height: auto;
    color: rgb(238, 79, 22);
}

// Bottom
img#bottom-help-img {
    width: 70%;
    height: auto;
    margin: 2vw 0 0 5vw;
}

#left-nav-credits {
    margin: 0 0 0 7vw;
}
</style>

<script>
import User from '../utils/User';

export default{
    data(){
        return{
            templateHowTo: this.$t("login.how_to"),
        }
    },
    methods: {
        navigateTo(view, route = false) {
            const app = this.$f7;
            const viewRequiresPermission = app.data.permissions.includes(view);

            if (viewRequiresPermission) {
                const hasPermission = this.verifyPermission(view);
                app.panel.close("left");
                if (!hasPermission) 
                    return;
            }

            if (!route) {
                app.tab.show('#view-' + view);
            }
            else {
                app.tab.show('#view-diagnosis');
                app.views.main.router.navigate(`/${view}/`);
            }
            app.panel.close("left");
        },
        logout() {
            User.logout();
            const app = this.$f7;
            app.views.main.router.navigate('/login/', {
                reloadAll: true,
            });
            app.tab.show('#view-diagnosis');
            app.panel.close("left");
        }
    },
    mounted() {
        this.$f7ready((f7) => {

        });
    },
}

</script>