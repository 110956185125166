export default {
    beforeQuittingStep4Tips: {
        block1: {
            title: "Farmakoterapia",
            content: [
                'Rzucając palenie możesz spodziewać się wystąpienia różnych zmian w swoim organizmie i swoim stanie emocjonalnym. Ich nasilenie może być bardzo różne ale można łagodzić je na wiele sposobów. <a class="link external system-link" href="http://jakrzucicpalenie.pl/objawy/">Więcej</a>',

                'Jeśli chcesz stosować środki farmakologiczne łagodzące objawy odstawienia, to ze względu na swój stan zdrowia powinieneś bezwzględnie skonsultować to z lekarzem. Możesz dowiedzieć się więcej w Telefonicznej Poradni Pomocy Palącym (<span class="call-phone-no" title="801108108"><i class="f7-icons phone-call-icon">phone</i>801 108 108</span> lub <span class="call-phone-no" title="222118015"><i class="f7-icons phone-call-icon">phone</i>22 211 80 15</span>) W przypadku niektórych leków istnieją przeciwwskazania medyczne do ich stosowania.',

                `
Świetny wybór. Obecnie dostępne bez recepty są trzy preparaty, które zawierają Cytyzynę: Tabex, Desmoxan oraz Recigar. Wszystkie mają takie samo działanie i sposób przyjmowania. Możesz wybrać  jeden z tych trzech leków.  

Ważne:
<ol>
    <li>Żeby odczuć efekt terapeutyczny trzeba przestrzegać dawkowania leku. Leczenie według producenta powinno trwać 25 dni.</li>
    <li>Pamiętaj, że pomimo przyjmowania leku możesz odczuwać chęć palenia w sytuacjach, w których zwykle paliłeś/łaś papierosy. </li>
    <li>Pamiętaj, że dodatkowo, gdy odczuwana jest silna potrzeba palenia  można wspomagać się stosowaniem środków z nikotyną.</li>
</ol> 
                `,

                `
Świetny wybór. Na rynku jest wiele dostępnych bez recepty środków z nikotyną. Zdecyduj teraz, którą formę Nikotynowej Terapii Zastępczej wybierzesz. <br />

<a class="link external system-link" href="https://jakrzucicpalenie.pl/metody-rzucania-palenia/">Szczegółowy opis środków</a><br />

Ważne:
<ol>
    <li>Pamiętaj, że nikotyna z preparatów działa wolniej niż w przypadku palenia. Jeśli wiesz, że będziesz czuć chęć palenia lub zaczynasz ją czuć – zastosuj któryś z preparatów. Przeczytaj dokładnie wskazówki dotyczące technik używania poszczególnych środków lub zadzwoń do Telefonicznej Poradni Pomocy Palącym.</li>
    <li>Pamiętaj, że pomimo przyjmowania leku możesz odczuwać chęć palenia w sytuacjach, w których zwykle paliłeś/łaś papierosy.</li>
    <li>Pamiętaj, że dodatkowo, gdy odczuwana jest silna potrzeba palenia  można wspomagać się stosowaniem innych środków z nikotyną (np. szybko działającym sprayem).</li>
</ol>
`,

`Świetny wybór. Zgłoś się do lekarza po receptę, tak by zacząć kurację na min. tydzień przed dniem rzucenia palenia.`,

`Świetny wybór. Zgłoś się do lekarza po receptę, tak by zacząć kurację na min. tydzień przed dniem rzucenia palenia.`,

`Możemy polecić wyłącznie środki, które zostały przebadane pod kątem bezpieczeństwa i skuteczności. Jeśli decydujesz się na stosowanie preparatu, o którym nie piszemy robisz to na własną odpowiedzialność.`,

`Pamiętaj, że terapeutyczne działanie niektórych leków odczuwane jest dopiero po kilku dniach kuracji. Wróć do wyboru leku min. na 5-7 dni przed dniem rzucenia palenia.`
            ]
        },
    }
}