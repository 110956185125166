import User from './User.js';

export default class StoreCache {
    constructor(prefix) {
        this.prefix = prefix ? prefix : 'onkologia';
        this.forAllUsers = ["_loginData", "lang"];
    }
    /**
     * Większość kluczy danych z local storage przyporządkowana
     * zostaje do konkretnego użytkownika przez dodanie
     * "_{id_obecnie_zalogowanego_uzytkownika}" na końcu klucza.
     * Wyjątkami są klucze z właściwości 'forAllUsers'.
     *
     * Dzięki temu na jednym telefonie logować mogą się
     * różni użytkownicy, bez utraty danych.
     *
     * @param {*} key
     * @returns
     * @memberof StoreCache
     */
    createFullKey(key) {
        return this.prefix + key + (this.forAllUsers.includes(key) ? "" : `_${User.id}`);
    }
    checkUser(key) {
        if (!this.forAllUsers.includes(key) && !User.id) {
            console.error("Błąd localStorage: brak id użytkownika.", key);
            return false;
        }
        return true;
    }
    get(key){
        if (!this.checkUser(key)) return;
        return JSON.parse(localStorage.getItem(this.createFullKey(key)))
    }
    set(key, value) {
        if (!this.checkUser(key)) return;
        localStorage.setItem(this.createFullKey(key), JSON.stringify(value))
    }
    remove(key) {
        if (!this.checkUser(key)) return;
        localStorage.removeItem(this.createFullKey(key))
    }
    clear() {
        localStorage.clear()
    }
}
