import questions from '../../assets/diagnosis/questions';
import diagnosisTips from '../../assets/diagnosis/diagnosisTips';
import journalTips from '../../assets/beforeQuitting/journalTips';
import beforeQuittingStep2Questions from '../../assets/beforeQuitting/step2Questions';
import beforeQuittingStep2Tips from '../../assets/beforeQuitting/step2Tips';
import beforeQuittingStep3Questions from '../../assets/beforeQuitting/step3Questions';
import beforeQuittingStep3Tips from '../../assets/beforeQuitting/step3Tips';
import beforeQuittingStep4Questions from '../../assets/beforeQuitting/step4Questions';
import beforeQuittingStep4Tips from '../../assets/beforeQuitting/step4Tips';
import beforeQuittingStep4Content from '../../assets/beforeQuitting/step4Content';
import quittingQuestionsQuestions from '../../assets/quitting/questionsQuestions';
import quittingQuestionsTips from '../../assets/quitting/questionsTips';
import quittingPharmacotherapyTips from '../../assets/quitting/pharmacotherapyTips';
import quitting12WeekQuestion from '../../assets/quitting/12WeekQuestion';
import quitting12WeekTips from '../../assets/quitting/12WeekTips';
import abstinenceTimerTips from '../../assets/abstinence/timerTips';
import abstinenceQuestionsTips from '../../assets/abstinence/questionTips';
import infoContent from '../../assets/info/infoContent';
import ratingQuestions from '../../assets/rating/questions';

export default {
    app: {
        z: 'z',
        wstecz: 'Wstecz',
        tak: 'Tak.',
        nie: 'Nie.',
        cancel: 'Anuluj',
        save: 'Zapisz',
        start: 'Rozpocznij',
        answer_analysis: 'Analiza odpowiedzi...',
        loading: 'Ładuję...',
        next_module: 'Następny moduł',
        daysOfWeek: [
            "niedziela",
            "poniedziałek",
            "wtorek",
            "środa",
            "czwartek",
            "piątek",
            "sobota",
        ],
        timeSpans: {
            minutes: 'minuty',
            minutes2: 'minut',
            hours: 'godziny',
            hours2: 'godzin',
            '24h': 'dobę',
            day: 'dzień',
            days: 'dni',
            week: 'tydzień'
        },
        no_internet: 'Brak połączenia z internetem. Nie można wyświetlić zawartości strony.',
    },
    permissions: {
        names: {
            'beforeQuitting': 'etapu przygotowania do rzucenia palenia',
            'quitting': 'etapu rzucania palenia',
            'abstinence': 'etapu utrzymania abstynencji',
            'info': 'informacji',
            'success': 'sukcesów',
        },
        no_permission_title: 'Brak dostępu.',
        no_permission_txt: 'Nie posiadasz jeszcze uprawnień do tego modułu.',
        permission_granted_title: 'Przyznano dostęp',
        permission_granted_txt1: 'Uzyskałeś/łaś dostęp do modułu ',
        permission_granted_txt2: '.',
        permission_granted_go_to_module: 'Przejdź do modułu',
        permissions_granted_title: 'Przyznano dostęp',
        permissions_granted_txt: 'Uzyskałeś/łaś dostęp do modułów {modules}.',
    },
    tips: {
        none: 'Brak wskazówek w tym module.'
    },
    register:{
        button: 'Zarejestruj się',
    },
    login: {
        log_in: 'Zaloguj się',
        register: 'Zarejestruj się',
        info: 'Informacje',
        how_to: '<span>Jak</span> <span>rzucić</span> <span>palenie</span>',
        forgot: 'Zapomniałem hasła',
        login: 'Login',
        password: 'Hasło',
        your_login: 'Twój login',
        your_password: 'Twoje hasło',
        email: 'Adres e-mail',
        email_second: 'Powtórz adres e-mail',
        password_second: 'Powtórz hasło',
        your_password_second: 'Powtórz hasło',
        your_email: 'Twój e-mail',
        your_email_second: 'Powtórz e-mail',
        failure: 'Logowanie nie powiodło się.',
        failure_unknown: 'Wystąpił błąd.',
        failure_nointernet_title: 'Brak połączenia z internetem. ',
        failure_nointernet_msg: 'Nie można zalogować.',
        send_activation: 'Wyślij ponownie',
        activation_sent: 'Link aktywacyjny został wysłany',
        activation_sent_msg: 'Sprawdź swoją skrzynkę e-mail.',
        activation_error: 'Wystąpił błąd',
    },
    registery:{
        registery: 'Zarejestruj się',
        cancel: 'Anuluj',
        login_length: 'Login musi mieć co najmniej 4 znaki',
        password_length: 'Hasło musi składać się z co najmniej 8 znaków',
        other_password: 'Podane hasła są różne',
        email_valid: 'Podany e-mail jest nieprawidłowy',
        other_email: 'Podane adres e-mial są różne',
        error_form_title: 'Błąd walidacji',
        error_form_valid: 'Uzupełnij wszystkie pola poprawnie!',
        success_form_valid: 'Gratulacje! <br>Twoje konto zostało utworzone.<br>Sprawdź swoją skrzynkę pocztową',
        success_form_title: 'Konto zostało utworzone!',
        error_registery_account: 'Błąd podczas tworzenia konta',
        failure_nointernet_title: 'Brak połączenia z internetem. ',
        failure_nointernet_msg: 'Nie można zarejestrować nowego użytkownika.',
    },
    resetPassword: {
        email: 'Adres e-mail',
        your_email: 'Twój adres e-mail',
        resetPassword: 'Wyślij nowe hasło',
        failure: 'Resetownie hasła nie powiodło się.',
        failure_unknown: 'Wystąpił błąd.',
        failure_nointernet_title: 'Brak połączenia z internetem. ',
        failure_nointernet_msg: 'Nie można zresetować hasła.',
        success: 'Pomyślnie zresetowano hasło',
        success_text: 'Sprawdź swoją skrzynkę pocztową',
    },
    leftNavPanel: {
        diagnostyka: 'Diagnostyka',
        przygotowanie: 'Przygotowanie',
        rzucanie: 'Rzucanie palenia',
        utrzymanie: 'Utrzymanie',
        sukcesy: 'Sukcesy',
        info: 'Informacje o paleniu',
        ocena: 'Ocena aplikacji',
        panel: 'Panel użytkownika',
        wyloguj: 'Wyloguj',
    },
    diagnosis:{
        title: 'Diagnostyka',
        textHeader: 'Odpowiedz na poniższe pytania aby zbadać głebokość Twojego uzależnienia, bez tego nie jesteśmy w stanie określić dokładnej terapii leczenia.',
        readTips: 'Poniżej znajdują się wskazówki opracowane na podstawie udzielonych odpowiedzi.',
        youSmoke: 'Czy palisz?',
        didYouSmoke: 'Czy paliłeś?',
        tak_rzucilem: 'Tak, rzuciłem.',
        youWantStopSmoking: 'Czy chcesz rzucić?',
        whenStopSmoke: 'Kiedy chcesz rzucić?',
        nearMounth: 'W przeciągu najbliższego miesiąca.',
        nowStopSmoking: 'Dzisiaj/jutro.',
        // Wyniki
        your_score: 'Twój wynik',
        score_meaning: 'Twoja diagnoza',
        pkt: 'pkt',
    },
    diagnosisTips,
    journalTips,
    questions,
    beforeQuitting:{
        title: 'Przygotowanie',
        step_1: 'Krok I',
        step_2: 'Krok II',
        step_3: 'Krok III',
        step_4: 'Krok IV',
        next_step: 'Następny krok',
        tips: 'Wskazówki',
        // Krok I
        journal: 'Dziennik kontrolowanego palenia',
        journal_why: 'W jakim celu prowadzić dziennik?',
        journal_intro: 'Podczas rzucania palenia ważne jest żebyś wiedział/ła kiedy i w jakich sytuacjach odczuwasz chęć palenia dlatego przez 1 {group_time} wprowadzaj informacje o każdym zapalonym papierosie. Po tym czasie sprawdź podsumowanie, które dla Ciebie przygotujemy.',
        journal_intro_started: 'Dziennik został rozpoczęty. Przez 1 {group_time} wprowadzaj informacje o każdym zapalonym papierosie. Po tym czasie sprawdź podsumowanie, które dla Ciebie przygotujemy.',
        journal_results: 'Dziennik kontrolowanego palenia - podsumowanie',
        journal_time_till_end: 'Czas do końca',
        journal_timer_days: 'Dni:',
        journal_timer_hours: 'Godziny:',
        journal_timer_minutes: 'Minuty:',
        journal_timer_seconds: 'Sekundy:',
        journal_input: 'Wprowadź {opt}sytuację w której zapaliłeś(aś) ostatniego papierosa.',
        journal_time: 'Godzina',
        journal_situation: 'Sytuacja',
        error_time_before_journal: 'Nie można zapisać papierosa sprzed godziny rozpoczęcia dziennika.',
        error_time_after_now: 'Nie można zapisać nie spalonego jeszcze papierosa.',
        error_empty_time: 'Pole "Godzina" nie może pozostać puste.',
        error_invalid_situation: 'Pole "Sytuacja" nie może pozostać puste.',
        error_not_enough_data: 'Brak wystarczającej ilości danych do określenia wskazówek.',
        journal_input_confirm_title: 'Potwierdzenie zapisu',
        journal_input_confirm_content: 'Czy na pewno chcesz zapisać zapalonego papierosa?',
        situations: [
            "po obudzeniu",
            "przed snem",
            "przy kawie",
            "po posiłku",
            "przerwa w zajęciach",
            "na spacerze",
            "po wyjściu z domu",
            "towarzysko",
            "chwila dla siebie",
            "stres",
            "radość",
            "żeby się skupić",
            "z nudów"
        ],
        // Krok II
        step2_title: 'Praca z przekonaniami',
        step2_intro: 'Odpowiedz na poniższe pytania w celu oceny Twoich przekonań dotyczących palenia papierosów.',
        // Krok III
        step3_title: 'Praca nad motywacją',
        step3_intro: 'Odpowiedz na poniższe pytania w celu personalizacji wskazówek dotyczących motywacji w rzucaniu palenia.',
        // Krok IV
        step4_title: 'Zaplanowanie farmakoterapii',
    },
    beforeQuittingStep2Questions,
    beforeQuittingStep2Tips,
    beforeQuittingStep3Questions,
    beforeQuittingStep3Tips,
    beforeQuittingStep4Questions,
    beforeQuittingStep4Tips,
    beforeQuittingStep4Content,
    info:{
        title: 'Informacje',
        header: 'Moduł informacyjny',
        info_block1: 'Szkodliwość palenia',
        info_block2: 'Jak reaguje organizm na rzucenie palenia?',
        info_block3: 'Gdzie szukać pomocy?',
        back: 'Wróć',
    },
    infoContent,
    quitting:{
        title: 'Rzucanie palenia',
        '12week_intro': 'Minęło już ponad 12 tygodni od godziny "0"! Prosimy o odpowiedź na poniższe pytanie dotyczące Twojej abstynencji.',
    },
    quitting_timer:{
        how_long: 'Jak długo trwa abstynencja:',
        number_of_days: 'Liczba dni:',
        number_of_hours: 'Liczba godzin:',
        number_of_minutes: 'Liczba minut:',
        number_of_seconds: 'Liczba sekund:',
        health_benefits: 'Jakie korzyści już osiągnąłeś/łaś?',
        health_benefits_text: 'Udało Ci się odstawić papierosy! Każda minuta i godzina bez palenia przybliża Cię do osiągnięcia celu! Obserwując jak rośnie licznik możesz mieć pewność, że systematycznie poprawia się Twoje zdrowie! <br /><br />Uwaga! Jeśli zapalisz papierosa wzrasta ryzyko nawrotu uzależnienia! Nie poddawaj się - zresetuj licznik i kontynuuj rzucanie palenia!',
        reset: 'Zapaliłem/łam papierosa',
        reset_btn: 'RESET',
    },
    quitting_calculator:{
        calculator: 'Kalkulator oszczędności',
        calculate: 'Oblicz',
        calculate_again: 'Oblicz ponownie',
        reset: 'Resetuj',
        last_cig_short: 'Ostatni papieros',
        last_cig_full: 'Data i godzina ostatniego zapalonego papierosa',
        daily_cigs: 'Liczba wypalanych dziennie papierosów',
        cigs_price: 'Cena paczki papierosów',
        cigs_quantity: 'Liczba papierosów w paczce',
        error_invalid_date: 'Podana data jest nieprawidłowa.',
        error_invalid_value: 'Podana wartość jest nieprawidłowa.',
        your_savings: 'Gratulacje! <br />Twoje dotychczasowe oszczędności wynoszą:',
        pln: 'zł',
        error_cant_calc: 'Błąd w formularzu',
        error_fields_empty: 'W formularzu należy uzupełnić wszystkie pola.',
        error_fields_invalid: 'Pola formularza zostały niepoprawnie wypełnione.',
        error_fields_invalid_and_empty: 'W formularzu znajdują się puste i niepoprawnie wypełnione pola.',
    },
    quitting_questions:{
        header: 'Pytania',
        notification_title: 'Etap rzucania palenia',
        notification_text: 'Prosimy o udzielenie odpowiedzi na pytania dotyczące Twoich starań w rzuceniu palenia.',
    },
    quittingQuestionsQuestions,
    quittingQuestionsTips,
    quittingPharmacotherapyTips,
    quitting12WeekQuestion,
    quitting12WeekTips,
    quitting_pharmacotherapy: {
        header: 'Farmakoterapia',
        notification_title: 'Farmakoterapia',
        // notification_text: 'W systemie czeka na Ciebie wiadomość dotycząca farmakoterapii.',
    },
    abstinenceTimerTips,
    abstinenceQuestionsTips,
    abstinence: {
        title: 'Utrzymanie abstynencji',
        already_passed: 'Czas od ostatniego papierosa to już ponad ',
        timer_began: 'Odliczanie czasu od ostatniego papierosa zostało rozpoczęte!',
        notification_title: '{time} od ostatniego papierosa',
    },
    abstinence_questions:{
        header: 'Powiadomienia',
        notification_title: 'Utrzymanie abstynencji',
    },
    success: {
        title: 'Sukcesy',
        ranking_header: 'Ranking użytkowników',
        table_position: 'Miejsce',
        table_login: 'Nazwa użytkownika',
        table_time_since: 'Czas abstynencji',
        table_days: 'dni',
        table_hours: 'godziny',
        table_minutes: 'minuty',
        ranking_user_info: 'Znajdujesz się poza pierwszą dzisiątką w rankingu. Twoja obecna pozycja to <span class="position-highlight">{position}</span>!',
    },
    ratingQuestions,
    rating: {
        no_internet: 'Brak połączenia z internetem. Nie można wysłać oceny aplikacji.',
        submit: 'Wyślij ocenę',
        intro: 'Prosimy o odpowiedź na poniższe pytania:',
        submit_confirm_title: 'Potwierdzenie wysłania oceny aplikacji',
        submit_confirm_txt: 'Czy na pewno chcesz wysłać wprowadzone odpowiedzi?',
        submission: 'Wysyłanie oceny...',
        submitted_title: 'Dziękujemy!',
        submitted_txt: 'Twoja ocena została wysłana.',
    },
    userPanel:{
        email: 'Adres e-mail: ',
        registered: 'Data rejestracji: ',
        change_password: 'Zmień hasło',
        cancel_change_password: 'Anuluj zmianę hasła',
        current_password: 'Obecne hasło',
        new_password: 'Nowe hasło',
        confirm_new_password: 'Powtórz nowe hasło',
        error_wrong_password: 'Podane hasło jest nieprawidłowe',
        error_invalid_password: 'Hasło musi się składać z co najmniej 8 znaków.',
        error_wrong_password_confirm: 'Podane hasło różni się od nowego hasła.',
        password_change_confirm_title: 'Potwierdzenie zmiany hasła',
        password_change_confirm_content: 'Czy na pewno chcesz zmienić hasło?',
        password_change_failure: 'Wystąpił błąd podczas zmiany hasła',
        password_change_failure_unknown: 'Nie udało się znaleźć przyczyny błędu.',
        password_change_success: 'Proces zmiany hasła został rozpoczęty.',
    },
    questionsComponent: {
        error_not_all_answered: 'Należy udzielić odpowiedzi na powyższe pytanie.',
    },
    zero_cigarette: {
        intro: 'Podjąłeś/łaś decyzję o rzuceniu palenia. Po wypaleniu ostatniego papierosa wprowadź godzinę. To Twoja godzina "0".',
        zero_cig_input_confirm_title: 'Potwierdzenie zapisu',
        zero_cig_input_confirm_content: 'Czy na pewno chcesz zapisać zapalonego papierosa?',
        zero_cig_reset_confirm_title: 'Potwierdzenie resetu czasomierza',
        zero_cig_reset_confirm_content: 'Czy na pewno chcesz zapisać zapalonego papierosa i tym samym zresetować czasomierz?',
    }
}