<template>
    <div class="main-container">
        <f7-block>
            <f7-row>
                <f7-col>
                    <div class="header-block">
                        <div>
                            <f7-icon material="question_answer"></f7-icon>
                        </div>
                        <div>
                            <h3>
                                {{$t('abstinence_questions.header')}}
                            </h3>
                        </div>
                    </div>
                </f7-col>
            </f7-row>
        </f7-block>

        <div class="content-container">
            <f7-block>
                <!-- Wskazówki - jeżeli pytania zostały zapisane -->
                <div class="tips-container">
                    <tips-component :tips="tips"></tips-component>
                </div>
            </f7-block>
        </div>
    </div>

</template>

<script>
    import StoreCache from '../../utils/storeCache.js';
    import TipsComponent from '../../components/tips';
    import * as moment from 'moment';

    export default {
        name: "abstinence-questions",
        data(){
            return {
            }
        },
        computed: {
            tips: function() {
                let tips = [];
                const cache = new StoreCache();

                let tipCycle = cache.get("_s2_abstinence_questions_tip_cycle") || { tip: 0 };

                // Wskazówka 1.
                let tip1 = { title: this.pharmacotherapyTips.block1.title };
                tip1.content = [this.pharmacotherapyTips.block1.content[tipCycle.tip]];
                tips.push(tip1);

                return tips;
            }
        },
        components: {
            TipsComponent,
        },
        methods:{
            
        },
        created() {
            this.pharmacotherapyTips = this.$t('abstinenceQuestionsTips').abstinenceQuestionsTips;
        },
        mounted() {
            this.$f7ready(f7 => {
            });
        }

    }
</script>

<style lang="scss" scoped>
    .main-container {
        display: flex;
        flex-direction: column;
        min-height: 80vh;
    }

    .content-container {
        background-color: #efeff4;
        flex: 1;
    }
</style>