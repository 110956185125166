<template>
    <div>

        <div id="top-timers">
            <div id="how-long-block">
                <div class="header-block">
                    <div>
                        <f7-icon material="timer" class="header-icon"></f7-icon>
                    </div>
                    <div>
                        <h3>
                            {{$t('quitting_timer.how_long')}}
                        </h3>
                    </div>
                </div>
            </div>
            <div id="timer-blocks">
                <div class="timer-block" id="timer-block-days">
                    <div class="timer-block-inside">
                        <span class="timer-block-icon">
                            <f7-icon material="timer"></f7-icon>
                        </span>
                        <span class="timer-block-txt">
                            {{$t('quitting_timer.number_of_days')}}
                        </span>
                        <div class="timer-block-counter">
                            {{$f7.data.timeSinceLastCigarette ? $f7.data.timeSinceLastCigarette.days : 0}}
                        </div>
                    </div>
                </div>
                <div class="timer-block" id="timer-block-hours">
                    <div class="timer-block-inside">
                        <span class="timer-block-icon">
                            <f7-icon material="timer"></f7-icon>
                        </span>
                        <span class="timer-block-txt">
                            {{$t('quitting_timer.number_of_hours')}}
                        </span>
                        <div class="timer-block-counter">
                            {{$f7.data.timeSinceLastCigarette ? $f7.data.timeSinceLastCigarette.hours : 0}}
                        </div>
                    </div>
                </div>
                <div class="timer-block" id="timer-block-minutes">
                    <div class="timer-block-inside">
                        <span class="timer-block-icon">
                            <f7-icon material="timer"></f7-icon>
                        </span>
                        <span class="timer-block-txt">
                            {{$t('quitting_timer.number_of_minutes')}}
                        </span>
                        <div class="timer-block-counter">
                            {{$f7.data.timeSinceLastCigarette ? $f7.data.timeSinceLastCigarette.minutes : 0}}
                        </div>
                    </div>
                </div>
                <div class="timer-block" id="timer-block-seconds">
                    <div class="timer-block-inside">
                        <span class="timer-block-icon">
                            <f7-icon material="timer"></f7-icon>
                        </span>
                        <span class="timer-block-txt">
                            {{$t('quitting_timer.number_of_seconds')}}
                        </span>
                        <div class="timer-block-counter">
                            {{$f7.data.timeSinceLastCigarette ? $f7.data.timeSinceLastCigarette.seconds : 0}}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="content-container">
            
            <f7-block id="prep-content-block">
                <div>
                    <h2 id="work">
                        {{$t('quitting_timer.health_benefits')}}
                    </h2>
                </div>
                <div id="txt-content" v-html="$t('quitting_timer.health_benefits_text')">
                </div>

                <div class="reset-btn-container" @click="resetTimer">
                    <div class="reset-btn">
                        <f7-icon f7="arrow_counterclockwise_circle"></f7-icon>
                        {{$t('quitting_timer.reset_btn')}}
                    </div>
                    <div class="reset-txt">
                        {{$t('quitting_timer.reset')}}
                    </div>
                </div>
            </f7-block>
        </div>

    </div>
</template>

<script>
    import StoreCache from '../../utils/storeCache.js';

    export default {
        name: "quitting-timer",
        data(){
            return {
            }
        },
        methods:{
            resetTimer() {
                this.$f7.dialog.create({
                    title: this.$t('zero_cigarette.zero_cig_reset_confirm_title'),
                    text: this.$t('zero_cigarette.zero_cig_reset_confirm_content'),
                    buttons: [
                        {
                            text: this.$t('app.cancel'),
                            onClick: (d) => { d.close(); }
                        },
                        {
                            text: "OK",
                            onClick: (d) => {
                                this.resetCigaretteZero();
                                d.close();
                            }
                        },
                    ],
                }).open();
            }
        },
        mounted() {
            this.$f7ready(f7 => {
            });
        }

    }
</script>

<style scoped>
    @media only screen and (max-width: 600px) {
        #top-timers {
            padding-bottom: 15vh;
        }
        #timer-blocks {
            margin: 0 8vw;
            width: 92vw;
        }
        .timer-block {
            width: 42vw;
            height: 135px;
        }
        .timer-block-inside {
            padding: 5vw;
        }
        .timer-block-txt {
            font-size: 13px;
        }
        .timer-block-icon i {
            margin-right: 2vw;
            font-size: 4.8vw;
        }
        .timer-block-counter {
            font-size: 60px;
        }
        .content-container {
            padding: 190px 3vw 3vw 3vw;
            min-height: 40vh;
        }
        h2#work {
            font-size: 3.5vw;
        }
        .reset-btn {
            font-size: 20px;
        }
        .reset-btn i {
            font-size: 50px;
        }
        .reset-txt {
            font-size: 20px;
        }
    }
    @media only screen and (min-width: 601px) {
        #top-timers {
            padding-bottom: 8vh;
        }
        #timer-blocks {
            margin: 0 4vw;
            width: 96vw;
        }
        .timer-block {
            width: 22vw;
        }
        .timer-block-inside {
            padding: 2.5vw;
        }
        .timer-block-txt {
            font-size: 2vw;
        }
        .timer-block-icon i {
            margin-right: 0.5vw;
            font-size: 3vw;
        }
        .timer-block-counter {
            font-size: 9vw;
        }
        h2#work {
            font-size: 3.5vw;
        }
        .content-container {
            padding: 100px 3vw 3vw 3vw;
            min-height: 55vh;
        }
        #txt-content {
            font-size: 3vw;
        }
        .reset-btn {
            font-size: 30px;
        }
        .reset-btn i {
            font-size: 70px;
        }
        .reset-txt {
            font-size: 30px;
        }
    }

    .content-container {
        background-color: #efeff4;
    }
    
    /* Czasomierz */
    #how-long-block {
        color: #fff;
        margin: 5vh 8vw 2.5vh 8vw;
    }

    #timer-blocks {
        display: flex;
        flex-wrap: wrap;
        position: absolute;
    }
    .timer-block {
        margin: 0.5vw;
        border-radius: 15px;
        background-color: rgb(34, 35, 36);
        box-shadow: 0 0 7px 0px #000;
    }
    .timer-block-inside > span {
        color: #fff;
        font-weight: bold;
    }
    .timer-block-inside {
        white-space: nowrap;
    }
    .timer-block-counter {
        font-weight: bold;
        color: #ee562b;
    }

    #prep-content-block {
        display: flex;
        margin: 0 !important;
        flex-direction: column;
        min-height: 40vh;
    }
    h2#work {
        font-weight: bold;
    }

    .reset-btn-container {
        background-color: #fff;
        padding: 10px;
        border-radius: 10px;
        margin: 20px 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .reset-btn {
        color: #ee562b;
        font-family: 'TitilliuBold';
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
    }
    .reset-txt {
        text-align: center;
        color: #ee562b;
        padding: 10px;
        font-weight: bold;
    }
</style>