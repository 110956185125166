export default {
    beforeQuittingStep3Tips: {
        block1: {
            title: "Zaprzestanie palenia",
            content: [
                "Masz powody do rzucenia palenia. Pomyśl co jeszcze zmieni się w Twoim życiu? Czego będzie więcej, a czego mniej (np. więcej spokoju o zdrowie i mniej powodów do sprzeczek z bliskimi)? Staraj się przynajmniej raz dziennie nad tym zastanowić. Podczas rzucania palenia zwracaj uwagę na każdy, najmniejszy nawet sukces!",

                "Jeszcze nie określiłeś/łaś dlaczego chcesz rzucić palenie. Kiedy będziesz mieć trochę czasu dla siebie pomyśl co zmieni się w Twoim życiu jeśli przestaniesz palić? Jak to wpłynie na  Twoje samopoczucie? Jak to wpłynie na Twoje zdrowie? Jak to wpłynie na Twoje relacje w rodzinie, w pracy, wśród znajomych? Czy zaoszczędzisz pieniądze i będziesz mógł/ła przeznaczyć je na realizację jakiś swoich planów? Czego będzie więcej, a czego mniej po rzuceniu palenia? Staraj się przynajmniej raz dziennie nad tym zastanowić. Podczas rzucania palenia zwracaj uwagę na każdy, najmniejszy nawet sukces!",
            ]
        },

        block2: {
            title: "Radzenie z potrzebą palenia",
            content: [
                "Postaraj się przypomnieć sobie sytuację, w której nie zrobiłeś/łaś czegoś na co miałeś/łaś ochotę. Wykorzystałeś/łaś wtedy jakieś swoje umiejętności i cechy charakteru, które pozwoliły Ci się powstrzymać. To świadczy o tym, że umiesz panować nad swoimi potrzebami i będziesz mógł/mogła te umiejętności wykorzystać rzucając palenie.",

                "Być może nie masz jeszcze dobrych doświadczeń z rzucaniem palenia. Postaraj się więc przypomnieć sobie sytuację, w której nie zrobiłeś/łaś czegoś na co miałeś/łaś ochotę. Wykorzystałeś/łaś wtedy jakieś swoje umiejętności i cechy charakteru, które pozwoliły Ci się powstrzymać. To świadczy o tym, że umiesz panować nad swoimi potrzebami i będziesz mógł/mogła te umiejętności wykorzystać rzucając palenie.",

                "Świetnie. Potrafisz na jakiś czas powstrzymać się od palenia, czyli masz jakieś umiejętności i cechy charakteru, które pozwalają Ci opóźnić zapalenie papierosa. Zastanów się jak to robisz? W jaki sposób udaje Ci się opóźnić zapalenie papierosa? Czy możesz te sposoby wykorzystać w innych sytuacjach?",

                "To świadczy o tym, że umiesz panować nad swoimi potrzebami i będziesz mógł/mogła te umiejętności wykorzystać rzucając palenie.",
            ]
        },
    }
}