import StoreCache from './storeCache.js';

/**
 * Wrapper dla danych autoryzacyjnych z local
 * storage.
 *
 * @class Auth
 */
export default class Auth {

    static createBearerHeader() {
        return (Auth.token && { Authorization: "Bearer " + Auth.token }) || null;
    }
    
    static get token() {
        return (new StoreCache).get("_loginData") ? (new StoreCache).get("_loginData").token : null;
    }

    static get uid() {
        return (new StoreCache).get("_loginData") ? (new StoreCache).get("_loginData").uid : null;
    }

    static get username() {
        return (new StoreCache).get("_loginData") ? (new StoreCache).get("_loginData").username : null;
    }

    static get email() {
        return (new StoreCache).get("_loginData") ? (new StoreCache).get("_loginData").email : null;
    }

    static get registered() {
        return (new StoreCache).get("_loginData") ? (new StoreCache).get("_loginData").registered : null;
    }

}