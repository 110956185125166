<template>
    <f7-page no-toolbar name="info-loggedout">
        <navbar-component hair-lines>
            <f7-link href="/login/" icon-f7="arrow_left"></f7-link>
            <template v-slot:title>{{$t('info.title')}}</template>
        </navbar-component>

        <f7-block id="top-block">
            <div id="header-block">
                <span id="header-icon">
                        <f7-icon material="info" slot="media" class="nav-list-img"></f7-icon>
                </span>
                <span id="header-title">
                    {{$t('info.header')}}
                </span>
            </div>

        </f7-block>

        <div class="content-container">
            <!-- Info cards -->
            <f7-block id="prep-content-block" v-if="!currentInfo">
                <div class="card info-block" @click="showInfo(1)">
                    <span class="info-block-icon">
                        <f7-icon material="info" slot="media" class="nav-list-img"></f7-icon>
                    </span>
                    <div class="info-block-content">
                        {{$t('info.info_block1')}}
                    </div>
                </div>
                <div class="card info-block" @click="showInfo(2)">
                    <span class="info-block-icon">
                        <f7-icon material="info" slot="media" class="nav-list-img"></f7-icon>
                    </span>
                    <div class="info-block-content">
                        {{$t('info.info_block2')}}
                    </div>
                </div>
                <div class="card info-block" @click="showInfo(3)">
                    <span class="info-block-icon">
                        <f7-icon material="info" slot="media" class="nav-list-img"></f7-icon>
                    </span>
                    <div class="info-block-content">
                        {{$t('info.info_block3')}}
                    </div>
                </div>
            </f7-block>

            <f7-block id="prep-content-block" v-else>
                <info-component :content="content[0]" v-if="currentInfo === 1"></info-component>
                <info-component :content="content[1]" v-if="currentInfo === 2"></info-component>
                <info-component :content="content[2]" v-if="currentInfo === 3"></info-component>
                        
                <div id="back-btn">
                    <button @click="showInfo(null)">
                        <div>
                            <div>
                                <f7-icon f7="chevron_left"></f7-icon>
                            </div>
                            <div>
                                {{$t('info.back')}}
                            </div>
                        </div>
                    </button>
                </div>

            </f7-block>
        </div>

    </f7-page>
</template>

<script>
    import NavbarComponent from '../components/navbar.vue';
    import InfoComponent from '../components/info-component';

    export default {
        name: "info-loggedout",
        data(){
            return{
                currentInfo: null,
                content: null,
            }
        },
        components: {
            NavbarComponent,
            InfoComponent
        },
        methods:{
            showInfo(infoNo) {
                this.currentInfo = infoNo;
            }
        },
        created() {
            this.content = this.$t('infoContent').infoContent
        },
        mounted() {
            this.$f7ready(f7=>{
            })
        }
    }
</script>

<style lang="scss" scoped>

    @media only screen and (max-width: 600px) {
        #header-icon i {
            font-size: 5.5vw;
        }
        #header-title {
            font-size: 5.5vw;
            margin-left: 2vw;
        }
        .info-block-icon i {
            font-size: 40px;
        }
        .info-block-content {
            font-size: 25px;
        }
        div#back-btn > button {
            font-size: 3.5vw;
        }
        div#back-btn > button i {
            font-size: 4vw;
        }
    }
    @media only screen and (min-width: 601px) {
        #header-icon i {
            font-size: 4.5vw;
        }
        #header-title {
            font-size: 4vw;
            margin-left: 2vw;
        }
        h2#work {
            font-size: 4.5vw;
        }
        div#prep-paragraphs {
            font-size: 3vw;
        }
        .info-block-icon i {
            font-size: 60px;
        }
        .info-block-content {
            font-size: 40px;
        }
        div#back-btn {
            margin-bottom: 50px;
        }
        div#back-btn > button {
            font-size: 3vw;
        }
        div#back-btn > button i {
            font-size: 3vw;
        }
    }

    .page[data-name="info-loggedout"]{
        background-image: url("../assets/img/bg.png");
        background-position: center;
        background-size: cover;
    }

    .content-container{
        background-color: #efeff4;
        min-height: 80vh;
        padding: 3vw;
    }

    #top-block {
        margin: 3vh 0 !important;
    }
    #header-block {
        color: #fff;
        margin: 5vh 8vw 2.5vh 8vw;
    }

    #prep-content-block {
        display: flex;
        margin: 0 !important;
        flex-direction: column;
        min-height: 70vh;
    }
    h2#work {
        margin-bottom: 2vh;
        text-transform: uppercase;
    }

    .info-block {
        background-color: #fff;
        text-align: center;
        padding: 50px 10px;
        margin: 15px 0;
        position: relative;

        .info-block-icon {
            position: absolute;
            top: -10px;
            left: -10px;

            i {
                color: #d53e06;
            }
        }

        .info-block-content {
            font-weight: bold;
        }
    }

    
    div#back-btn {
        border-color: transparent !important;
        box-shadow: none;
    }
    div#back-btn > button {
        background-color: #ee562b;
        border-radius: 25px;
        border-color: transparent !important;
        box-shadow: none;
        color: #fff;
        padding: 3vw;
        font-family: 'TitilliuRegular' !important;
    }
    div#back-btn > button > div {
        display: flex;
        justify-content: space-between;
        padding: 0 10px;
    }
    div#back-btn > button i {
        font-weight: bold;
    }

    
</style>

<style lang="scss">

    .page[data-name="info-loggedout"] .page-content{
        padding-bottom: 0 !important;
        font-family: 'TitilliuRegular';
    }

</style>