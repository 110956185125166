<template>
<f7-app :params="f7params" >

  <!-- Left panel with cover effect-->
  <left-nav-panel-component>
  </left-nav-panel-component>

  <!-- Views/Tabs container -->
  <f7-views tabs class="safe-areas">
    <f7-toolbar tabbar bottom labels>
      <f7-link tab-link="#view-diagnosis" tab-link-active icon-ios="material:insert_comment" icon-aurora="material:insert_comment" icon-md="material:insert_comment"></f7-link>
      <f7-link tab-link="#view-beforeQuitting" icon-ios="material:restore" icon-aurora="material:restore" icon-md="material:restore" @click="verifyPermission('beforeQuitting')"></f7-link>
      <f7-link tab-link="#view-quitting" icon-ios="material:smoke_free" icon-aurora="material:smoke_free" icon-md="material:smoke_free" @click="verifyPermission('quitting')"></f7-link>
      <f7-link tab-link="#view-abstinence" icon-ios="material:thumb_up" icon-aurora="material:thumb_up" icon-md="material:thumb_up" @click="verifyPermission('abstinence')"></f7-link>
      <f7-link tab-link="#view-success" title="success" @click="verifyPermission('success')">
        <img src="../assets/img/emoji_events-24px.svg" class="nav-list-img" style="filter: invert(47%) sepia(0%) saturate(527%) hue-rotate(95deg) brightness(93%) contrast(88%);" />
      </f7-link>
      <f7-link tab-link="#view-info" icon-ios="material:info" icon-aurora="material:info" icon-md="material:info"></f7-link>
    </f7-toolbar>

    <!-- Wyświetlanie strony 'home' jezeli uzytkownik jest zalogowany i 'login' w przeciwnym wypadku. -->
    <f7-view id="view-login" name="login" tab url="/login/"></f7-view>
    <f7-view id="view-diagnosis" name="diagnosis" tab tab-active main url="/diagnosis/"></f7-view>
    <f7-view id="view-beforeQuitting" name="beforeQuitting" tab url="/beforeQuitting/"></f7-view>
    <f7-view id="view-quitting" name="quitting" tab url="/quitting/"></f7-view>
    <f7-view id="view-abstinence" name="abstinence" tab url="/abstinence/"></f7-view>
    <f7-view id="view-success" name="success" tab url="/success/"></f7-view>
    <f7-view id="view-info" name="info" tab url="/info/"></f7-view>

  </f7-views>
</f7-app>
</template>
<script>
  import cordovaApp from '../js/cordova-app.js';
  import routes from '../js/routes.js';
  import StoreCache from '../utils/storeCache.js';
  import LeftNavPanelComponent from '../components/left-nav-panel.vue';
  import Statistics from "../utils/Statistics";
  import User from "../utils/User";
  import Auth from "../utils/Auth";
  import Application from '../utils/Application.js';
  import AppSettings from '../utils/AppSettings.js';
  import Util from '../utils/Util.js';
  import $ from 'dom7';
  import * as moment from 'moment';

    export default {
    data() {
      return {
        // Framework7 Parameters
        f7params: {
          id: 'pl.emedway.onkologia', // App bundle ID
          name: 'Jak rzucić palenie', // App name
          theme: 'auto', // Automatic theme detection
          // App root data
          data: function () {
            return {
              isLoggedIn: false,
              api:{
                 url:  'https://palenie.javol.smallhost.pl',
                //url : 'http://niopib-prod.devqube.tech'
              },
              intervals: {
                timeSinceLastCigarette: null,
              },
              timeSinceLastCigarette: null,
              timeTillEndOfCigaretteJournal: null,
              cigaretteJournalEnded: null,
              cigaretteStats : null,
              stats: null,
              // Lista modułów z pytaniami, na potrzeby zapisu i pobierania odpowiedzi.
              questionModules: [
                'q_diagnostyka',
                'q_s2_beforeQuitting',
                'q_s3_beforeQuitting',
                'q_s4_beforeQuitting',
                'q_s3_quitting',
                'q_quitting_after12weeks',
              ],
              permissions: [
                'beforeQuitting',
                'quitting',
                'abstinence',
                'success',
              ],
            };
          },
          // App routes
          routes: routes,
          // Input settings
          input: {
            scrollIntoViewOnFocus: this.$device.cordova && !this.$device.electron,
            scrollIntoViewCentered: this.$device.cordova && !this.$device.electron,
          },
          // Cordova Statusbar settings
          statusbar: {
            iosOverlaysWebView: true,
            androidOverlaysWebView: false,
            iosBackgroundColor: "#d53e06",
            androidBackgroundColor: "#d53e06",
            iosTextColor: "#ffffff",
            androidTextColor: "#ffffff",
          },
        },
        timeSinceLastCigarette: null,
        timeTillEndOfCigaretteJournal: null,
        cigaretteJournalEnded: null,
      }
    },
    watch: {
      // Objeście problemu z zasięgiem zmiennych
      // globalnych w funkcji 'calculateTimeValues'.
      // --------------
      timeSinceLastCigarette: function(current, prev) {
        this.$f7.data.timeSinceLastCigarette = current;
      },
      timeTillEndOfCigaretteJournal: function(current, prev) {
        this.$f7.data.timeTillEndOfCigaretteJournal = current;
      },
      cigaretteJournalEnded: function(current, prev) {
        if (current == true) this.$f7.data.stats.endCigaretteJournal();
        this.$f7.data.cigaretteJournalEnded = current;
      }
      // --------------
    },
    methods: {
      /**
       * Funkcja wywoływana w interwale co sekundę.
       * Odlicza czas dziennika, od ostatniego
       * papierosa.
       */
      calculateTimeValues() {
        // Cykliczne sprawdzanie, czy należy wysłać dane na serwer.
        Application.checkUpdateRemote();

        // Czas od ostatniego papierosa.
        const storeCache = new StoreCache();

        if (!User.id) return;

        const userStats = storeCache.get('_stats');

        // Odliczanie czasu w czasomierzach.
        if(userStats) {
          if(userStats.cigarettes && userStats.cigarettes.length > 0) {
            const lastIndex = userStats.cigarettes.length - 1;
            const latestCigarette = userStats.cigarettes[lastIndex];
            const latestCigaretteDate = moment(latestCigarette.registered);
            const latestCigaretteSeconds = latestCigaretteDate.unix();
            const secondsSinceLastCigarette = (this.getDate().unix()) - latestCigaretteSeconds;

            this.timeSinceLastCigarette = Util.secondsToTime(secondsSinceLastCigarette);
          } else {
            this.timeSinceLastCigarette = {
              days: 0,
              hours: 0,
              minutes: 0,
              seconds: 0,
              secondsTotal: 0,
            };
          }
        }

        // Czas do końca dziennika kontrolowanego palenia.
        const userSettings = storeCache.get('_settings');

        if (!userSettings) {
          User.verifySession();
          return;
        }

        if(userSettings && userSettings.cigaretteJournal) {
          if(!userSettings.cigaretteJournal.ended) {
            const journalStartDate = moment(Number(userSettings.cigaretteJournal.startTime));

            const journalStartTime = journalStartDate.valueOf();
            const secondsTillEndOfJournal = (journalStartTime + Number(userSettings.cigaretteJournal.duration))/1000 - (this.getDate().unix());

            if (secondsTillEndOfJournal > 0) {
              this.timeTillEndOfCigaretteJournal = Util.secondsToTime(secondsTillEndOfJournal);
            } else {
              this.cigaretteJournalEnded = true;
            }
          } else {
            this.timeTillEndOfCigaretteJournal = {
              days: 0,
              hours: 0,
              minutes: 0,
              seconds: 0,
            };
          }
        }
      },

    },
    mounted() {
      this.$f7ready((f7) => {
        // Init cordova APIs (see cordova-app.js)
        if (f7.device.cordova) {
          cordovaApp.init(f7);
        }

        f7.views.main.params.iosSwipeBack = false;

        const self = this;
        const storeCache = new StoreCache();

        f7.data.stats = new Statistics(new StoreCache(), f7);

        // Inicjalizacja zaleźności i właściwości klasy AppSettings.
        AppSettings.f7 = f7;
        AppSettings.store = new StoreCache();

        Application.f7 = f7;
        Application.router = this.$f7.views.main.router;
        Application.store = new StoreCache();

        // Inicjalizacja zaleźności i właściwości klasy User.
        User.f7 = f7;
        User.router = this.$f7.views.main.router;
        User.store = new StoreCache();

        // Tylko na dev.
        if (Application.checkConnection() === 'dev') {
          AppSettings.onDeviceReady();
        }
        else {
          // Produkcja
          $(document).on('deviceready', AppSettings.onDeviceReady);
        }


        // Sprawdzenie, czy użytkownik ma aktywną sesję.
        User.verifySession().then(isActive => {
          if (!isActive) return;

          console.log("TUTAJ 2")

          $('.toolbar').removeClass('toolbar-hidden');
          Application.init();
        });

        // Uruchomienie interwału liczącego czas od ostatniego papierosa.
        if (!this.$f7.data.intervals.timeSinceLastCigarette)
          this.$f7.data.intervals.timeSinceLastCigarette = setInterval(this.calculateTimeValues.bind(f7), 1000);

      });
    },
    components: {
      LeftNavPanelComponent
    }
}
</script>
