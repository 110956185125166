export default class Util {

    /**
     * Zamienia sekundy na ilość dni, godzin, minut i sekund.
     *
     * @static
     * @param {*} seconds
     * @returns {object}
     * @memberof Util
     */
    static secondsToTime(seconds) {
        const time = {
            days: Math.floor(seconds / (3600*24)),
            hours: Math.floor(seconds % (3600*24) / 3600),
            minutes: Math.floor(seconds % 3600 / 60),
            seconds: Math.floor(seconds % 60),
            secondsTotal: seconds,
        };

        return time;
    }
    
    /**
     * Zamienia próg czasowy w sekundach na 
     * słowa.
     *
     * @static
     * @param {*} threshold
     * @returns {string}
     * @memberof Util
     */
    static getTimespanWord(threshold) {
        if (Number(threshold) < 3600) {
            return String(threshold / 60) + ' ' + window.vue.$t('app.timeSpans.minutes2');
        }
        if (Number(threshold) <= 345600) {
            const hoursNum = threshold / 60 / 60;
            let hoursTxt;
            if ([2,3,4].includes(hoursNum % 10) && hoursNum != 12) {
                hoursTxt = 'hours';
            }
            else {
                hoursTxt = 'hours2';
            }
            return String(hoursNum + ' ' + window.vue.$t('app.timeSpans.' + hoursTxt));
        }

        const days = String(Math.floor(threshold / 60 / 60 / 24));
        return days + ' ' + (days == 1 ? window.vue.$t('app.timeSpans.day') : window.vue.$t('app.timeSpans.days'));
    }
    
}