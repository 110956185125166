<template>
    <div>
        <template v-if="userGroup != 1">
            <div class="table-cnt">
                <table>
                    <tr>
                        <th>
                            Pora dnia
                        </th>
                        <th>
                            Ilość spalonych papierosów
                        </th>
                    </tr>
                    <tr v-for="(row, key) in timePeriodsTable" :key="key">
                        <td>
                            {{row.period}}
                        </td>
                        <td>
                            {{row.cigs}}
                        </td>
                    </tr>
                </table>
            </div>

            <tips-component :tips="periodTips"></tips-component>
        </template>

        <div class="table-cnt">
            <table>
                <tr>
                    <th>
                        Sytuacja
                    </th>
                    <th>
                        Ilość spalonych papierosów
                    </th>
                </tr>
                <tr v-for="(row, key) in situationsTable" :key="key">
                    <td>
                        {{row.situation}}
                    </td>
                    <td>
                        {{row.cigs}}
                    </td>
                </tr>
            </table>
        </div>

        <tips-component :tips="situationTips"></tips-component>
    </div>
</template>

<script>
    import StoreCache from '../../utils/storeCache.js';
    import User from '../../utils/User';
    import Application from '../../utils/Application';
    import TipsComponent from '../../components/tips';
    import journalTips from "../../assets/beforeQuitting/journalTips";
    import * as moment from 'moment';

    export default {
        name: "beforeQuitting-krok1-results",
        props: ['userGroup', 'isModuleComplete'],
        data() {
            return {
                journalTips: null,
                situations: this.$t('beforeQuitting.situations'),
                timePeriods: [
                    "05.00-10.00",
                    "10.01-12.00",
                    "12.01-20.00",
                    "20.01-24.00",
                    "00.01-04.49",
                ],
            }
        },
        components: {
            TipsComponent
        },
        computed: {
            cigsForJournal: function() {
                this.isModuleComplete();
                
                const storeCache = new StoreCache(),
                    cigs = storeCache.get('_stats').cigarettes,
                    journalStartDate = this.journalStartDate,
                    journalEndDate = this.journalEndDate;

                return cigs.filter(cig => {
                    let cigDate = moment(cig.registered);

                    return cigDate.valueOf() >= journalStartDate.valueOf() && cigDate.valueOf() <= journalEndDate.valueOf() && cig.situation != null;
                });
            },
            journal: function() {
                return (new StoreCache).get('_settings').cigaretteJournal;
            },
            journalEndDate: function() {
                let journalStartDate = moment(Number(this.journal.startTime));
                const journalStartTime = journalStartDate.valueOf();
                
                return moment(journalStartTime + Number(this.journal.duration));
            },
            journalStartDate: function() {
                let journalStartDate = moment(Number(this.journal.startTime));
                const journalStartTime = journalStartDate.valueOf();

                return moment(Number(journalStartTime));
            },
            // Wskazówki dot. pór dnia
            periodTips: function() {
                let tips = [];
                let tip = {
                    title: this.journalTips.periods.title,
                    content: [],
                };

                if (this.topPeriods.length == 0) {
                    tip.content = [this.$t('beforeQuitting.error_not_enough_data')];
                }

                this.topPeriods.forEach(row => {
                    switch (row.period) {
                        case this.timePeriods[0]:
                            tip.content.push(this.journalTips.periods.content[0]);
                            break;
                        case this.timePeriods[1]:
                            tip.content.push(this.journalTips.periods.content[1]);
                            break;
                        case this.timePeriods[2]:
                            tip.content.push(this.journalTips.periods.content[2]);
                            break;
                        case this.timePeriods[3]:
                            tip.content.push(this.journalTips.periods.content[3]);
                            break;
                        case this.timePeriods[4]:
                            tip.content.push(this.journalTips.periods.content[4]);
                            break;
                    }
                });
                tips.push(tip);
                
                return tips;
            },
            situationsTable: function() {
                let situationsTbl = this.situations.map(situation => {
                    let result = { situation };

                    const cigCount = this.cigsForJournal.reduce((sum, cig) => {
                        if (situation === cig.situation) {
                            return ++sum;
                        }
                        return sum;
                    }, 0);

                    result.cigs = cigCount;

                    return result;
                });

                const nonZeroSituationsTbl = situationsTbl
                    .filter(s => {
                        return s.cigs > 0;
                    });

                if (nonZeroSituationsTbl.length > 0) {
                    situationsTbl = nonZeroSituationsTbl;
                }

                situationsTbl = situationsTbl
                    .sort((a, b) => {
                        return b.cigs - a.cigs;
                    });
                
                return situationsTbl;
            },
            // Wskazówki dot. sytuacji
            situationTips: function() {
                let tips = [],
                    tip = {
                        title: this.journalTips.situations.title,
                        content: [],
                    },
                    addedIndexes = [];

                if (this.topSituations.length == 0) {
                    tip.content = [this.$t('beforeQuitting.error_not_enough_data')];
                }

                this.topSituations.forEach(row => {
                    let content,
                        index;

                    switch (true) {
                        case this.situations.slice(0, 1).includes(row.situation):
                            content = this.journalTips.situations.content[0];
                            index = 0;
                            break;
                        case this.situations.slice(1, 7).includes(row.situation):
                            content = this.journalTips.situations.content[1];
                            index = 1;
                            break;
                        case this.situations.slice(7, 8).includes(row.situation):
                            content = this.journalTips.situations.content[2];
                            index = 2;
                            break;
                        case this.situations.slice(8).includes(row.situation):
                            content = this.journalTips.situations.content[3];
                            index = 3;
                            break;
                    }

                    if (!addedIndexes.includes(index)) {
                        tip.content.push(content);
                        addedIndexes.push(index);
                    }
                });
                tips.push(tip);
                
                return tips;
            },
            timePeriodsTable: function() {
                let periodsTbl = this.timePeriods.map(period => {
                    let result = { period };
                    const startHr = period.split('-')[0].replace('.', ':'),
                            endHr = period.split('-')[1].replace('.', ':');

                    const cigCount = this.cigsForJournal.reduce((sum, cig) => {
                        const registeredDate = moment(cig.registered),
                            registeredDay = this.extractDate(registeredDate),
                            registeredDayWStartHr = moment(registeredDay + ' ' + startHr, "DD.MM.YYYY HH:mm"),
                            registeredDayWEndHr = moment(registeredDay + ' ' + endHr, "DD.MM.YYYY HH:mm");

                        if (registeredDate > registeredDayWStartHr && registeredDate < registeredDayWEndHr) {
                            return ++sum;
                        }
                        return sum;
                    }, 0);

                    result.cigs = cigCount;

                    return result;
                });

                return periodsTbl;
            },
            topPeriods: function() {
                const nonZerotimePeriods = this.timePeriodsTable.filter(s => {
                    return s.cigs > 0;
                });
                
                if (nonZerotimePeriods.length > 0) {
                    const sortedTimePeriodsTable = this.timePeriodsTable.sort((a, b) => {
                        return b.cigs - a.cigs;
                    });

                    const topPeriodCigs = sortedTimePeriodsTable[0].cigs;
                    return sortedTimePeriodsTable.filter((row) => {
                        return row.cigs == topPeriodCigs;
                    });
                }

                return [];
            },
            topSituations: function() {
                const nonZeroSituationsTable = this.timePeriodsTable.filter(s => {
                    return s.cigs > 0;
                });

                if (nonZeroSituationsTable.length > 0) {
                    const topSituationCigs = this.situationsTable[0].cigs;
                    return this.situationsTable.filter((row) => {
                        return row.cigs == topSituationCigs;
                    });
                }

                return [];
            },
        },
        created() {
            this.journalTips = this.$t('journalTips').journalTips
        }
    }
</script>

<style lang="scss" scoped>
    @media only screen and (max-width: 600px) {
        table {
            font-size: 15px;
        }
    }
    @media only screen and (min-width: 601px) {
        table {
            td, th {
                font-size: 2.5vw;
            }
        }
    }

    .table-cnt {
        border: 2px solid #E96331;
        border-radius: 10px;
        margin: 10px 0;
        background-color: #fff;
    }

    table {
        text-align: center;
        width: 100%; 
        border-collapse: collapse;

        td, th {
            width: 50%;
            padding: 2.5px 2px;
        }

        th {
            border-bottom: 2px solid #E96331;
        }
    }
</style>