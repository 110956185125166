export default {
    quittingPharmacotherapyTips: {
        block1: {
            title: 'Przebieg farmakoterapii',
            content: [
                'Pamiętaj o przyjmowaniu leków zgodnie z zaleceniami.',

                'Jeśli masz jakieś wątpliwości co do stosowanego leku zadzwoń: 801 108 108.',

                'Substancja aktywna w leku działa! Wykorzystaj to!',

                'Pamiętaj, że leki zmniejszają potrzebę palenia i ułatwiają kontrolowanie chęci palenia!',

                'Czy dzisiaj brałeś/łaś już swój lek na rzucenie palenia?',

                'Pamiętaj, że czas trwania terapii można wydłużyć i dostosować do Twoich potrzeb.',

                'Pamiętaj o zabraniu leków wychodząc z domu.',

                'Ustaw sobie przypomnienie o kolejnej dawce.',

                'Jeśli chcesz omówić działanie leku zadzwoń: 801 108 108.',

                'Pamiętaj o kolejnej dawce leku.',

                'Sprawdź czy masz leki na następne dni.',

                'Czy nadal pilnujesz prawidłowego dawkowania leku?',
            ]
        },
    }
}