<template>
    <div class="questions-container">
        <f7-block class="answer-loader" v-if="loading">
            <div class="answer-loader-inside">
                <f7-preloader color="orange"></f7-preloader>
                <div class="answer-loader-txt">
                    {{$t(loadingAltTxt ? loadingAltTxt : 'app.answer_analysis')}}
                </div>
            </div>
        </f7-block>

        <f7-block v-else>
            <div class="intro" v-if="intro">{{intro}}</div>

            <form id="questions-form" class="form list">

                <ul class="border-radious">

                    <div v-for="(question, i) in questions" v-bind:key="i">
                        <li :ref="'qref_' + i" v-if="questionIsVisible(question)">
                            <div class="item-content item-input" @click="focusSelect('qsref_' + i)">
                                <div class="item-inner">
                                    <div class="item-title item-label">{{question.name}}</div>
                                    <div class="item-input-wrap">

                                        <template v-if="question.type == 'select'">
                                          <f7-list>
                                            <f7-list-item radio radio-icon :name="'q_' + i" @change="changeHandler($event); answers[i] = $event.target.value" :ref="'qsref_' + i" v-for="(answer, j) in question.answer" v-bind:key="j" :value="answer.value" :title="answer.name" />
                                          </f7-list>

                                        </template>

                                        <template v-else-if="question.type == 'multicheckbox'">
                                          <f7-list>
                                            <f7-list-item checkbox radio-icon :name="'q_'+question.id" @change="changeHandler($event); answers[i] = $event.target.value" :ref="`multicheckbox_${i}_${j}`" v-for="(answer, j) in question.answer" v-bind:key="j" :value="answer.value" :title="answer.name" />
                                          </f7-list>

                                        </template>

                                        <div id="question-error" v-if="errors[i]">{{errors[i]}}</div>
                                    </div>
                                </div>


                            </div>
                        </li>
                    </div>

                </ul>

                <send @click.native="saveForm"></send>
            </form>
        </f7-block>
    </div>
</template>

<script>
    import send from './component-button'

    export default {
        name: "questions",
        props:['name', 'questions', 'intro', 'saveHandler', 'loadingAltTxt'],
        data() {
            return {
                loading: false,
                answers: [],
                errors: [],
                focused: '',
            }
        },
        components: {
            send,
        },
        methods: {
            answerIsFilled(answer) {
                return answer && (
                        (typeof answer === 'string' && answer != '0') ||
                        (typeof answer === 'number' && answer != 0) ||
                        (typeof answer === 'object' && answer.length > 0));
            },
            changeColorActive(ref){
                if (this.$refs[ref] && this.$refs[ref].length > 0) {
                    const el = this.$refs[ref][0];
                    el.classList.add('focusIn');
                    this.focused = ref;
                }
            },
            changeColorInactive(ref){
                if (this.$refs[ref] && this.$refs[ref].length > 0) {
                    const el = this.$refs[ref][0];
                    el.classList.remove('focusIn');
                    this.focused = '';
                }
            },
            changeHandler(evt, q = null){
                this.errors = [];

                // Jeżeli to multicheckbox, wprowadza odpowiedzi jako tablicę.
                if (q != null) {
                    let checkedBoxes = [];
                    for (let [key, ref] of Object.entries(this.$refs)) {
                        if (key.match(/multicheckbox_\d+_\d+/g) && key.split("_")[1] == String(q)) {
                            if (ref[0] && ref[0].checked)
                                checkedBoxes.push(ref[0].value)
                        }
                    }
                    this.answers[q] = checkedBoxes;
                }

                evt.target.blur();
            },
            dependenciesMet(dependencies) {
                let met = true;

                dependencies.forEach(dep => {
                    if (!this.answers[dep.questionId] || this.answers[dep.questionId] != dep.value) {
                        met = false;
                    }
                });

                return met;
            },
            focusSelect(ref) {
                // if (this.$refs[ref] && this.$refs[ref].length > 0) {
                //     const el = this.$refs[ref][0];
                //     console.log(this.$f7.$(el)[0]);
                //     // el.focus();
                //     this.$f7.$(el)[0].click();
                // }
            },
            // Sprawdza czy wszystkie warunki zostały spełnione, żeby pytanie mogło być widoczne.
            questionIsVisible(q) {
                return q.dependsOn == null || (q.dependsOn != null && ( (typeof q.dependsOn === 'number' && this.answerIsFilled(this.answers[q.dependsOn])) || (typeof q.dependsOn !== 'number' && this.dependenciesMet(q.dependsOn)) ) );
            },
            saveForm(){
                const formData = this.$f7.form.convertToData('#questions-form');

                if (!this.validate(formData)) return;
                // console.log(formData)
                // return;

                let score = 0;
                const questionsLength = this.questions.length;

                // Zliczanie punktow.
                for (let i = 0; i < questionsLength; i++) {
                    if (typeof formData[`q_${i}`] != 'object') {
                        if(this.answerIsFilled(formData[`q_${i}`])) {
                            const answerScore = _.find(this.questions[i].answer, {'value': formData[`q_${i}`]}).score;

                            if (answerScore)
                                score += parseInt(answerScore);
                        }
                    }
                    else {
                        formData[`q_${i}`] = JSON.stringify(formData[`q_${i}`]);
                    }
                }
                formData.score = score;

                this.loading = true;
                setTimeout(() => {
                    this.loading = false;
                    this.$f7.data.stats.saveAnswers(this.name, formData);
                    this.saveHandler(formData);
                }, 2000);
            },
            validate(data) {
                this.errors = [];
                const questionsLength = this.questions.length;
                let valid = true;

                for (let i = 0; i < questionsLength; i++) {
                    const questionData = data[`q_${i}`];
                    const isRequired = this.questions[i].required;
                    const dependenciesAreMet = !this.questions[i].dependsOn || typeof this.questions[i].dependsOn === 'number' || this.dependenciesMet(this.questions[i].dependsOn);

                    if ((dependenciesAreMet && isRequired) && (!questionData || !this.answerIsFilled(questionData))) {
                        this.errors[i] = this.$t('questionsComponent.error_not_all_answered');
                        valid = false;
                    }
                }

                return valid;
            }
        },
        mounted() {
            this.$f7ready(f7 => {

            });
        }
    }
</script>

<style lang="scss" scoped>
    @media only screen and (max-width: 600px) {
        .intro {
            font-size: 3.8vw;
        }
        .item-title {
            font-size: 3.8vw;
        }
        select {
            font-size: 3.5vw;
        }
        .item-choice {
            font-size: 3.5vw;
        }
        #question-error {
            font-size: 3vw;
        }
    }
    @media only screen and (min-width: 601px) {
        .intro {
            font-size: 3.2vw;
        }
        .item-title {
            font-size: 3vw;
        }
        select {
            font-size: 2.5vw;
        }
        .item-choice {
            font-size: 2.5vw;
        }
        #question-error {
            font-size: 2.5vw;
        }
    }

    .questions-container {
        .block {
            margin-top: 0 !important;
            padding: 0 !important;
        }
    }

    .focusIn{
        border: 1px solid #da521f !important;
        background-color: transparent !important;
    }

    .border-radious{
        border-radius: 25px;
    }

    .list ul:before{
        width: 0 !important;
    }

    .list ul{
        background: transparent !important;
    }

    .list li{
        background-color: white;
        margin-top: 10px;
        border-radius: 25px;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        border: 1px solid #fff;
    }

    .item-outer {
        display: flex;

        > div {
            display: flex;
            justify-items: center;
            align-items: center;
        }

        .item-inner-left {
            padding: 0 1vw;
        }
        .item-inner-right {
            flex: 1;
        }
    }
    .item-title {
        padding: 2vw;
        color: #000;
    }
    .item-arrow-container {
        color: #da521f;
        margin-right: 3vw;

        i {
            font-size: 5vw;
        }
    }
    .input-with-value {
        color: #da521f;
    }
    select {
        // border-bottom: 1px solid grey;
        margin-bottom: 1vh;
    }

    #question-error {
        color: red;
    }

</style>
