<template>
    <f7-page name="abstinence">
        <navbar-component hair-lines>
            <f7-link icon-f7="bars" panel-open="left"></f7-link>
            <template v-slot:title>{{$t('abstinence.title')}}</template>
        </navbar-component>

        <f7-block id="step-block">
            <div id="step-list">
                <div v-bind:class="{ active: (currentStep == 1) }" @click="changeStep(1)">
                    <f7-icon material="timer"></f7-icon>
                </div>
                <div v-bind:class="{ active: (currentStep == 2) }" @click="changeStep(2)">
                    <f7-icon material="question_answer"></f7-icon>
                </div>
            </div>
        </f7-block>

        <!-- Czasomierz -->
        <abstinence-timer-component v-if="currentStep == 1 && cigaretteZero" 
            :threshold="threshold"
            :getThreshold="getThreshold"
        >
        </abstinence-timer-component>
        <zero-cigarette-component 
            v-if="currentStep == 1 && !cigaretteZero"
            :cb="addZeroCigarette"
        >
        </zero-cigarette-component>
        
        <!-- Pytania -->
        <abstinence-questions-component v-if="currentStep == 2">
        </abstinence-questions-component>
            
    </f7-page>
</template>

<script>
    import NavbarComponent from '../../components/navbar.vue';
    import ZeroCigaretteComponent from '../../components/zero-cigarette';
    import AbstinenceTimerComponent from './abstinence-timer';
    import AbstinenceQuestionsComponent from './abstinence-questions';
    import * as moment from 'moment';

    export default {
        name: "abstinence",
        data() {
            return {
                currentStep: 1,
                abstinenceTimerTips: null,
                threshold: null,
                cigaretteZero: null,
            }
        },
        components: {
            NavbarComponent,
            ZeroCigaretteComponent,
            AbstinenceTimerComponent,
            AbstinenceQuestionsComponent,
        },
        methods:{
            addZeroCigarette() {
                this.cigaretteZero = true;
            },
            changeStep(step) {
                this.currentStep = step;
            },
            nextStep() {
                this.currentStep < 2 && this.currentStep++;
            },
            getThreshold() {
                if (!this.$f7.data.timeSinceLastCigarette) return;
                
                const secondsSinceCig = this.$f7.data.timeSinceLastCigarette.secondsTotal;
                
                for (let [secThreshold, tip] of Object.entries(this.abstinenceTimerTips).reverse()) {
                    if (secThreshold <= secondsSinceCig) {
                        this.threshold = secThreshold;
                        break;
                    }
                }

                if (!this.threshold)
                    this.threshold = 0;
            }
        },
        created() {
            this.abstinenceTimerTips = this.$t('abstinenceTimerTips').abstinenceTimerTips;
        },
        mounted() {
            this.$f7ready(f7=>{
                document.querySelector('#view-abstinence').addEventListener('tab:show',()=>{
                    this.getThreshold();

                    this.cigaretteZero = f7.data.stats.getTimerZeroCigarette();
                });
            })
        }

    }
</script>

<style scoped>

    @media only screen and (max-width: 600px) {
        #step-list {
            font-size: 4vw;
            margin: 0 10px;
        }
    }
    @media only screen and (min-width: 601px) {
        #step-list i {
            font-size: 5vw;
            margin: 0 5vw;
        }
    }

    .page[data-name="abstinence"]{
        background-image: url("../../assets/img/bg.png");
        background-position: center;
        background-size: cover;
    }

    #step-block {
        margin: 3vh 0 !important;
    }
    #step-list {
        display: flex;
        font-weight: bold;
        justify-content: space-between;
        font-family: 'TitilliuBold' !important;
    }
    #step-list div {
        display: flex;
        justify-content: center;
        flex-grow: 1;
    }
    #step-list div.active {
        color: #fff;
    }
</style>

<style lang="scss">
    .page[data-name="abstinence"] .page-content{
        font-family: 'TitilliuRegular';
    }
</style>