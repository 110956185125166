<template>

  <f7-page name="home">
      <navbar-component hair-lines>
        <f7-link icon-f7="bars" panel-open="left"></f7-link>
        <template v-slot:title>Home</template>
      </navbar-component>


  </f7-page>

</template>

<style scoped>
  .page[data-name="home"]{
    background-image: url("../assets/img/bg.png");
    background-position: center;
    background-size: cover;
  }

</style>

<script>
    import NavbarComponent from '../components/navbar.vue';
    import Cache from '../utils/storeCache';
    import Auth from '../utils/Auth';

    export default {
    data() {
      return {
        
      };
    },
    methods: {
      
    },
    components: {
      NavbarComponent
    },
    mounted() {
        this.$f7ready((f7)=>{
            let cache = new Cache();
            let token = cache.get('_loginData');
            let self = this;

            const headers = Auth.createBearerHeader()

            this.$f7.request({
                method: "GET",
                url: `${this.$f7.data.api.url}/getUser`,
                headers,
                success: function (response) {

                    let parsedResponse = null;
                    try {
                        parsedResponse = JSON.parse(response);
                    }
                    catch(e) {
                        console.log(e);
                        return;
                    }

                    //console.log(parsedResponse.response);

                    if (parsedResponse && parsedResponse.success) {
                        self.$f7.data.isLoggedIn = true;
                        self.$f7.data.user = parsedResponse.data;
                        //self.$f7.views.main.router.navigate("/");

                    }
                    else {
                        self.$f7.data.isLoggedIn = false;
                        self.$f7.views.main.router.navigate("/login/");
                    }

                },
                error: function (error) {
                    self.$f7.data.isLoggedIn = false;
                    setTimeout(()=>{
                        self.$f7.views.main.router.navigate("/login/");
                    },500);

                    console.log('Przenosze HOME!');
                }
            });
        })
    }
  };
</script>