<template>
    <a :href="link" :style="'background:'+ color" class="btn-component button">{{text}}</a>
</template>

<script>
    export default {
        name: "componentButton",
        props:{
            link:{
                type: String,
                default: '#',
            },
            color:{
                type: String,
                default: '#d53e06',
            },
            text: {
                type: String,
                default: 'Wyślij'
            }
        }
    }
</script>

<style scoped>
    @media only screen and (max-width: 600px) {
        .btn-component{
            font-size: 4.5vw;
        }
    }
    @media only screen and (min-width: 601px) {
        .btn-component{
            font-size: 3.8vw;
            padding: 1vh;
        }
    }

    a.btn-component {
        margin-top: 20px;
        height: auto;
        background-color: #ee562b;
        border-radius: 25px;
        color: #fff;
        font-family: 'TitilliuRegular' !important;
    }
    a.btn-component > button > div {
        display: flex;
        justify-content: space-between;
        padding: 0 10px;
    }
    a.btn-component > i {
        font-weight: bold;
    }
</style>

<style>

</style>