<template>
    <div class="main-container">
        <f7-block>
            <f7-row>
                <f7-col>
                    <div id="header-block">
                        <span id="header-icon">
                            <f7-icon material="question_answer"></f7-icon>
                        </span>
                        <span id="header-title">
                            {{$t('quitting_questions.header')}}
                        </span>
                    </div>
                </f7-col>
            </f7-row>
        </f7-block>

        <div class="content-container">
            <f7-block>
                <!-- Jeżeli pytania nie zostały zapisane -->
                <template v-if="!answersSaved">
                    <questions-component
                        :intro="$t('quitting.12week_intro')"
                        :name="'q_quitting_after12weeks'" 
                        :questions="questions" 
                        :saveHandler="saveQuestions" 
                    ></questions-component>
                </template>

                <!-- Wskazówki - jeżeli pytania zostały zapisane -->
                <div class="tips-container" v-else>
                    <tips-component :tips="tips"></tips-component>
                </div>
            </f7-block>
        </div>
    </div>

</template>

<script>
    import * as moment from 'moment';
    import StoreCache from '../../utils/storeCache.js';
    import QuestionsComponent from '../../components/questions';
    import TipsComponent from '../../components/tips';

    export default {
        name: "quitting-12weekquestion",
        props: ['questionsAnswered', 'setQuestionsAnswered'],
        data(){
            return {
                answersSaved: this.questionsAnswered,
                questions : null,
                questionsTips: null,
            }
        },
        components: {
            QuestionsComponent,
            TipsComponent,
        },
        computed: {
            tips: function() {
                let tips = [];
                const cache = new StoreCache();
                const answers = cache.get("_q_quitting_after12weeks");

                // Wskazówka 1.
                let tip1 = { title: this.questionsTips.block1.title };
                if (answers['q_0'] === '1') {
                    tip1.content = [this.questionsTips.block1.content[0]];
                }
                else {
                    tip1.content = [this.questionsTips.block1.content[1]];
                }
                tips.push(tip1);

                return tips;
            }
        },
        methods:{
            saveQuestions() {
                this.answersSaved = true;
                this.setQuestionsAnswered('q_quitting_after12weeks');
            }
        },
        created() {
            this.questions = this.$t('quitting12WeekQuestion').quitting12WeekQuestion;
            this.questionsTips = this.$t('quitting12WeekTips').quitting12WeekTips;
        },
        mounted() {
            this.$f7ready(f7 => {
            });
        }

    }
</script>

<style lang="scss" scoped>
    @media only screen and (max-width: 600px) {
        #header-icon i {
            font-size: 5.5vw;
        }
        #header-title {
            font-size: 5.5vw;
            margin-left: 2vw;
        }
    }
    @media only screen and (min-width: 601px) {
        #header-icon i {
            font-size: 4.5vw;
        }
        #header-title {
            font-size: 4vw;
            margin-left: 2vw;
        }
    }

    .main-container {
        display: flex;
        flex-direction: column;
        min-height: 80vh;
    }

    .content-container {
        background-color: #efeff4;
        flex: 1;
    }

    #header-block {
        color: #fff;
        margin: 0 4vw 0 4vw;
    }
</style>

<style>

    @media only screen and (min-width: 601px) {
    }

</style>