<template>
    <div class="main-container">
        <f7-block>
            <f7-row>
                <f7-col>
                    <div class="header-block">
                        <div>
                            <f7-icon material="question_answer"></f7-icon>
                        </div>
                        <div>
                            <h3>
                                {{$t('quitting_questions.header')}}
                            </h3>
                        </div>
                    </div>
                </f7-col>
            </f7-row>
        </f7-block>

        <div class="content-container">
            <f7-block>
                <!-- Jeżeli pytania nie zostały zapisane -->
                <template v-if="!answersSaved">
                    <questions-component
                        :name="'q_s3_quitting'" 
                        :questions="questions" 
                        :saveHandler="saveQuestions" 
                    ></questions-component>
                </template>

                <!-- Wskazówki - jeżeli pytania zostały zapisane -->
                <div class="tips-container" v-else>
                    <tips-component :tips="tips"></tips-component>
                </div>
            </f7-block>
        </div>
    </div>

</template>

<script>
    import * as moment from 'moment';
    import StoreCache from '../../utils/storeCache.js';
    import QuestionsComponent from '../../components/questions';
    import TipsComponent from '../../components/tips';

    export default {
        name: "quitting-questions",
        props: ['questionsAnswered', 'setQuestionsAnswered'],
        data(){
            return {
                answersSaved: this.questionsAnswered,
                questions : null,
                questionsTips: null,
            }
        },
        components: {
            QuestionsComponent,
            TipsComponent,
        },
        computed: {
            tips: function() {
                let tips = [];
                const cache = new StoreCache();
                const answers = cache.get("_q_s3_quitting");

                // Wskazówka 1.
                let tip1 = { title: this.questionsTips.block1.title };
                if (answers['q_0'] === '1') {
                    const rand = Math.floor(Math.random() * 4);
                    tip1.content = [this.questionsTips.block1.content[rand]];
                }
                else {
                    tip1.content = [this.questionsTips.block1.content[4]];
                }
                tips.push(tip1);

                // Wskazówka 2.
                if (answers['q_1']) {
                    try {
                        let tip2 = { title: this.questionsTips.block2.title };
                        if (JSON.parse(answers['q_1']).includes('11')) {
                            tip2.content = [this.questionsTips.block2.content[1]];
                        }
                        else {
                            tip2.content = [this.questionsTips.block2.content[0]];
                        }
                        tips.push(tip2);
                    }
                    catch(e) {
                        console.log(e)
                    }
                }

                return tips;
            }
        },
        methods:{
            saveQuestions() {
                const cache = new StoreCache();
                this.answersSaved = true;
                const timeAnswered = this.getDate().toISOString();
                const settings = cache.get('_settings');

                if (settings.questionsAnsweredAt) {
                    settings.questionsAnsweredAt.q_s3_quitting = timeAnswered;
                }
                else {
                    settings.questionsAnsweredAt = {
                        q_s3_quitting: timeAnswered
                    }
                }
                cache.set('_settings', settings);
                
                this.setQuestionsAnswered('q_s3_quitting');
                // this.isModuleComplete();
            }
        },
        created() {
            this.questions = this.$t('quittingQuestionsQuestions').quittingQuestionsQuestions;
            this.questionsTips = this.$t('quittingQuestionsTips').quittingQuestionsTips;
        },
        mounted() {
            this.$f7ready(f7 => {
            });
        }

    }
</script>

<style lang="scss" scoped>
    .main-container {
        display: flex;
        flex-direction: column;
        min-height: 80vh;
    }

    .content-container {
        background-color: #efeff4;
        flex: 1;
    }
</style>