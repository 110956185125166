export default {
    journalTips: {
        periods: {
            title: "Pory dnia",
            content: [
                "Najwięcej papierosów wypalasz rano. To może świadczyć o silnym uzależnieniu od nikotyny.",

                "Palisz więcej w pierwszej części dnia. Zastanów się, czy nie uzupełniasz w ten sposób poziomu nikotyny po nocnej przerwie.",

                "Palisz więcej w godzinach największej aktywności. Pomyśl, co jest tego powodem? Może Twoje palenie ma głównie charakter nawykowy.",

                "Palisz więcej w godzinach wieczornych. Zastanów się, czy „wykorzystujesz” papierosy żeby się zrelaksować?",

                "Palisz najwięcej w nocy. Może to świadczyć o bardzo silnym uzależnieniu lub Twoim trybie życia. Skontaktuj się koniecznie ze specjalistą i ustal, co jest powodem tej sytuacji.",
            ]
        },

        situations: {
            title: "Sytuacje",
            content: [
                "Palenie tuż po obudzeniu się może wskazywać na rozpoczynające się uzależnienie fizyczne.",

                "Jest wiele sytuacji, w których palisz nawykowo. Postaraj się zwrócić na nie uwagę i zaplanuj czym możesz zastąpić papierosa w tych konkretnych sytuacjach. Uwaga! To ważne, bo po rzuceniu palenia w tych sytuacjach możesz odczuwać nawykową chęć palenia.",

                "Palisz głównie towarzysko. Zastanów się jak możesz to zmienić? Poszukaj sposobów na zastąpienie papierosa w ręku (np. trzymanie czegoś w ręku, picie wody) ",

                "Najwięcej palisz, kiedy odczuwasz emocje lub chcesz wypełnić czas. Przypomnij sobie taką samą sytuację, w której jednak nie zapaliłeś/łaś? Jak to zrobiłeś/łaś? Co Ci pomogło?",
            ]
        },

    }
}